import { search } from "@/api";
const state = {
  search: {
    searchList: [],
  },
  view: {},
};

const getters = {
  GET_SEARCH: (state) => state.search.searchList,
};

const mutations = {
  SET_SEARCH(state, searchData) {
    state.search.searchList = searchData;
  },
};

const actions = {
  SEARCH_SUBMIT({}, payload) {
    return search.searchSubmit(payload);
  },
  FETCH_SEARCH({ commit }, payload) {
    return search.fetchSearch(payload).then((data) => {
      commit("SET_SEARCH", data);
    });
  },
  FETCH_SEARCH_WITH_ID({ commit }, payload) {
    return search.fetchSearchWithId(payload).then((data) => {
      commit("SET_SEARCH", data);
    });
  },
  MODIFY_SEARCH({}, payload) {
    return search.modifySearch(payload);
  },

  /*
 SEARCH_SUBMIT({ commit }, payload) {
        return search.searchSubmit(payload).then(data => {
            commit("SET_SEARCH", data.response);
        });
    },
    CHECK_ID({ commit }, userData) {
      return join.checkId(userData).then(data => {
        const transedData = {
        flag: data.response.flag
        };
        commit("SET_ISCHECKED_ID", transedData.flag);
      });
    }
    */
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
