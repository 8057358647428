import { join } from "@/api";
const state = {
  join: {
    joinList: [],
    licNo: "",
  },
  encDataKeys: [],
  encDataValues: [],
  agreementValues: [],
  subList: [],
  view: {
    isDuplicatedDrLicense: false,
    isDuplicatedId: false,
  },
  hospNm: "",
  hospLogo: "",
};

const getters = {
  GET_CHECKED_RESULT: (state) => state.view.isDuplicatedId,
  GET_BASIC: (state) => state.join.joinList,
  GET_IS_DUPLICATED_DRLICENSE: (state) => state.view.isDuplicatedDrLicense,
  GET_IDENTIFY: (state) => state.encDataValues,
  GET_ENC_DATA: (state) => state.encDataKeys,
  GET_PASSED_DRLICENSE: (state) => state.join.licNo,
  GET_AGREEMENT_VALUSE: (state) => state.agreementValues,
  GET_SUB_DATA: (state) => state.subList,
  GET_HOSP_NM: (state) => state.hospNm,
  GET_HOSP_LOGO: (state) => state.hospLogo,
};

const mutations = {
  SET_CHECKED_RESULT(state, result) {
    state.view.isDuplicatedId = result;
  },
  SET_BASIC(state, memberData) {
    state.join.joinList = memberData;
  },
  SET_IDENTIFY(state, identifyInfo) {
    state.encDataValues = identifyInfo;
  },
  SET_ENC_DATA(state, encData) {
    state.encDataKeys = encData;
  },
  SET_IS_DUPLICATED_DRLICENSE(state, isDuplicate) {
    state.view.isDuplicatedDrLicense = isDuplicate;
  },
  SET_PASSED_DRLICENSE(state, license) {
    state.join.licNo = license;
  },
  SET_AGREEMENT_VALUSE(state, agreementData) {
    state.agreementValues = agreementData;
  },
  SET_SUB_DATA(state, subData) {
    state.subList = subData;
  },
  SET_HOSP_NM(state, nm) {
    state.hospNm = nm;
  },
  SET_HOSP_LOGO(state, logo) {
    state.hospLogo = logo;
  },
};

const actions = {
  SET_JOIN_BASIC({}, payload) {
    return join.joinBasic(payload);
  },
  IDENTIFY_SUCCESS({ commit }, payload) {
    return join.identitySuccess(payload).then((data) => {
      commit("SET_IDENTIFY", data);
    });
  },
  FETCH_ENC_DATA({ commit }) {
    return join.getEncData().then((data) => {
      commit("SET_ENC_DATA", data);
    });
  },
  CHECK_DOCTOR_LICENSE({ commit }, payload) {
    return join.fetchToDoctorLicense(payload).then((data) => {
      commit("SET_IS_DUPLICATED_DRLICENSE", data);
      commit("SET_PASSED_DRLICENSE", payload);
    });
  },
  CHECK_ID({ commit }, payload) {
    return join.checkId(payload).then((data) => {
      commit("SET_CHECKED_RESULT", data);
    });
  },
  FETCH_BASIC({ commit }, payload) {
    return join.fetchBasic(payload).then((data) => {
      commit("SET_BASIC", data);
    });
  },
  FETCH_BASIC_TARGET({ commit }, payload) {
    return join.fetchBasicTarget(payload).then((data) => {
      commit("SET_BASIC", data);
    });
  },
  FETCH_BASIC_TARGET_UN_AUTH({ commit }, payload) {
    return join.fetchBasicTargetUnAuth(payload).then((data) => {
      commit("SET_BASIC", data);
    });
  },
  FETCH_BASIC_ADDRESS({}, payload) {
    return join.fetchBasicAddress(payload);
  },
  MODIFY_BASIC({}, payload) {
    return join.modifyBasic(payload);
  },
  SUB_SUBMIT({}, payload) {
    return join.modifySub(payload);
  },
  FETCH_SUB({ commit }) {
    return join.fetchSub().then((data) => {
      commit("SET_SUB_DATA", data);
    });
  },
  SELECT_HOSP_NM({ commit }) {
    return join.selectHospNm().then((data) => {
      commit("SET_HOSP_NM", data.hospNm);
      commit("SET_HOSP_LOGO", data.hospLogo);
    });
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
