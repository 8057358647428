const state = {
  header: {
    checkPopup: false,
  },
  side: {
    checkPopup: false,
  },
  footer: {
    checkAgreementPopup: false,
  },
  checkPopup: false,
  checkAuthPopup: false,
  checkAgreementPopup: false,
  checkAgreementPopup2: false,
  checkAddressPopup: false,
  checkApiPopup: false,
  checkSearchPopup: false,
  checkSearchPopupReform: false,
  isLoading: false,
  isChildLoading: false,
  isCheckecIdentity: true,
  popConfig: [],
  searchParam: "",
  refund_result: false,
};

const getters = {
  getSearchParams: (state) => state.searchParam,
  getPopupState: (state) => state.checkPopup,
  getPopupStateHeader: (state) => state.header.checkPopup,
  getPopupStateSide: (state) => state.side.checkPopup,
  getAgreementPopupState: (state) => state.checkAgreementPopup,
  getAgreementPopupState2: (state) => state.checkAgreementPopup2,
  getAgreementPopupStateFooter: (state) => state.footer.checkAgreementPopup,
  getAuthPopupState: (state) => state.checkAuthPopup,
  getApiPopupState: (state) => state.checkApiPopup,
  getAddressPopupState: (state) => state.checkAddressPopup,
  getSearchPopupState: (state) => state.checkSearchPopup,
  getSearchPopupReformState: (state) => state.checkSearchPopupReform,
  getIdentityState: (state) => state.isCheckecIdentity,
  getPopConfig: (state) => state.popConfig,
  getRefundResult: (state) => state.refund_result,
};

const mutations = {
  SET_REFUND_RESULT(state, result) {
    if (result) {
      state.refund_result = true;
    } else {
      state.refund_result = false;
    }
  },
  SET_LOADER(state, isLoading) {
    // console.log({ isLoading })
    if (isLoading) {
      state.isLoading = true;
    } else {
      state.isLoading = false;
    }
  },
  SET_CHILD_LOADER(state, isChildLoading) {
    // console.log({ isLoading })
    if (isChildLoading) {
      state.isChildLoading = true;
    } else {
      state.isChildLoading = false;
    }
  },
  SET_POP_CONFIG(state, json) {
    state.popConfig = json;
    //console.log(json);
  },
  SET_SEARCH_PARAMS(state, text) {
    state.searchParam = text;
  },
  SET_POPUP(state, currentState) {
    state.checkPopup = currentState;
  },
  SET_POPUP_HEADER(state, currentState) {
    state.header.checkPopup = currentState;
  },
  SET_POPUP_SIDE(state, currentState) {
    state.side.checkPopup = currentState;
  },
  SET_AGREEMENT_POPUP(state, currentState) {
    state.checkAgreementPopup = currentState;
  },
  SET_AGREEMENT_POPUP2(state, currentState) {
    state.checkAgreementPopup2 = currentState;
  },
  SET_AGREEMENT_POPUP_FOOTER(state, currentState) {
    state.footer.checkAgreementPopup = currentState;
  },
  SET_AUTH_POPUP(state, currentState) {
    state.checkAuthPopup = currentState;
  },
  SET_ADDRESS_POPUP(state, currentState) {
    state.checkAddressPopup = currentState;
  },
  SET_API_POPUP(state, currentState) {
    state.checkApiPopup = currentState;
  },
  SET_IDENTITY_VERIFICATION(state, currentState) {
    state.isCheckecIdentity = currentState;
  },
  SET_SEARCH_POPUP(state, currentState) {
    state.checkSearchPopup = currentState;
  },
  SET_SEARCH_POPUP_REFORM(state, currentState) {
    state.checkSearchPopupReform = currentState;
  },
};

const actions = {};

export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
