import { promotion } from "@/api";
const state = {
  promotion: {
    promotionList: []
  },
  view: {
    doctorInfoList:[],
    drIdParam: null
  }
};

const getters = {
  GET_PROMOTION: state => state.promotion.promotionList,
  GET_DOCTOR_INFO: state => state.view.doctorInfoList,
  GET_DRID_PARAM: state => state.view.drIdParam
};

const mutations = {
  SET_PROMOTION(state, promotionData) {
    state.promotion.promotionList = promotionData;
    //console.log(promotionData);
  },
  SET_DOCTOR_INFO(state, doctorInfo) {
    state.view.doctorInfoList = doctorInfo;
  },
  SET_DRID_PARAM(state, drId) {
    state.view.drIdParam = drId;
  }
};

const actions = {
  PROMOTION_SUBMIT({}, payload) {
    return promotion.promotionSubmit(payload);
  },
  FETCH_PROMOTION({ commit }) {
    return promotion.fetchPromotion().then(data => {
      commit("SET_PROMOTION", data);
    });
  },
  FETCH_PROMOTION_TARGET({ commit }, payload) {
    return promotion.fetchPromotionTarget(payload).then(data => {
      commit("SET_PROMOTION", data);
    });
  },
  FETCH_PROMOTION_TARGET_UN_AUTH({ commit }, payload) {
    return promotion.fetchPromotionTargetUnAuth(payload).then(data => {
      commit("SET_PROMOTION", data);
    });
  },
  PROMOTION_MODIFY({}, payload) {
    return promotion.promotionModify(payload);
  }
  /*
 SEARCH_SUBMIT({ commit }, payload) {
        return search.searchSubmit(payload).then(data => {
            commit("SET_SEARCH", data.response);
        });
    },
    CHECK_ID({ commit }, userData) {
      return join.checkId(userData).then(data => {
        const transedData = {
        flag: data.response.flag
        };
        commit("SET_ISCHECKED_ID", transedData.flag);
      });
    }
    */
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
