<template>
  <v-app id="app">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <app-header-test
      :headerOption="headerOption"
      ref="appheader"
      v-if="headerOnOff"
    />
    <div class="spinner" v-if="isLoading">
      <transition name="slide-fade" mode="in-out" v-if="isLoading">
        <beat-loader
          :loading="isLoading"
          :color="loader.color"
          :size="loader.size"
        ></beat-loader>
      </transition>
    </div>
    <router-view
      :class="{ hide: isLoading }"
      @setLoader="loaderOn()"
      @offLoader="loaderOff()"
      @is-login="loginOn()"
      @send-app-header-method="sendAppheaderMethod"
    />
    <app-footer v-if="headerOnOff" />
  </v-app>
</template>

<script>
import AppHeaderTest from "@/components/appHeader/AppHeaderTest.vue";
import AppFooter from "@/components/AppFooter.vue";
import BeatLoader from "vue-spinner/src/BeatLoader";

import { mapState, mapMutations } from "vuex";

export default {
  name: "App",
  components: {
    AppFooter,
    BeatLoader,
    AppHeaderTest,
  },
  data() {
    return {
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      loader: {
        loading: false,
        color: "#ff6565",
        size: "18px",
      },
      headerOnOff: true,
    };
  },
  methods: {
    loaderOn() {
      //console.log("APP setLoader 실행됨");
      this.SET_LOADER(true);
    },
    loaderOff() {
      //console.log("APP offLoader 실행됨");
      this.SET_LOADER(false);
    },
    loginOn() {
      this.$refs.appheader.loginOn();
    },
    sendAppheaderMethod(sendAppheaderItem) {
      if (sendAppheaderItem.method === "writeMessage") {
        this.$refs.appheader.callMessage(sendAppheaderItem.item);
      } else if (sendAppheaderItem.method === "callMessage") {
        this.$refs.appheader.callMessage(sendAppheaderItem.item);
      } else if (sendAppheaderItem.method === "entHeader") {
        this.$refs.appheader.entOnOff(sendAppheaderItem.item);
      } else if (sendAppheaderItem.method === "appHeaderOff") {
        if (sendAppheaderItem.item === "off") {
          this.headerOnOff = false;
        } else {
          this.headerOnOff = true;
        }
      } else if (sendAppheaderItem.method === "callLoginPop") {
        this.$refs.appheader.callLoginPop(sendAppheaderItem.item);
      } else if (sendAppheaderItem.method === "clearItem") {
        this.$refs.appheader.clearLocalStorage();
      } else if (sendAppheaderItem.method === "callPwOwnCheckPopup") {
        this.$refs.appheader.callPwOwnCheckPopup();
      } else if (sendAppheaderItem.method === "clearLocalStorage") {
        this.$refs.appheader.clearLocalStorage();
      } else if (sendAppheaderItem.method === "initLogin") {
        this.$refs.appheader.initLogin();
      }
      // else if (sendAppheaderItem.method === "setMyPageTrue") {
      //   this.$refs.appheader.setMyPage(true);
      // } else if (sendAppheaderItem.method === "setMyPageFalse") {
      //   this.$refs.appheader.setMyPage(false);
      // }
    },
    ...mapMutations("basic", ["SET_LOADER"]), // 매개변수에 따라 isLoading state변경
  },
  computed: {
    ...mapState("basic", ["isLoading"]),
  },
};
</script>
<style lang="scss" scoped>
@import "assets/css/init.css";
@import "assets/css/common.css";
@import "assets/fonts/stylesheet.css";
</style>
