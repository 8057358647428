import { book } from "@/api";

const state = {
  book: {
    bookList: [],
    totalCnt: 0,
  },

  appointmentData: {
    bookAgrYn: "",
    kakaoUrl: "",
  },
};

const getters = {
  GET_BOOK_LIST: (state) => state.book.bookList,
  GET_TOTAL_CNT: (state) => state.book.totalCnt,
  GET_APP_DATA: (state) => state.appointmentData,
};

const mutations = {
  SET_BOOK_LIST(state, bookData) {
    state.book.bookList = bookData;
  },
  SET_TOTAL_CNT(state, cnt) {
    state.book.totalCnt = cnt;
  },
  SET_APP_DATA_YN(state, yn) {
    state.appointmentData.bookAgrYn = yn;
  },
  SET_APP_DATA_URL(state, url) {
    state.appointmentData.kakaoUrl = url;
  },
};

const actions = {
  ADD_BOOK({}, payload) {
    // console.log(payload);
    return book.addBook(payload);
  },
  SELECT_FROM_DRID({ commit }, payload) {
    return book.selectFromDrid(payload).then((data) => {
      commit("SET_BOOK_LIST", data.data);
      commit("SET_TOTAL_CNT", data.totalCnt);
    });
  },
  BOOK_CHECK({}, payload) {
    return book.bookCheck(payload).then(() => {
      console.log("완료");
    });
  },
  GET_APPOINTMENT_DATA({ commit }) {
    return book.getAppointmentData().then((data) => {
      commit("SET_APP_DATA_YN", data.data.bookAgrYn);
      commit("SET_APP_DATA_URL", data.data.kakaoUrl);
    });
  },
  UPDATE_APPOINTMENT_DATA({}, payload) {
    book.updateAppointmentData(payload);
  },
};

export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
