<template>
  <modal>
    <template v-slot:body>
      <div class="hospital-list pa-0" style="width: 100%">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="auto" class="text-h5 mr-5">
              <div>거리기준점</div>
            </v-col>
            <v-col cols="auto">
              <v-radio-group
                v-model="radioValue"
                row
                hide-details
                class="mt-0"
                mandatory
              >
                <v-radio
                  class="font-weight-bold"
                  color="reform_primary"
                  label="병원 정보 기준"
                  :value="0"
                ></v-radio>
                <v-radio
                  class="font-weight-bold"
                  color="reform_primary"
                  label="주소 입력"
                  :value="1"
                  @click="searchAddress"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if="radioValue === 1">
              {{ address.buildingname }}
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="9">
              <v-text-field
                hide-details
                class="mt-0"
                v-model="searchOpt.searchTxt"
                maxlength="40"
                @keyup.enter="searchDoctor()"
                outlined
                color="reform_primary"
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" align-self="center">
              <v-btn
                width="100%"
                color="reform_primary"
                elevation="0"
                rounded
                dark
                height="45px"
                @click="searchDoctor()"
                v-text="'검색'"
              >
              </v-btn>
            </v-col>
          </v-row>
          <div
            class="list mt-2"
            v-if="hospital.length > 0"
            style="
              overflow-y: auto;
              max-height: 350px;
              border-top: 3px solid #146f83;
              border-bottom: 3px solid #146f83;
            "
          >
            <div
              class="item collect"
              v-for="(hospitalInfo, hospitalIndex) in hospital"
              :key="hospitalIndex"
              @click="selectDoctor(hospitalIndex)"
            >
              <div class="l-context list">
                <div class="doctor-name">
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-col
                      cols="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="text-h6 font-weight-bold reform_primary--text"
                      align-self="center"
                    >
                      {{ hospitalInfo.drNm }}
                      <span vertical v-if="hospitalInfo.proObj !== null">
                        /
                      </span>
                      {{ hospitalInfo.proObj }}
                    </v-col>
                    <v-col cols="12" md="6" lg="6" xl="6" align-self="center">
                      <v-chip
                        dark
                        color="black"
                        class="white--text"
                        v-text="getDistance(hospitalInfo.distance)"
                      >
                      </v-chip>
                    </v-col>
                  </v-row>
                </div>

                <div style="display: grid">
                  <div class="hospital-info">
                    <p>{{ hospitalInfo.hospNm }}</p>
                    <p>{{ hospitalInfo.addr }} {{ hospitalInfo.addrDtl }}</p>
                    <p v-if="hospitalInfo.hospTel !== null">
                      Tel: {{ hospitalInfo.hospTel }}
                    </p>
                    <p v-if="hospitalInfo.hospFaxNo">
                      Fax: {{ hospitalInfo.hospFaxNo }}
                    </p>
                  </div>
                  <v-row>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.diagNm1 !== null &&
                        hospitalInfo.diagNm1 !== ''
                      "
                      v-text="hospitalInfo.diagNm1"
                    >
                    </v-chip>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.diagNm2 !== null &&
                        hospitalInfo.diagNm2 !== ''
                      "
                      v-text="hospitalInfo.diagNm2"
                    >
                    </v-chip
                    ><v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.diagNm3 !== null &&
                        hospitalInfo.diagNm3 !== ''
                      "
                      v-text="hospitalInfo.diagNm3"
                    >
                    </v-chip>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.symptomNm1 !== null &&
                        hospitalInfo.symptomNm1 !== ''
                      "
                      v-text="hospitalInfo.symptomNm1"
                    >
                    </v-chip>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.symptomNm2 !== null &&
                        hospitalInfo.symptomNm2 !== ''
                      "
                      v-text="hospitalInfo.symptomNm2"
                    >
                    </v-chip
                    ><v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.symptomNm3 !== null &&
                        hospitalInfo.symptomNm3 !== ''
                      "
                      v-text="hospitalInfo.symptomNm3"
                    >
                    </v-chip>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.operNm1 !== null &&
                        hospitalInfo.operNm1 !== ''
                      "
                      v-text="hospitalInfo.operNm1"
                    >
                    </v-chip>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.operNm2 !== null &&
                        hospitalInfo.operNm2 !== ''
                      "
                      v-text="hospitalInfo.operNm2"
                    >
                    </v-chip
                    ><v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.operNm3 !== null &&
                        hospitalInfo.operNm3 !== ''
                      "
                      v-text="hospitalInfo.operNm3"
                    >
                    </v-chip>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.equipNm1 !== null &&
                        hospitalInfo.equipNm1 !== ''
                      "
                      v-text="hospitalInfo.equipNm1"
                    >
                    </v-chip>
                    <v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.equipNm2 !== null &&
                        hospitalInfo.equipNm2 !== ''
                      "
                      v-text="hospitalInfo.equipNm2"
                    >
                    </v-chip
                    ><v-chip
                      dark
                      class="ma-1 chips-text white--text"
                      color="reform_primary"
                      v-if="
                        hospitalInfo.equipNm3 !== null &&
                        hospitalInfo.equipNm3 !== ''
                      "
                      v-text="hospitalInfo.equipNm3"
                    >
                    </v-chip>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
          <div class="notification-list" v-else>
            <div class="item">
              <div class="logo-wrap">
                <img src="@/assets/images/logo_unplugged.svg" />
              </div>
              <h4>결과 없음</h4>
            </div>
          </div>
          <div class="text-center mt-5" v-if="hospital.length > 0">
            <v-pagination
              circle
              :length="listStatus.totalPage"
              v-model="listStatus.currentPage"
              color="reform_primary"
              @input="loadSerchList"
              total-visible="5"
            >
            </v-pagination>
          </div>
        </v-container>
      </div>
      <address-popup
        @responseAddress="getAddress($event)"
        @close-address-modal="checkRadioValue"
        v-if="checkAddressPopup"
        :popupSetAddress="popupSetAddress"
      />
      <button id="focusControl"></button>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import Modal from "./ModalSearch.vue";
import AddressPopup from "@/components/modal/AddressPopup";

export default {
  props: {
    popupSetSearch: {},
  },
  components: {
    Modal,
    AddressPopup,
  },
  data() {
    return {
      radioValue: 0,
      popupSet: {},
      popupSetAddress: {},
      sideSet: {
        menuName: "research",
        sideTitle: "검색결과",
      },
      getCheckValue: {
        distance: [
          {
            flag: false,
            type: "hospital",
          },
          {
            flag: false,
            type: "own",
          },
        ],
      },
      searchOpt: {
        addrType: 1, // distance 옵션
        orderType: 1, // default 옵션,
        recordsPerPage: "", // 한번에 불러올 갯수
        searchTxt: "", // 검색어
        preSearchTxt: "", // 이전검색어 저장
        addr: null,
      },
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      address: {
        buildingname: "",
        zonecode: "",
        bname: "",
      },
      hospital: [],
    };
  },
  mounted() {},
  computed: {
    ...mapState("basic", [
      "checkSearchPopup",
      "checkAddressPopup",
      "isChildLoading",
      "checkSearchPopupReform",
    ]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("referList", ["GET_SEARCH_LIST"]),
    getDistance() {
      return (value) => {
        const calcuratedKM = Math.floor(value / 1000);
        if (calcuratedKM < 11) {
          return "10KM 이내";
        } else {
          return calcuratedKM + " KM";
        }
      };
    },
  },
  methods: {
    ...mapMutations("basic", [
      "SET_SEARCH_POPUP_REFORM",
      "SET_ADDRESS_POPUP",
      "SET_CHILD_LOADER",
    ]),
    ...mapActions("referList", ["BRING_SEARCH_LIST"]),
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        /*
        console.log(
          "Ds",
          document.querySelector("#pagination").childNodes[k].childNodes[0]
            .textContent
        );
        */
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    getAddress(address) {
      this.address.buildingname = address.address + " " + address.buildingName;
      this.address.zonecode = address.zonecode;
      this.address.bname = address.bname;
      this.bringAddress(this.address);
      this.SET_ADDRESS_POPUP(false);
    },
    checkRadioValue() {
      if (
        this.address.buildingname === null ||
        this.address.buildingname === undefined ||
        this.address.buildingname === ""
      )
        this.radioValue = 0;
    },
    searchAddress() {
      console.log(this.radioValue);
      this.SET_ADDRESS_POPUP(true);
      this.popupSetAddress.popType = "address";
    },
    bringAddress(result) {
      this.searchOpt.addrType = 2;
      this.searchOpt.addr = result.buildingname;
    },
    async loadSerchList(order) {
      let searchOption;
      try {
        searchOption = {
          orderType: this.searchOpt.orderType,
          addrType: this.searchOpt.addrType,
          searchWord: this.searchOpt.searchTxt,
          recordsPerPage: this.listStatus.dataOnPage,
          pageNo: order,
          addr: this.searchOpt.addr,
          auth: true,
        };
        await this.BRING_SEARCH_LIST(searchOption).then(() => {
          //console.log("가져온 결과값", this.GET_SEARCH_LIST);
          this.listStatus.totalDataCnt = this.GET_SEARCH_LIST.param.totalCount;
          /** this.listStatus.currentPage = this.GET_SEARCH_LIST.param.pageNo;*/
          /** this.listStatus.dataOnPage = this.GET_SEARCH_LIST.param.recordsPerPage; */
          this.searchOpt.preSearchTxt = this.GET_SEARCH_LIST.param.searchWord;
          this.hospital = this.GET_SEARCH_LIST.list;
          this.listStatus.currentPage = order;
        });
      } catch (error) {
        console.log("error: ", error);
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          this.loadSerchList(this.listStatus.last, 4);
          // for (
          //   let k = 0;
          //   k < document.querySelector("#pagination").childElementCount;
          //   k++
          // ) {
          //   /*
          //   console.log(
          //     "재료",
          //     document.querySelector("#pagination").childNodes[k].childNodes[0]
          //       .textContent
          //   );
          //   */
          //   if (
          //     Number(
          //       document.querySelector("#pagination").childNodes[k]
          //         .childNodes[0].textContent
          //     ) === Number(this.listStatus.last)
          //   ) {
          //     document
          //       .querySelector("#pagination")
          //       .childNodes[k].classList.add("active");
          //   }
          // }
        });
      } else if (type === "next") {
        this.loadSerchList(this.listStatus.currentPage, 0);
        // document
        //   .querySelector("#pagination")
        //   .childNodes[0].classList.add("active");
      } else {
        // if (this.listStatus.totalDataCnt >= 1) {
        //   this.$nextTick((_) => {
        //     document
        //       .querySelector("#pagination")
        //       .childNodes[this.listStatus.currentPage - 1].classList.add(
        //         "active"
        //       );
        //   });
        // }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalDataCnt = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      //console.log("totalDataCnt", totalDataCnt);
      //console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      //console.log("totalPage", totalPage);
      //console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      //console.log("last : " + last);
      //console.log("first : " + first);
      //console.log("next :" + next);
      //console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    async searchDoctor() {
      let searchOption;
      if (
        this.searchOpt.searchTxt !== null &&
        this.searchOpt.searchTxt !== ""
      ) {
        try {
          if (localStorage.getItem("token") === null) {
            searchOption = {
              orderType: this.searchOpt.orderType,
              addrType: 2,
              searchWord: this.searchOpt.searchTxt,
              recordsPerPage: this.listStatus.dataOnPage,
              pageNo: this.listStatus.currentPage,
              addr: this.searchOpt.addr,
              auth: false,
            };
          } else if (this.searchOpt.addrType === 2) {
            //console.log("option : 2");
            searchOption = {
              orderType: this.searchOpt.orderType,
              addrType: this.searchOpt.addrType,
              searchWord: this.searchOpt.searchTxt,
              recordsPerPage: this.listStatus.dataOnPage,
              pageNo: this.listStatus.currentPage,
              addr: this.searchOpt.addr,
              auth: true,
            };
          } else {
            //console.log("option : else");
            //console.log(this.GET_BASIC);
            this.searchOpt.addr = this.GET_BASIC.addr;
            searchOption = {
              orderType: this.searchOpt.orderType,
              addrType: this.searchOpt.addrType,
              searchWord: this.searchOpt.searchTxt,
              recordsPerPage: this.listStatus.dataOnPage,
              pageNo: this.listStatus.currentPage,
              addr: this.searchOpt.addr,
              auth: true,
            };
          }
          // console.log(searchOption);
          await this.BRING_SEARCH_LIST(searchOption).then(() => {
            //console.log("가져온 결과값", this.GET_SEARCH_LIST);
            this.listStatus.totalDataCnt =
              this.GET_SEARCH_LIST.param.totalCount;
            /** this.listStatus.currentPage = this.GET_SEARCH_LIST.param.pageNo;*/
            /** this.listStatus.dataOnPage = this.GET_SEARCH_LIST.param.recordsPerPage; */
            this.searchOpt.preSearchTxt = this.GET_SEARCH_LIST.param.searchWord;
            this.hospital = this.GET_SEARCH_LIST.list;
            this.preParePagination();
          });
        } catch (error) {
          console.log("error: ", error);
          this.hospital = [];
        }
      } else {
        this.SET_CHILD_LOADER(false);
        this.hospital = [];
      }

      const focusControl = document.getElementById("focusControl");
      //console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    selectDoctor(order) {
      this.$emit("receive-doctor", this.hospital[order]);
      this.SET_SEARCH_POPUP_REFORM(false);
    },
    checkReceived(type, order) {
      if (type === "first") {
        for (let i = 0; i < this.getCheckValue.default.length; i++) {
          if (order !== i) {
            this.getCheckValue.default[i].flag = false;
          } else {
            this.getCheckValue.default[i].flag = true;
            this.searchOpt.orderType = order + 1;
          }
        }
      } else if (type === "second") {
        for (let i = 0; i < this.getCheckValue.distance.length; i++) {
          if (order !== i) {
            this.getCheckValue.distance[i].flag = false;
          } else {
            this.getCheckValue.distance[i].flag = true;
            this.searchOpt.addrType = 1;
          }
        }
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    if (document.querySelector("body").classList.contains("spreaded")) {
      document.querySelector("body").classList.remove("spreaded");
    }
    this.$emit("reload-nav");
    this.SET_ADDRESS_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
.chips-text {
  font-size: 14px !important;
}
</style>

<style>
  @media screen and (max-width: 920px) {
    .modal-container {
        width: 95% !important;
      }
  }

  @media screen and (max-width: 500px) {
    .hospital-list {
      min-width: 130% !important;
    }
  }
</style>