<template>
  <modal :destination="defineUrl !== '' ? true : false" :isFixing="isFixing">
    <template v-slot:body>
      <div class="api-pop" v-if="popType === 'DrLicense'">
        <!-- 스피너 시작 -->
        <div class="spinner" v-if="isChildLoading">
          <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
            <beat-loader
              :loading="isChildLoading"
              :color="loader.color"
              :size="loader.size"
            ></beat-loader>
          </transition>
        </div>
        <!-- 스피너 끝 -->
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content" :class="{ hide: isChildLoading }">
          <div class="article">
            <div class="item">
              <div class="input-wrap">
                <input
                  type="text"
                  :placeholder="placeholder"
                  v-model="doctorLicenseReduplication.doctorLicense.value"
                  @keyup="checkDoctorLicense()"
                  @blur="checkDoctorLicense()"
                />
              </div>
              <div
                class="button-wrap"
                :class="{
                  active:
                    doctorLicenseReduplication.doctorLicense.value.length ===
                      5 && doctorLicenseReduplication.doctorLicense.flag,
                }"
              >
                <button
                  type="button"
                  @click="checkDrPopup()"
                  :disabled="
                    doctorLicenseReduplication.doctorLicense.value.length !==
                      5 && !doctorLicenseReduplication.doctorLicense.flag
                  "
                >
                  {{ apiBtnText }}
                </button>
              </div>
            </div>
            <p
              class="error-occure"
              :class="{
                active: doctorLicenseReduplication.doctorLicense.hasError,
              }"
              v-text="doctorLicenseReduplication.doctorLicense.errorTxt"
            ></p>
          </div>
          <div class="warn-wrap">
            <p v-html="warnMutated"></p>
          </div>
          <div
            class="btn-wrap"
            :class="{ activated: doctorLicenseReduplication.isChecked }"
          >
            <button
              :disabled="!doctorLicenseReduplication.isChecked"
              v-text="confirmBtnText"
              type="button"
              @click="drLicenseDone()"
            />
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'Message'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="message custom">
          <v-container>
            <v-row class="pa-0">
              <v-col cols="12" class="text-center text-h4 pd-0 mg-0">
                쪽지 보내기
              </v-col>
              <v-col cols="12">
                <v-row justify="center" align="center" class="pa-0">
                  <v-col cols="8" class="text-center">
                    <v-text-field
                      outlined
                      readonly
                      v-model="dataParam.drNm"
                      placeholder="받는 사람"
                      @click="callHospitalInfo()"
                      style="cursor: pointer"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-btn
                      dark
                      rounded
                      color="reform_primary"
                      elevation="0"
                      @click="callHospitalInfo()"
                      width="100%"
                      style="cursor: pointer"
                    >
                      <span style="color: white; font-size: 17px">선택</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pd-0" style="height: 80px">
                <v-text-field
                  outlined
                  v-model="form.message.title"
                  placeholder="제목을 입력해주세요"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="form.message.content"
                  placeholder="내용을 입력해주세요"
                  auto-grow
                />
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="8">
                    <div
                      color="black"
                      readonly
                      v-text="form.message.attachment.filename"
                      @click="attachmentFile()"
                      style="
                        height: 50px;
                        cursor: pointer;
                        border-bottom: 1px solid black;
                      "
                    />
                  </v-col>
                  <v-col cols="4" style="line-height: 80px">
                    <v-btn
                      dark
                      rounded
                      color="reform_primary"
                      elevation="0"
                      @click="attachmentFile()"
                      style="width: 100%; cursor: pointer; margin-bottom: 20px"
                    >
                      <span style="color: white; font-size: 17px"
                        >첨부파일</span
                      >
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  dark
                  color="reform_primary"
                  elevation="0"
                  @click="submitMsg()"
                  v-text="'전송하기'"
                  width="70%"
                  height="50"
                  style="font-size: 20px"
                  v-if="
                    form.message.title !== null && form.message.content !== null
                  "
                >
                </v-btn>
                <v-btn
                  dark
                  color="#bcbcbc"
                  elevation="0"
                  v-text="'전송하기'"
                  width="70%"
                  height="50"
                  style="font-size: 20px"
                  v-else
                  @click="submitMsg()"
                >
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <div
            class="attachment"
            hidden
            style="position: relative; top: 5000px"
          >
            <input
              class="file-upload"
              type="file"
              @change="onFileChange($event)"
            />
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'LoginMain'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <!-- <h4 class="title" v-html="titleMutated2"></h4> -->
          <h4 class="title">로그인</h4>
          <div class="subtitle">
            <!-- <p v-html="contentMutated"></p> -->
            <p>의사회원만 이용 가능한 서비스 입니다.</p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <v-row no-gutters justify="space-between">
                  <v-col cols="auto">
                    <p class="text-left">아이디</p>
                  </v-col>
                  <v-col cols="auto">
                    <v-switch
                      class="pt-4 ma-0"
                      v-model="form.login.view.isIdStored"
                      hide-details
                      dense
                      inset
                      id="loginPeriod"
                      :label="`아이디저장`"
                      color="reform_primary"
                    ></v-switch>
                  </v-col>
                </v-row>

                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="form.login.id.value"
                    @keyup="checkLoginId()"
                    @blur="checkLoginId()"
                    @keyup.enter="enteredLogin()"
                    placeholder="아이디"
                  />
                </div>
                <p>비밀번호</p>
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.login.password.value"
                    @keyup="checkLoginPw()"
                    @blur="checkLoginPw()"
                    @keyup.enter="enteredLogin()"
                    placeholder="비밀번호"
                  />
                </div>
              </div>
            </div>
            <div class="switch_wrap">
              <v-container fluid class="pa-0 ma-0">
                <v-row no-gutters justify="center" align="center">
                  <v-col cols="12" md="4" lg="4" xl="4">
                    <!-- <label class="switch">
                      <input
                        type="checkbox"
                        id="loginPeriod"
                        v-model="form.login.view.isIdStored"
                      />
                      <span class="slider round"></span>
                    </label>
                    <p class="switch_text">아이디저장</p> -->
                  </v-col>
                  <v-col cols="12">
                    <v-breadcrumbs
                      :items="breaditems"
                      large
                      class="justify-center reform_primary--text"
                      :divider="'|'"
                    >
                      <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                          @click="reloadPop(item.method)"
                          :disabled="item.disabled"
                        >
                          {{ item.text }}
                        </v-breadcrumbs-item>
                      </template>
                    </v-breadcrumbs>
                    <!-- <ul class="auth-hyper-link row">
                      <li @click="reloadPop('FindId')">아이디를 잊어버리셨나요?</li>
                      <li @click="reloadPop('FindPw')">비밀번호를 잊어버리셨나요?</li>
                    </ul> -->
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <div
              class="btn-wrap login_btn"
              :class="{
                active: form.login.id.flag && form.login.password.flag,
              }"
            >
              <button type="button" @click="login()">로그인</button>
            </div>
            <div
              class="btn-wrap resiste_btn"
              :class="{
                active: form.login.id.flag && form.login.password.flag,
              }"
            >
              <button type="button" @click="goRegiste()">회원가입</button>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'Login'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="form.login.id.value"
                    @keyup="checkLoginId()"
                    @blur="checkLoginId()"
                    @keyup.enter="enteredLogin()"
                    placeholder="아이디"
                  />
                </div>
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.login.password.value"
                    @keyup="checkLoginPw()"
                    @blur="checkLoginPw()"
                    @keyup.enter="enteredLogin()"
                    placeholder="비밀번호"
                  />
                </div>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active: form.login.id.flag && form.login.password.flag,
                }"
              >
                <button type="button" @click="login()">로그인</button>
              </div>
            </div>
            <div class="switch_wrap">
              <label class="switch">
                <input
                  type="checkbox"
                  id="loginPeriod"
                  v-model="form.login.view.isIdStored"
                />
                <span class="slider round"></span>
              </label>
              <p>아이디저장</p>
            </div>
            <ul class="auth-hyper-link row">
              <li @click="callJoinPage()">회원가입</li>
              <li @click="reloadPop('FindId')">아이디 찾기</li>
              <li @click="reloadPop('FindPw')">비밀번호 찾기</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'IssueChild'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.password.value"
                    @keyup="checkPw()"
                    @blur="checkPw()"
                  />
                </div>
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.passwordConfirm.value"
                    @keyup="checkPwConfirm()"
                    @blur="checkPwConfirm()"
                  />
                </div>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active:
                    form.changePw.password.flag &&
                    form.changePw.passwordConfirm.flag,
                }"
              >
                <button
                  type="button"
                  @click="issueChild(dataParam)"
                  :disabled="
                    !form.changePw.password.flag ||
                    !form.changePw.passwordConfirm.flag
                  "
                >
                  확인
                </button>
              </div>
              <p class="warn-message" v-text="form.changePw.view.errorTxt"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'CheckPassword'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.password.value"
                    @keyup="checkPw()"
                    @blur="checkPw()"
                  />
                </div>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active: form.changePw.password.flag,
                }"
              >
                <button
                  type="button"
                  @click="confirmPw()"
                  :disabled="!form.changePw.password.flag"
                >
                  확인
                </button>
              </div>
              <p class="warn-message" v-text="form.changePw.view.errorTxt"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'CheckPasswordOwn'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.password.value"
                    @keyup="checkPw()"
                    @blur="checkPw()"
                  />
                </div>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active: form.changePw.password.flag,
                }"
              >
                <button
                  type="button"
                  @click="confirmPwOwn()"
                  :disabled="!form.changePw.password.flag"
                >
                  확인
                </button>
              </div>
              <p class="warn-message" v-text="form.changePw.view.errorTxt"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'ChangePassword'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.password.value"
                    @keyup="checkPw()"
                    @blur="checkPw()"
                  />
                </div>
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.passwordConfirm.value"
                    @keyup="checkPwConfirm()"
                    @blur="checkPwConfirm()"
                  />
                </div>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active:
                    form.changePw.password.flag &&
                    form.changePw.passwordConfirm.flag,
                }"
              >
                <button
                  type="button"
                  @click="changePw()"
                  :disabled="
                    !form.changePw.password.flag ||
                    !form.changePw.passwordConfirm.flag
                  "
                >
                  확인
                </button>
              </div>
              <p class="warn-message" v-text="form.changePw.view.errorTxt"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'ChangePasswordOwn'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.password.value"
                    @keyup="checkPw()"
                    @blur="checkPw()"
                  />
                </div>
                <div class="input-wrap">
                  <input
                    type="password"
                    v-model="form.changePw.passwordConfirm.value"
                    @keyup="checkPwConfirm()"
                    @blur="checkPwConfirm()"
                  />
                </div>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active:
                    form.changePw.password.flag &&
                    form.changePw.passwordConfirm.flag,
                }"
              >
                <button
                  type="button"
                  @click="changePwOwn()"
                  :disabled="
                    !form.changePw.password.flag ||
                    !form.changePw.passwordConfirm.flag
                  "
                >
                  확인
                </button>
              </div>
              <p class="warn-message" v-text="form.changePw.view.errorTxt"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop find_id" v-if="popType === 'FindId'">
        <!-- 스피너 끝 -->
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <!-- <div class="input-wrap">
                  <input
                    type="text"
                    v-model="form.findId.name.value"
                    placeholder="이름"
                    @keyup="checkFindIdName()"
                    @blur="checkFindIdName()"
                  />
                </div>
                <div class="input-wrap-complex">
                  <div class="input-wrap">
                    <input
                      type="text"
                      v-model="form.findId.phone.value"
                      placeholder="휴대폰번호"
                      @keyup="checkFindIdPhone()"
                      @blur="checkFindIdPhone()"
                    />
                  </div>
                  <div
                    class="btn-wrap"
                    :class="{
                      active: form.findId.name.flag && form.findId.phone.flag,
                    }"
                  >
                    <button
                      type="button"
                      @click="findIdSmsCall()"
                      v-text="form.findId.view.btnTxt"
                      :disabled="
                        !form.findId.name.flag || !form.findId.phone.flag
                      "
                    ></button>
                  </div>
                </div>
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="form.findId.receiveSms.value"
                    placeholder="인증번호"
                    @keyup="checkFindIdSms()"
                    @blur="checkFindIdSms()"
                    maxlength="6"
                  />
                </div> -->
                <v-container fluid>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        hide-details
                        outlined
                        color="reform_primary"
                        v-model="form.findId.name.value"
                        placeholder="이름"
                        @keyup="checkFindIdName()"
                        @blur="checkFindIdName()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col class="pr-2">
                      <v-text-field
                        hide-details
                        outlined
                        color="reform_primary"
                        v-model="form.findId.phone.value"
                        placeholder="휴대폰번호"
                        @keyup="checkFindIdPhone()"
                        @blur="checkFindIdPhone()"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="auto" class="pt-1">
                      <v-btn
                        @click="findIdSmsCall()"
                        v-text="form.findId.view.btnTxt"
                        :disabled="
                          !form.findId.name.flag || !form.findId.phone.flag
                        "
                        :outlined="
                          !form.findId.name.flag || !form.findId.phone.flag
                        "
                        :dark="form.findId.name.flag && form.findId.phone.flag"
                        color="reform_primary"
                        elevation="0"
                        height="48px"
                        rounded
                      >
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        hide-details
                        outlined
                        color="reform_primary"
                        v-model="form.findId.receiveSms.value"
                        placeholder="인증번호"
                        @keyup="checkFindIdSms()"
                        @blur="checkFindIdSms()"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active:
                    form.findId.view.isSmsTrans &&
                    form.findId.name.flag &&
                    form.findId.phone.flag &&
                    form.findId.receiveSms.flag,
                }"
              >
                <button
                  type="button"
                  @click="findIdSubmit()"
                  :disabled="!form.findId.receiveSms.flag"
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop find_fw" v-if="popType === 'FindPw'">
        <!-- 스피너 끝 -->
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="article">
            <div class="item">
              <div class="login-complex">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="form.findPw.id.value"
                    placeholder="아이디"
                    @keyup="checkFindPwId()"
                    @blur="checkFindPwId()"
                  />
                </div>
                <div class="input-wrap-complex">
                  <div class="input-wrap">
                    <input
                      type="text"
                      v-model="form.findPw.phone.value"
                      placeholder="휴대폰번호"
                      @keyup="checkFindPwPhone()"
                      @blur="checkFindPwPhone()"
                    />
                  </div>
                  <div
                    class="btn-wrap"
                    :class="{
                      active: form.findPw.id.flag && form.findPw.phone.flag,
                    }"
                  >
                    <button
                      type="button"
                      @click="findPwSmsCall()"
                      v-text="form.findPw.view.btnTxt"
                      :disabled="
                        !form.findPw.id.flag || !form.findPw.phone.flag
                      "
                    ></button>
                  </div>
                </div>
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="form.findPw.receiveSms.value"
                    placeholder="인증번호"
                    @keyup="checkFindPwSms()"
                    @blur="checkFindPwSms()"
                    maxlength="6"
                  />
                </div>
              </div>
              <div
                class="btn-wrap"
                :class="{
                  active:
                    form.findPw.view.isSmsTrans &&
                    form.findPw.id.flag &&
                    form.findPw.phone.flag &&
                    form.findPw.receiveSms.flag,
                }"
              >
                <button
                  type="button"
                  @click="findPwSubmit()"
                  :disabled="!form.findPw.receiveSms.flag"
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'replyComment'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div class="textarea-wrap">
            <textarea
              v-model="form.replyComment.value"
              @keyup="checkReplyComment()"
              @blur="checkReplyComment()"
              placeholder="회신내용을 입력해주세요"
            />
          </div>
          <div class="btn-wrap" :class="{ active: form.replyComment.flag }">
            <button
              type="button"
              :disabled="!form.replyComment.flag"
              @click="checkStepForVisit()"
            >
              회신
            </button>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'replyComment1'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title" v-html="titleMutated"></h4>
          <div class="subtitle">
            <p v-html="contentMutated"></p>
          </div>
        </div>
        <div class="content">
          <div
            class="btn-wrap"
            :class="{ active: form.replyComment.flag }"
            style="display: flex; width: 400px !important"
          >
            <button
              type="button"
              :disabled="!form.replyComment.flag"
              @click="sendReplyCommentWithoutVisit()"
            >
              내원확인X
            </button>
            <div style="width: 10px; background: #fff"></div>
            <button
              type="button"
              :disabled="!form.replyComment.flag"
              @click="sendReplyComment()"
            >
              내원확인O
            </button>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'replyComment2'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title">회신 등록</h4>
          <div class="subtitle">
            <p>
              회신 등록을 진행하면 자동으로 내원확인이 같이 진행되게 됩니다.
            </p>
            <p>내원 여부를 확인하고 마저 회신 등록을 진행해 주세요.</p>
          </div>
        </div>
        <div class="content">
          <div
            class="btn-wrap"
            :class="{ active: true }"
            style="display: flex; width: 400px !important"
          >
            <button type="button" @click="btnClose()">취소</button>
            <div style="width: 10px; background: #fff"></div>
            <button type="button" @click="sendReplyComment()">회신 등록</button>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'replyComment3'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title">회신 수정</h4>
          <div class="subtitle">
            <p>현재 등록되어있는 회신 사항이 있습니다.</p>
            <p>회신 수정 버튼을 누르시면 회신을 수정합니다.</p>
          </div>
        </div>
        <div class="content">
          <div
            class="btn-wrap"
            :class="{ active: true }"
            style="display: flex; width: 400px !important"
          >
            <button type="button" @click="btnClose()">취소</button>
            <div style="width: 10px; background: #fff"></div>
            <button type="button" @click="sendReplyComment()">회신 수정</button>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'refund'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title">구매 취소 확인</h4>
          <div class="subtitle">
            <p>해당 구매를 취소하시겠습니까?</p>
          </div>
        </div>
        <div class="content">
          <div
            class="btn-wrap"
            :class="{ active: true }"
            style="display: flex; width: 400px !important"
          >
            <button type="button" @click="btnCloseRefund()">취소</button>
            <div style="width: 10px; background: #fff"></div>
            <button type="button" @click="paymentRefund()">구매 취소</button>
          </div>
        </div>
      </div>
      <div class="api-pop" v-if="popType === 'visitPopup'">
        <div class="close" @click="btnClose()">
          <!-- <img src="@/assets/images/x.svg" /> -->
          <span class="material-icons-outlined">close</span>
        </div>
        <div class="header">
          <h4 class="title">내원 확인</h4>
          <div class="subtitle">
            <p>내원확인을 누르시면 내원확인이 완료됩니다.</p>
          </div>
        </div>
        <div class="content">
          <div
            class="btn-wrap"
            :class="{ active: true }"
            style="display: flex; width: 400px !important"
          >
            <button type="button" @click="btnClose()">취소</button>
            <div style="width: 10px; background: #fff"></div>
            <button type="button" @click="sendVisitYn()">내원 확인</button>
          </div>
        </div>
      </div>
      <search-hospital-popup
        v-if="checkSearchPopup"
        :popupSetSearch="popupSetSearch"
        @receive-doctor="receiveDoctor"
        @reload-nav="reloadNav"
      />
      <search-hospital-popup-reform
        v-if="checkSearchPopupReform"
        :popupSetSearch="popupSetSearch"
        @receive-doctor="receiveDoctor"
        @reload-nav="reloadNav"
      />
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import BeatLoader from "vue-spinner/src/BeatLoader";
import Modal from "./Modal.vue";
import SearchHospitalPopup from "@/components/modal/SearchHospitalPopup";
import SearchHospitalPopupReform from "@/components/modal/SearchHospitalPopup_reform";

export default {
  props: {
    apiSet: {
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      apiBtnText: {
        type: String,
      },
      warnMessage: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      prevLink: {
        type: String,
      },
      defineUrl: {
        type: String,
      },
      customParam: {
        type: String,
      },
      customParam2: {
        type: String,
      },
      dataParam: {
        type: Object,
      },
      isFixing: {
        type: Boolean,
      },
      rcvAttachment: {
        type: Object,
      },
    },
  },
  components: {
    Modal,
    BeatLoader,
    SearchHospitalPopup,
    SearchHospitalPopupReform,
  },
  data() {
    return {
      popupSetSearch: {},
      showNav: true,
      popType: this.apiSet.popType,
      title: this.apiSet.title,
      content: this.apiSet.content,
      warnMessage: this.apiSet.warnMessage,
      placeholder: this.apiSet.placeholder,
      isFixing: this.apiSet.isFixing,
      apiBtnText: this.apiSet.apiBtnText,
      confirmBtnText: this.apiSet.confirmBtnText,
      cancelBtnText: this.apiSet.cancelBtnText,
      defineUrl: this.apiSet.defineUrl,
      nextLink: this.apiSet.nextLink,
      prevLink: this.apiSet.prevLink,
      customParam: this.apiSet.customParam,
      customParam2: this.apiSet.customParam2,
      dataParam: this.apiSet.dataParam,
      rcvAttachment: this.apiSet.rcvAttachment,
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      doctorLicenseReduplication: {
        doctorLicense: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*6~20자리 영문 또는 숫자를 입력해주세요",
        },
        isChecked: false,
      },
      form: {
        message: {
          title: null,
          attachment: {
            filename: null,
            url: null,
            source: null,
          },
          content: null,
        },
        login: {
          id: {
            value: "",
            flag: false,
          },
          password: {
            value: "",
            flag: false,
          },
          view: {
            isIdStored: false,
            errorTxt: "아이디 또는 비밀번호의 값을 입력해주세요.",
          },
        },
        changePw: {
          password: {
            value: "",
            flag: false,
          },
          passwordConfirm: {
            value: "",
            flag: false,
          },
          view: {
            errorTxt: "비밀번호의 값을 입력해주세요.",
          },
        },
        findId: {
          name: {
            value: "",
            flag: false,
          },
          phone: {
            value: "",
            flag: false,
          },
          receiveSms: {
            value: "",
            flag: false,
          },
          view: {
            errorTxt: "이름 또는 휴대폰번호의 값을 입력해주세요.",
            btnTxt: "인증번호 요청",
            isSmsTrans: false,
          },
        },
        findPw: {
          id: {
            value: "",
            flag: false,
          },
          phone: {
            value: "",
            flag: false,
          },
          receiveSms: {
            value: "",
            flag: false,
          },
          view: {
            errorTxt: "아이디 또는 휴대폰번호의 값을 입력해주세요.",
            btnTxt: "인증번호 요청",
            isSmsTrans: false,
          },
        },
        replyComment: {
          value: "",
          flag: false,
        },
      },
      locationUrl: null,
      isValidate: false,

      breaditems: [
        {
          text: "아이디 찾기",
          disabled: false,
          method: "FindId",
        },
        {
          text: "비밀번호 찾기",
          disabled: false,
          method: "FindPw",
        },
      ],
    };
  },
  watch: {},
  mounted() {},
  computed: {
    ...mapState("basic", [
      "checkApiPopup",
      "isChildLoading",
      "checkPopup",
      "checkSearchPopup",
      "checkSearchPopupReform",
    ]),
    ...mapGetters("join", ["GET_IS_DUPLICATED_DRLICENSE"]),
    ...mapGetters("auth", [
      "GET_SMS_LIST",
      "GET_SEARCHED_AUTH_DATA",
      "GET_TRANS_ID",
      "GET_CHECK_RESULT",
    ]),
    ...mapGetters("request", ["GET_RESP"]),
    titleMutated() {
      if (this.title !== undefined && this.title.indexOf("\n") !== -1) {
        return this.title.split("\n").join("<br />");
      } else {
        return this.title;
      }
    },
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf("\n") !== -1) {
        return this.content.split("\n").join("<br />");
      } else {
        return this.content;
      }
    },
    warnMutated() {
      if (
        this.warnMessage !== undefined &&
        this.warnMessage.indexOf("\n") !== -1
      ) {
        return this.warnMessage.split("\n").join("<br />");
      } else {
        return this.warnMessage;
      }
    },
  },
  methods: {
    ...mapMutations("basic", [
      "SET_API_POPUP",
      "SET_CHILD_LOADER",
      "SET_POP_CONFIG",
      "SET_SEARCH_POPUP",
      "SET_SEARCH_POPUP_REFORM",
    ]),
    ...mapMutations("auth", ["SET_TRANS_ID"]),
    ...mapActions("join", ["CHECK_DOCTOR_LICENSE", "SUB_SUBMIT"]),
    ...mapActions("auth", [
      "LOGIN",
      "BRING_SMS_CODE",
      "BRING_SEARCHED_ID",
      "CHANGE_PASSWORD",
      "CHANGE_PASSWORD_OWN",
      "CHECK_PASSWORD",
    ]),
    ...mapActions("note", ["SUBMIT_MESSAGE"]),
    ...mapActions("request", ["REPLY_WORD", "VISIT_CHECK", "CANCLE_ORDER"]),
    async paymentRefund() {
      const refundParam = this.rcvAttachment;

      console.log("refundParam : ", refundParam);

      //유효성 검사 시작

      //1. 취소금액
      if (
        refundParam.cancelRequestAmount === null ||
        refundParam.cancelRequestAmount === undefined
      )
        refundParam.cancelRequestAmount = "";

      var cancelRequestAmountBoo = /^[0-9]{1,7}$/;

      if (!cancelRequestAmountBoo.test(refundParam.cancelRequestAmount)) {
        const popConfig = {
          title: "취소금액 확인",
          content: "취소금액의 기준을 초과하였습니다. 고객센터에 문의해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //2. 아이디
      if (refundParam.drId === null || refundParam.drId === undefined)
        refundParam.drId = "";

      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

      if (!drIdBoo.test(refundParam.drId)) {
        const popConfig = {
          title: "비로그인 확인",
          content:
            "로그인이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //3. 상품번호
      if (
        refundParam.merchantUid === null ||
        refundParam.merchantUid === undefined
      )
        refundParam.merchantUid = "";

      var merchantUidBoo = /^[0-9]{8,14}$/;

      if (!merchantUidBoo.test(refundParam.merchantUid)) {
        const popConfig = {
          title: "비정상적인 상품주문번호",
          content:
            "상품주문번호가 정상적이지 않습니다. \n 고객센터에 문의해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }
      //impUid는 캔슬 시 null여부에 따라 체크됨

      let popConfig = {};
      await this.CANCLE_ORDER(refundParam).then((data) => {
        //console.log(this.GET_RESP);
        if (this.GET_RESP.resultCd === "0") {
          this.SET_CHILD_LOADER(false);
          popConfig = {
            title: "구매 취소 완료",
            content: "해당 구매내역을 취소하였습니다.",
            popType: "refund",
            cancelBtnText: "확인",
          };
        } else {
          popConfig = {
            title: "구매취소 불가",
            content:
              "해당상품의 구매취소가 불가합니다." + this.GET_RESP.resultMsg,
            popType: "warn",
            cancelBtnText: "확인",
          };
        }
      });

      this.SET_POP_CONFIG(popConfig);
      this.$emit("call-parents-popup");
      this.SET_API_POPUP(false);
    },
    receiveDoctor(doctorInfo) {
      //console.log("가져온", doctorInfo);
      this.dataParam = doctorInfo;
    },
    reloadNav() {
      if (!this.showNav) {
        this.showNav = true;
      }
    },
    callHospitalInfo() {
      document.querySelector("body").classList.add("spreaded");
      this.showNav = false;
      this.SET_SEARCH_POPUP_REFORM(true);
    },
    async submitMsg() {
      if (
        this.form.message.title === null ||
        this.form.message.content === null
      ) {
        const popConfig = {
          title: "입력값 확인",
          content: "쪽지에 전송할 내용을 모두 입력해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
      } else {
        try {
          //유효성 검사

          //1. 아이디
          if (this.dataParam.drId === null || this.dataParam.drId === undefined)
            this.dataParam.drId = "";

          var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

          if (!drIdBoo.test(this.dataParam.drId)) {
            const popConfig = {
              title: "로그인 확인",
              content:
                "로그인이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
            return;
          }

          //2. 제목
          if (
            this.form.message.title === null ||
            this.form.message.title === undefined
          )
            this.form.message.title = "";

          if (
            this.form.message.title.length < 1 ||
            this.form.message.title.length > 20
          ) {
            const popConfig = {
              title: "제목 확인",
              content: "제목은 1자 이상 20자 이내로 입력이 가능합니다.",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
            return;
          }

          //3. 내용
          if (
            this.form.message.content === null ||
            this.form.message.content === undefined
          )
            this.form.message.content = "";

          if (
            this.form.message.content.length < 1 ||
            this.form.message.content.length > 3000
          ) {
            const popConfig = {
              title: "내용 확인",
              content: "내용은 1자 이상 3000자 이내로 입력이 가능합니다.",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
            return;
          }

          const message = {
            recptDrId: this.dataParam.drId,
            transDrId: localStorage.getItem("drId"),
            title: this.form.message.title,
            conts: this.form.message.content,
          };
          const transedPrParam = JSON.stringify(message);
          const transData = new FormData();
          transData.append("noteParam", transedPrParam);
          //console.log("파일 첨부 여부 : ", this.form.message.attachment.source);
          if (this.form.message.attachment.source !== null) {
            transData.append("attachFile", this.form.message.attachment.source);
          }
          await this.SUBMIT_MESSAGE(transData).then(() => {
            const popConfig = {
              title: "쪽지발송 완료",
              content: "쪽지를 성공적으로 발송하였습니다.",
              popType: "warn",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
            this.SET_API_POPUP(false);
          });
        } catch (error) {
          console.log("error:", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            const popConfig = {
              title: "쪽지발송 실패",
              content: "쪽지를 발송할 수 없습니다.",
              popType: "warn",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
          }
        }
      }
    },
    attachmentFile() {
      let parentNode;
      parentNode = document.querySelector(".attachment");
      parentNode.querySelector("input.file-upload").click();
    },
    onFileChange(event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      //console.log("가져올 파일명: ", getFile[0].name);
      //console.log("가져올 파일의 URL", getFileUrl);
      this.form.message.attachment.source = event.target.files[0];
      /** getFile */
      this.form.message.attachment.url = getFileUrl;
      this.form.message.attachment.filename = getFile[0].name;
      this.form.message.attachment.flag = true;
      //console.log("파일", event.target.files[0]);
      //console.log("소스", this.form.message.attachment.source);
    },
    enteredLogin() {
      if (this.form.login.id.flag && this.form.login.password.flag) {
        //console.log("enteredLogin");
        this.login();
      }
    },
    async sendVisitYn() {
      //유효성 검사
      if (
        this.customParam === null ||
        this.customParam === undefined ||
        this.customParam === ""
      ) {
        const popConfig = {
          title: "오류",
          content: "비정상적인 오류가 발생하였습니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //console.log("referId", this.customParam);
      const payload = {
        referId: this.customParam,
      };
      try {
        await this.VISIT_CHECK(payload).then(() => {
          const popConfig = {
            title: "내원확인",
            content: "내원확인이 완료되었습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
            defineUrl: "/v/request/list",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "내원확인을 처리할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },

    async sendReplyComment() {
      try {
        //console.log("APi Pop=====================================");
        //console.log(this.customParam);
        //console.log(this.customParam2);
        //console.log(this.rcvAttachment);
        //console.log("-----------");

        //유효성 검사

        //1. referId
        if (this.customParam === null || this.customParam === undefined)
          this.customParam = "";

        var referIdBoo = /^[0-9]{8,14}$/;

        if (!referIdBoo.test(this.customParam)) {
          const popConfig = {
            title: "오류",
            content: "비정상적인 값이 확인되었습니다.",
            cancelBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
          return;
        }

        //2. rcvConts
        if (
          this.customParam2.value === null ||
          this.customParam2.value === undefined
        )
          this.customParam2.value = "";

        if (
          this.customParam2.value.length < 1 ||
          this.customParam2.value.length > 3000
        ) {
          const popConfig = {
            title: "내용 확인",
            content: "내용은 1자 이상 3000자 이내로 입력해주세요.",
            cancelBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
          return;
        }

        const reply = {
          referId: this.customParam,
          //rcvConts: this.form.replyComment.value,
          rcvConts: this.customParam2.value,
          visitYn: "Y",
        };
        //console.log("reply", reply);
        const transedReferParam = JSON.stringify(reply);
        const transData = new FormData();
        transData.append("referParam", transedReferParam);
        if (this.rcvAttachment !== "" && this.rcvAttachment !== null) {
          for (var i = 0; i < this.rcvAttachment.length; i++) {
            transData.append("rcvFileList", this.rcvAttachment[i]);
          }
        }
        await this.REPLY_WORD(transData).then(() => {
          const popConfig = {
            title: "회신등록 성공",
            content: "회신을 성공적으로 등록하였습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
            defineUrl: "/v/request/list",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          const popConfig = {
            title: "회신등록 실패",
            content: " 회신을 등록할 수 없습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
            defineUrl: "/v/request/list",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      }
    },
    async sendReplyCommentWithoutVisit() {
      //console.log("-----------");
      //console.log(this.customParam2);

      //유효성 검사

      //1. referId
      if (this.customParam === null || this.customParam === undefined)
        this.customParam = "";

      var referIdBoo = /^[0-9]{8,14}$/;

      if (!referIdBoo.test(this.customParam)) {
        const popConfig = {
          title: "오류",
          content: "비정상적인 값이 확인되었습니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //2. rcvConts
      if (
        this.customParam2.value === null ||
        this.customParam2.value === undefined
      )
        this.customParam2.value = "";

      if (
        this.customParam2.value.length < 1 ||
        this.customParam2.value.length > 3000
      ) {
        const popConfig = {
          title: "내용 확인",
          content: "내용은 1자 이상 3000자 이내로 입력해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      try {
        const reply = {
          referId: this.customParam,
          //rcvConts: this.form.replyComment.value,
          rcvConts: this.customParam2.value,
          visitYn: "N",
        };
        await this.REPLY_WORD(reply).then(() => {
          const popConfig = {
            title: "회신등록 성공",
            content: "회신을 성공적으로 등록하였습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
            defineUrl: "/v/request/list",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          const popConfig = {
            title: "회신등록 실패",
            content: " 회신을 등록할 수 없습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
            defineUrl: "/v/request/list",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      }
    },
    checkStepForVisit() {
      this.popType = "replyComment1";
      this.title = "회신등록(내원확인)";
      this.content =
        '내원확인을 완료하였는지 체크합니다.\n내원확인을 하지 않았을시 "내원확인X"버튼을,\n내원확인을 완료 하였을시 "내원확인O"버튼을 클릭해 주세요,';
    },
    checkReplyComment() {
      this.form.replyComment.flag = false;
      const replyComment = this.form.replyComment.value.replace(/\s/gi, "");
      if (replyComment === "") {
        this.form.replyComment.flag = false;
        return false;
      }
      this.form.replyComment.flag = true;
      return true;
    },
    async issueChild(value) {
      //console.log(value);

      //유효성 검사

      //1. 사용여부
      if (value.subYn === null || value.subYn === undefined) value.subYn = "";

      var usbYnBoo = /^[가-힣]{0,1}$/;

      if (!usbYnBoo.test(value.subYn)) {
        const popConfig = {
          title: "사용여부 오류",
          content: "부계정 사용여부를 확인해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //2. 이름
      if (value.nm === null || value.nm === undefined) value.nm = "";

      var nmBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|]{1,100}$/;

      if (!nmBoo.test(value.nm.replace(/\s/gi, ""))) {
        const popConfig = {
          title: "사용자명 확인",
          content: "사용자명을 확인해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //3. 핸드폰 번호
      if (value.hpNo === null || value.hpNo === undefined) value.hpNo = "";

      var hpNoBoo = /^[0-9]{8,20}$/;

      if (!hpNoBoo.test(value.hpNo.replace(/-/gi, ""))) {
        const popConfig = {
          title: "핸드폰번호 확인",
          content: "핸드폰 번호를 확인해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //4. 비밀번호
      if (
        this.form.changePw.password.value === null ||
        this.form.changePw.password.value === undefined
      )
        this.form.changePw.password.value = "";

      if (
        this.form.changePw.password.value.length < 8 ||
        this.form.changePw.password.value.length > 20
      ) {
        const popConfig = {
          title: "비밀번호 확인",
          content: "비밀번호는 8~20자 이내로 입력이 가능합니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      const subData = {
        subYn: value.subYn,
        nm: value.nm,
        hpNo: value.hpNo,
        passwd: this.form.changePw.password.value,
      };
      try {
        await this.SUB_SUBMIT(subData).then(() => {
          const popConfig = {
            title: "비밀번호 변경완료",
            content: "비밀번호가 정상적으로 변경되었습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
          this.SET_API_POPUP(false);
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          const popConfig = {
            title: "비밀번호 변경실패",
            content: " 비밀번호를 변경할 수 없습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
          this.SET_API_POPUP(false);
        }
      }
    },
    async confirmPwOwn() {
      //비밀번호 체크
      try {
        const payload = {
          passwd: this.form.changePw.password.value,
        };
        await this.CHECK_PASSWORD(payload).then(() => {
          if (this.GET_CHECK_RESULT) {
            this.reloadPop("ChangePasswordOwn");
          } else {
            const popConfig = {
              title: "비밀번호 확인실패",
              content: "비밀번호가 일치하지 않습니다.",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
          }
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          const popConfig = {
            title: "비밀번호 확인실패",
            content: "서버통신장애로 비밀번호 확인이 실패했습니다.",
            cancelBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      }
    },
    async confirmPw() {
      try {
        const payload = {
          passwd: this.form.changePw.password.value,
        };
        await this.CHECK_PASSWORD(payload).then(() => {
          if (this.GET_CHECK_RESULT) {
            this.reloadPop("ChangePassword");
          } else {
            const popConfig = {
              title: "비밀번호 확인실패",
              content: "비밀번호가 일치하지 않습니다.",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
          }
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          const popConfig = {
            title: "비밀번호 확인실패",
            content: "서버통신장애로 비밀번호 확인이 실패했습니다.",
            cancelBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      }
    },
    callJoinPage() {
      this.SET_API_POPUP(false);
      this.$router.push("/v/join/Agreement").catch(() => {});
    },
    /** 비밀번호 변경 */
    async changePwOwn() {
      //유효성 검사

      //1. 아이디
      if (this.GET_TRANS_ID === null || this.GET_TRANS_ID === undefined)
        this.GET_TRANS_ID = "";

      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

      if (!drIdBoo.test(this.GET_TRANS_ID)) {
        const popConfig = {
          title: "로그인 확인",
          content:
            "로그인이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //2. 비밀번호
      if (
        this.form.changePw.password.value === null ||
        this.form.changePw.password.value === undefined
      )
        this.form.changePw.password.value = "";

      if (
        this.form.changePw.password.value.length < 8 ||
        this.form.changePw.password.value.length > 20
      ) {
        const popConfig = {
          title: "비밀번호 확인",
          content: "비밀번호는 8~20자 이내로 입력이 가능합니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      try {
        const payload = {
          id: this.GET_TRANS_ID,
          passwd: this.form.changePw.password.value,
        };
        await this.CHANGE_PASSWORD_OWN(payload).then(() => {
          this.$emit("require-change-password-done");
          const popConfig = {
            title: "비밀번호 변경완료",
            content: "비밀번호가 성공적으로 변경되었습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
            defineUrl: "/",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
          this.SET_API_POPUP(false);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          const popConfig = {
            title: "비밀번호 변경실패",
            content: "서버통신장애로 비밀번호 변경이 실패했습니다.",
            cancelBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      }
    },
    async changePw() {
      //console.log("this.GET_TRANS_ID : " + this.GET_TRANS_ID);
      //console.log("this.form.changePw.password.value : " + this.form.changePw.password.value);

      // 유효성 검사

      //1. 아이디
      if (this.GET_TRANS_ID === null || this.GET_TRANS_ID === undefined)
        this.GET_TRANS_ID = "";

      var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

      if (!drIdBoo.test(this.GET_TRANS_ID)) {
        const popConfig = {
          title: "아이디 확인",
          content: "아이디가 정상적이지 않습니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      //2. 비밀번호
      if (
        this.form.changePw.password.value === null ||
        this.form.changePw.password.value === undefined
      )
        this.form.changePw.password.value = "";

      if (
        this.form.changePw.password.value.length < 8 ||
        this.form.changePw.password.value.length > 20
      ) {
        const popConfig = {
          title: "비밀번호 확인",
          content: "비밀번호는 8자 이상 20자 이내로 입력이 가능합니다.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
        return;
      }

      try {
        const payload = {
          id: this.GET_TRANS_ID,
          passwd: this.form.changePw.password.value,
        };
        await this.CHANGE_PASSWORD(payload).then(() => {
          const popConfig = {
            title: "비밀번호 변경완료",
            content: "비밀번호가 성공적으로 변경되었습니다.",
            cancelBtnText: undefined,
            popType: "custom",
            confirmBtnText: "확인",
            defineUrl: "/",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
          this.SET_API_POPUP(false);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          const popConfig = {
            title: "비밀번호 변경실패",
            content: "서버통신장애로 비밀번호 변경이 실패했습니다.",
            cancelBtnText: "확인",
          };
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      }
    },
    checkPw() {
      this.form.changePw.password.flag = false;
      const pw = this.form.changePw.password.value.replace(/\s/gi, "");
      if (pw === "") {
        this.form.changePw.password.flag = false;
        this.form.changePw.view.errorTxt = "*비밀번호를 입력해주세요.";
        return false;
      }
      const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
      if (!isPw.test(pw)) {
        this.form.changePw.view.errorTxt =
          "*영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.";
        this.form.changePw.password.flag = false;
        return false;
      }
      this.form.changePw.password.flag = true;
      this.form.changePw.view.errorTxt = null;
      return true;
    },
    checkPwConfirm() {
      this.form.changePw.passwordConfirm.flag = false;
      const pwConfirm = this.form.changePw.passwordConfirm.value.replace(
        /\s/gi,
        ""
      );
      if (pwConfirm === "") {
        this.form.changePw.passwordConfirm.flag = false;
        this.form.changePw.view.errorTxt = "*비밀번호 확인을 입력해주세요.";
        return false;
      }
      if (pwConfirm !== this.form.changePw.password.value) {
        this.form.changePw.passwordConfirm.flag = false;
        this.form.changePw.view.errorTxt = "*비밀번호가 일치하지 않습니다.";
        return false;
      }
      this.form.changePw.passwordConfirm.flag = true;
      this.form.changePw.view.errorTxt = "";
      return true;
    },
    /** === 비밀번호 찾기 === */
    async findPwSmsCall() {
      if (this.form.findPw.id.flag && this.form.findPw.phone.flag) {
        try {
          const payload = {
            drId: this.form.findPw.id.value,
            hpNo: this.form.findPw.phone.value,
          };
          await this.BRING_SMS_CODE(payload).then(() => {
            //console.log("가져온 정보", this.GET_SMS_LIST);
            //console.log("인증해야하는 번호", this.GET_SMS_LIST.authNum);
            //console.log("유효성 시간", this.GET_SMS_LIST.createDt);
            if (this.GET_SMS_LIST.authNum !== null) {
              this.calculateSecond();
              this.form.findPw.view.isSmsTrans = true;
              this.form.findPw.receiveSms.flag = true;
              const popConfig = {
                title: "인증번호 전송완료",
                content: "휴대폰으로 인증번호가 전송되었습니다.",
                cancelBtnText: "확인",
              };
              this.SET_POP_CONFIG(popConfig);
              this.$emit("call-parents-popup");
            } else {
              const popConfig = {
                title: "일치하는 정보가 없습니다.",
                content: "입력한 정보를 확인해주세요.",
                cancelBtnText: "확인",
              };
              this.SET_POP_CONFIG(popConfig);
              this.$emit("call-parents-popup");
            }
          });
        } catch (error) {
          console.log("아이디 찾기 sms 에러");
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            const popConfig = {
              title: "인증코드 발송실패",
              content: "인증코드를 발송하지 못했습니다.",
              cancelBtnText: "확인",
            };
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
          }
        }
      } else {
        /** 진입못함 */
        const popConfig = {
          title: "아이디 찾기 입력정보 확인",
          content: this.form.findPw.view.errorTxt,
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
      }
    },
    checkFindPwSms() {
      this.form.findPw.receiveSms.flag = false;
      const sms = this.form.findPw.receiveSms.value.replace(/\s/gi, "");
      if (sms === "") {
        this.form.findPw.receiveSms.flag = false;
        this.form.findPw.view.errorTxt = "*인증번호를 입력해주세요.";
        return false;
      }
      const isSms = /^[0-9]{6}$/;
      if (!isSms.test(sms)) {
        this.form.findPw.view.errorTxt = "*인증번호 형식이 잘못되었습니다.";
        this.form.findPw.receiveSms.flag = false;
        return false;
      }
      this.form.findPw.receiveSms.flag = true;
      this.form.findPw.view.errorTxt = null;
      return true;
    },
    checkFindPwId() {
      this.form.findPw.id.flag = false;
      const id = this.form.findPw.id.value.replace(/\s/gi, "");
      if (id === "") {
        this.form.findPw.id.flag = false;
        this.form.findPw.view.errorTxt = "*아이디를 입력해주세요.";
        return false;
      }
      const isHan = /^[0-9|a-z|A-Z]{5,20}$/;
      if (!isHan.test(id)) {
        //console.log("아이디 조건불일치");
        this.form.findPw.view.errorTxt = "*아이디의 형식이 잘못되었습니다.";
        this.form.findPw.id.flag = false;
        return false;
      }
      //console.log("아이디 조건일치");
      this.form.findPw.id.flag = true;
      if (!this.form.findPw.phone.flag) {
        this.form.findPw.view.errorTxt = "*전화번호를 확인해주세요.";
      } else {
        this.form.findPw.view.errorTxt = null;
      }
      return true;
    },
    checkFindPwPhone() {
      this.form.findPw.phone.flag = false;
      const phone = this.form.findPw.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.form.findPw.phone.flag = false;
        this.form.findPw.view.errorTxt = "*휴대폰번호를 입력해주세요.";
        return false;
      }
      const isPhone = /^[0-9|-]{10,13}$/;
      if (!isPhone.test(phone)) {
        this.form.findPw.view.errorTxt =
          "*휴대폰 번호의 형식이 잘못되었습니다.";
        this.form.findPw.phone.flag = false;
        return false;
      }
      this.form.findPw.phone.flag = true;
      if (!this.form.findPw.id.flag) {
        this.form.findPw.view.errorTxt = "*아이디를 확인해주세요.";
      } else {
        this.form.findPw.view.errorTxt = null;
      }
      return true;
    },
    findPwSubmit() {
      if (this.form.findPw.receiveSms.value === this.GET_SMS_LIST.authNum) {
        clearTimeout(this.calculateSecond());
        if (!this.isValidate) {
          this.SET_TRANS_ID(this.form.findPw.id.value);
          this.reloadPop("ChangePassword");
        } else {
          this.form.findPw.view.isSmsTrans = false;
          const popConfig = {
            title: "인증번호 유효시간이 지났습니다.",
            content: "인증번호를 다시 수신해주세요.",
            cancelBtnText: "확인",
          };
          this.form.findPw.receiveSms.value = "";
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      } else {
        const popConfig = {
          title: "인증번호가 일치하지 않습니다.",
          content: "인증번호를 다시 입력해주세요.",
          cancelBtnText: "확인",
        };
        this.form.findPw.receiveSms.value = "";
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
      }
    },

    /** ==== 아이디찾기 ==== */
    async findIdSmsCall() {
      if (this.form.findId.name.flag && this.form.findId.phone.flag) {
        try {
          const payload = {
            nm: this.form.findId.name.value,
            hpNo: this.form.findId.phone.value.replace(/-/gi, ""),
          };

          await this.BRING_SMS_CODE(payload).then(() => {
            //console.log("가져온 정보", this.GET_SMS_LIST);
            //console.log("인증해야하는 번호", this.GET_SMS_LIST.authNum);
            //console.log("유효성 시간", this.GET_SMS_LIST.createDt);
            if (this.GET_SMS_LIST.authNum !== null) {
              this.calculateSecond();
              this.form.findId.view.isSmsTrans = true;
              this.form.findId.receiveSms.flag = true;
              const popConfig = {
                title: "인증번호 전송완료",
                content: "휴대폰으로 인증번호가 전송되었습니다.",
                cancelBtnText: "확인",
              };
              this.SET_POP_CONFIG(popConfig);
              this.$emit("call-parents-popup");
            } else {
              const popConfig = {
                title: "일치하는 정보가 없습니다.",
                content: "입력한 정보를 확인해주세요.",
                cancelBtnText: "확인",
              };
              this.SET_POP_CONFIG(popConfig);
              this.$emit("call-parents-popup");
            }
          });
        } catch (error) {
          console.log("아이디 찾기 sms 에러");
          console.log("error :", error);
        }
      } else {
        /** 진입못함 */
        const popConfig = {
          title: "아이디 찾기 입력정보 확인",
          content: this.form.findId.view.errorTxt,
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
      }
    },
    checkFindIdSms() {
      this.form.findId.receiveSms.flag = false;
      const sms = this.form.findId.receiveSms.value.replace(/\s/gi, "");
      if (sms === "") {
        this.form.findId.receiveSms.flag = false;
        this.form.findId.view.errorTxt = "*인증번호를 입력해주세요.";
        return false;
      }
      const isSms = /^[0-9]{6}$/;
      if (!isSms.test(sms)) {
        this.form.findId.view.errorTxt = "*인증번호 형식이 잘못되었습니다.";
        this.form.findId.receiveSms.flag = false;
        return false;
      }
      this.form.findId.receiveSms.flag = true;
      this.form.findId.view.errorTxt = null;
      return true;
    },
    checkFindIdName() {
      this.form.findId.name.flag = false;
      const name = this.form.findId.name.value.replace(/\s/gi, "");
      if (name === "") {
        this.form.findId.name.flag = false;
        this.form.findId.view.errorTxt = "*이름을 입력해주세요.";
        return false;
      }
      const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z]{2,21}$/;
      if (!isHan.test(name)) {
        this.form.findId.view.errorTxt = "*이름의 형식이 잘못되었습니다.";
        this.form.findId.name.flag = false;
        return false;
      }
      this.form.findId.name.flag = true;
      if (!this.form.findId.phone.flag) {
        this.form.findId.view.errorTxt = "*전화번호를 확인해주세요.";
      } else {
        this.form.findId.view.errorTxt = null;
      }
      return true;
    },
    checkFindIdPhone() {
      this.form.findId.phone.flag = false;
      const phone = this.form.findId.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.form.findId.phone.flag = false;
        this.form.findId.view.errorTxt = "*휴대폰번호를 입력해주세요.";
        return false;
      }
      const isPhone = /^[0-9|-]{10,13}$/;
      if (!isPhone.test(phone)) {
        this.form.findId.view.errorTxt =
          "*휴대폰 번호의 형식이 잘못되었습니다.";
        this.form.findId.phone.flag = false;
        return false;
      }
      this.form.findId.phone.flag = true;
      if (!this.form.findId.name.flag) {
        this.form.findId.view.errorTxt = "*이름을 확인해주세요.";
      } else {
        this.form.findId.view.errorTxt = null;
      }
      return true;
    },
    async findIdSubmit() {
      if (this.form.findId.receiveSms.value === this.GET_SMS_LIST.authNum) {
        clearTimeout(this.calculateSecond());
        if (!this.isValidate) {
          try {
            const payload = {
              nm: this.form.findId.name.value,
              hpNo: this.form.findId.phone.value.replace(/-/gi, ""),
            };

            await this.BRING_SEARCHED_ID(payload).then(() => {
              //console.log("가져온 정보", this.GET_SEARCHED_AUTH_DATA);
              let StringValue = "";
              for (let i = 0; i < this.GET_SEARCHED_AUTH_DATA.length; i++) {
                if (i === this.GET_SEARCHED_AUTH_DATA.length - 1) {
                  StringValue += this.GET_SEARCHED_AUTH_DATA[i];
                } else {
                  StringValue += this.GET_SEARCHED_AUTH_DATA[i] + " ,";
                }
              }
              const popConfig = {
                popType: "custom",
                title: "아이디 찾기 완료",
                content:
                  "입력한 정보로 가입한 아이디는\n" + StringValue + " 입니다.",
                confirmBtnText: "확인",
                action: "Login",
                cancelBtnText: undefined,
              };
              this.SET_POP_CONFIG(popConfig);
              this.$emit("call-parents-popup");
              this.SET_API_POPUP(false);
            });
          } catch (error) {
            console.log("err", error);
            if (error.data.status !== 5000 && error.data.status !== 5002) {
              const popConfig = {
                title: "아이디 찾기 실패",
                content: "서버통신장애로 아이디를 찾지 못했습니다.",
                cancelBtnText: "확인",
              };
              this.SET_POP_CONFIG(popConfig);
              this.$emit("call-parents-popup");
            }
          }
        } else {
          this.form.findId.view.isSmsTrans = false;
          const popConfig = {
            title: "인증번호 유효시간이 지났습니다.",
            content: "인증번호를 다시 수신해주세요.",
            cancelBtnText: "확인",
          };
          this.form.findId.receiveSms.value = "";
          this.SET_POP_CONFIG(popConfig);
          this.$emit("call-parents-popup");
        }
      } else {
        const popConfig = {
          title: "인증번호가 일치하지 않습니다.",
          content: "인증번호를 다시 입력해주세요.",
          cancelBtnText: "확인",
        };
        this.form.findId.receiveSms.value = "";
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
      }
    },
    /** ==== 로그인 API ==== */
    async login() {
      if (this.form.login.id.flag && this.form.login.password.flag) {
        if (this.form.login.view.isIdStored) {
          localStorage.setItem("storedId", this.form.login.id.value);
        } else {
          if (localStorage.getItem("storedId") !== null) {
            localStorage.removeItem("storedId");
          }
        }
        const formData = new FormData();
        formData.append("username", this.form.login.id.value);
        formData.append("password", this.form.login.password.value);
        try {
          let popConfig;
          await this.LOGIN(formData).then((data) => {
            //console.log("Login-data: ", data);
            localStorage.setItem("drId", data.drId);
            localStorage.setItem("nm", data.nm);
            localStorage.setItem("token", data.token);
            localStorage.setItem("memType", data.memType);
            localStorage.setItem("pwActiveYn", data.pwActiveYn);
            localStorage.setItem("pwElapYn", data.pwElapYn);
            localStorage.setItem("suspandYn", data.suspandYn);
            localStorage.setItem("popYn", data.popYn);
            if (
              data.suspandYn === "Y" ||
              data.pwActiveYn === "N" ||
              data.pwElapYn === "Y"
            ) {
              if (data.suspandYn === "Y") {
                //console.log("휴면계정처리가 필요함");
                this.$router.push("/v/Recovery").catch(() => {});
              } else if (data.pwActiveYn === "N") {
                //console.log("비밀번호 오류횟수 초과로 강제변경");
                this.$router.push("/v/requireChangePw").catch(() => {});
              } else if (data.pwElapYn === "Y") {
                this.$router.push("/v/restrictPw").catch(() => {});
                //console.log("비밀번호를 사용한지 3개월이 지남");
              }
            } else {
              if (this.defineUrl !== undefined) {
                popConfig = {
                  popType: "custom",
                  title: "로그인 완료",
                  content: "로그인이 완료되었습니다.",
                  confirmBtnText: "확인",
                  cancelBtnText: undefined,
                  authCause: true,
                  defineUrl: this.defineUrl,
                };
              } else {
                popConfig = {
                  popType: "custom",
                  title: "로그인 완료",
                  content: "로그인이 완료되었습니다.",
                  confirmBtnText: "확인",
                  cancelBtnText: undefined,
                  authCause: true,
                };
              }

              this.SET_POP_CONFIG(popConfig);
              this.$emit("call-parents-popup");
            }
            /** 
            if () {

            } else {

            }
             */

            this.$emit("is-login");
            this.SET_API_POPUP(false);
          });
        } catch (error) {
          console.log("error 로그인:", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            const popConfig = {
              title: "로그인 입력정보 확인",
              content: "아이디 또는 비밀번호가 잘못되었습니다.",
              cancelBtnText: "확인",
            };
            this.form.login.value = "";
            this.form.login.password.value = "";
            this.form.login.flag = false;
            this.form.login.password.flag = false;
            this.form.login.view.isIdStored = false;
            this.SET_POP_CONFIG(popConfig);
            this.$emit("call-parents-popup");
          }
        }
      } else {
        /** 빠질 일 없고 테스트임 */
        const popConfig = {
          title: "로그인 입력정보 확인",
          content: this.form.login.view.errorTxt,
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
      }
    },
    goRegiste() {
      this.SET_API_POPUP(false);
      this.$router.push("/v/join/agreement").catch(() => {});
    },
    checkLoginId() {
      this.form.login.id.flag = false;
      const id = this.form.login.id.value.replace(/\s/gi, "");
      if (id === "") {
        this.form.login.id.flag = false;
        this.form.login.view.errorTxt = "*아이디를 입력해주세요.";
        return false;
      }
      const isId = /^[0-9|a-z|A-Z|!]{5,20}$/; //부계정을 위해 틀수문자중 '!'는 정규식배제 x
      if (!isId.test(id)) {
        this.form.login.view.errorTxt = "*아이디 형식이 일치하지 않습니다.";
        this.form.login.id.flag = false;
        return false;
      }
      this.form.login.id.flag = true;
      if (!this.form.login.password.flag) {
        this.form.login.view.errorTxt = "*비밀번호를 확인해주세요.";
      } else {
        this.form.login.view.errorTxt = null;
      }
      return true;
    },
    checkLoginPw() {
      this.form.login.password.flag = false;
      const password = this.form.login.password.value.replace(/\s/gi, "");
      if (password === "") {
        this.form.login.password.flag = false;
        this.form.login.view.errorTxt = "*비밀번호를 입력해주세요.";
        return false;
      }
      /** 
      const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
      if (!isPw.test(password)) {
        this.form.login.view.errorTxt = "*비밀번호 형식이 일치하지 않습니다.";
        this.form.login.password.flag = false;
        return false;
      }
      */
      this.form.login.password.flag = true;
      if (!this.form.login.id.flag) {
        this.form.login.view.errorTxt = "*아이디를 확인해주세요.";
      } else {
        this.form.login.view.errorTxt = null;
      }
      return true;
    },

    /** ==== 의사면허번호 API ==== */
    async checkDrPopup() {
      this.SET_CHILD_LOADER(true);
      try {
        await this.CHECK_DOCTOR_LICENSE(
          this.doctorLicenseReduplication.doctorLicense.value
        ).then(() => {
          //console.log("서버 통신결과", this.GET_IS_DUPLICATED_DRLICENSE);
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
          }, 800);
          if (!this.GET_IS_DUPLICATED_DRLICENSE) {
            this.doctorLicenseReduplication.isChecked = true;
          } else {
            this.doctorLicenseReduplication.isChecked = false;
            this.doctorLicenseReduplication.doctorLicense.flag = false;
            this.doctorLicenseReduplication.doctorLicense.hasError = true;
            this.doctorLicenseReduplication.doctorLicense.errorTxt =
              "이미 가입된 의사면허번호입니다.";
            this.doctorLicenseReduplication.doctorLicense.value = "";
          }
        });
      } catch (error) {
        console.log("의사면허번호 중복검사에서 Error");
        console.log("error :", error);
        this.doctorLicenseReduplication.isChecked = false;
        this.doctorLicenseReduplication.doctorLicense.flag = false;
        this.doctorLicenseReduplication.doctorLicense.hasError = true;
        this.doctorLicenseReduplication.doctorLicense.errorTxt =
          "서버 통신에 문제가 있습니다.";
        this.doctorLicenseReduplication.doctorLicense.value = "";
      }
    },
    callLicense() {
      this.popType = "DrLicense";
      this.SET_API_POPUP(true);
      this.title = "의사면허번호 확인";
      this.content =
        "이 서비스는 의사 회원만을 위한 서비스입니다.\n중복 가입을 피하기 위해 의사면호번호를 확인합니다.";
      this.placeholder = "의사면허번호 입력";
      this.warnMessage =
        "신규 가입한 회원의 의사면허번호는 가입 후 검증합니다.\n" +
        "의사면허번호 및 개인정보가 일치하지 않으면\n" +
        "서비스 사용이 중단됩니다.";
      this.apiBtnText = "중복확인";
      this.confirmBtnText = "회원가입";
      this.nextLink = "/v/join/member";
    },
    checkDoctorLicense() {
      this.doctorLicenseReduplication.doctorLicense.flag = false;
      this.doctorLicenseReduplication.doctorLicense.hasError = true;
      const license =
        this.doctorLicenseReduplication.doctorLicense.value.replace(/\s/gi, "");
      if (license === "") {
        this.doctorLicenseReduplication.doctorLicense.flag = false;
        this.doctorLicenseReduplication.doctorLicense.hasError = true;
        this.doctorLicenseReduplication.doctorLicense.errorTxt =
          "*의사면허번호를 입력해주세요.";
        return false;
      }
      const isNum = /^[0-9]{5}$/;
      if (!isNum.test(license)) {
        this.doctorLicenseReduplication.doctorLicense.errorTxt =
          "*5자리의 숫자를 입력해주세요.";
        this.doctorLicenseReduplication.doctorLicense.flag = false;
        this.doctorLicenseReduplication.doctorLicense.hasError = true;
        return false;
      }
      this.doctorLicenseReduplication.doctorLicense.flag = true;
      this.doctorLicenseReduplication.doctorLicense.hasError = false;
      this.doctorLicenseReduplication.doctorLicense.errorTxt = "";
      return true;
    },
    drLicenseDone() {
      if (this.doctorLicenseReduplication.isChecked) {
        this.SET_API_POPUP(false);
        this.$router.push(this.nextLink).catch(() => {});
        /*
      const agreement = {
        doctorLicenseFlag : this.doctorLicenseReduplication.isChecked,
        isAgreementFlag : true
      }
      */
      } else {
        const popConfig = {
          title: "의사면허번호 중복확인",
          content: "의사면허번호 중복을 확인하고 진행해주세요.",
          cancelBtnText: "확인",
        };
        this.SET_POP_CONFIG(popConfig);
        this.$emit("call-parents-popup");
      }
    },
    /** ==== 의사면허번호 API 끝 ==== */

    /** 유틸 */
    preparedProperty() {
      if (this.popType === "Login") {
        this.bringStoredId();
      }
    },
    reloadPop(type) {
      let pop;
      //console.log("리로드팝 건네받은 type: ", type);
      if (type === "FindId") {
        pop = {
          popType: type,
          title: "아이디 찾기",
          content: "핸드폰으로 전송된 인증번호를 입력해주세요.",
        };
      } else if (type === "FindPw") {
        pop = {
          popType: type,
          title: "비밀번호 찾기",
          content: "핸드폰으로 전송된 인증번호를 입력해주세요.",
        };
      } else if (type === "ChangePassword") {
        pop = {
          popType: type,
          title: "비밀번호 변경",
          content: "변경할 비밀번호와 비밀번호 확인을 입력해주세요.",
        };
      } else if (type === "CheckPassword") {
        pop = {
          popType: type,
          title: "비밀번호 확인",
          content: "현재 비밀번호를 입력해주세요.",
        };
      } else if (type === "ChangePasswordOwn") {
        pop = {
          popType: type,
          title: "비밀번호 변경",
          content: "변경할 비밀번호와 비밀번호 확인을 입력해주세요.",
        };
      }
      this.$emit("reload-api-pop", pop);
      this.SET_API_POPUP(false);
    },
    bringStoredId() {
      const getStoredId = localStorage.getItem("storedId");
      if (getStoredId !== null) {
        this.form.login.id.value = getStoredId;
        this.form.login.id.flag = true;
        this.form.login.view.errorTxt = "*비밀번호를 확인해주세요.";
      }
    },
    calculateSecond() {
      setTimeout(() => {
        //console.log("셋타임아웃 실행됨");
        this.isValidate = true;
      }, 180000);
    },
    btnCloseRefund() {
      //console.log("클로즈감지");
      //console.log("fix", this.isFixing);
      if (!this.isFixing) {
        this.SET_API_POPUP(false);
        this.SET_CHILD_LOADER(false);
      } else {
        this.SET_API_POPUP(false);
        this.$router.push("/").catch(() => {});
      }
    },
    btnClose() {
      //console.log("클로즈감지");
      //console.log("fix", this.isFixing);
      if (!this.isFixing) {
        this.SET_API_POPUP(false);
      } else {
        this.SET_API_POPUP(false);
        this.$router.push("/").catch(() => {});
      }
    },
    samplez() {},
    checkStoredId() {
      if (
        this.popType === "Login" &&
        localStorage.getItem("storedId") !== null
      ) {
        this.form.login.view.isIdStored = true;
      }
    },
  },
  created() {
    //console.log("데이터파람2", this.dataParam);
    //console.log("fixing", this.isFixing);
    this.checkStoredId();
    this.preparedProperty();
  },
  beforeDestroy() {
    if (this.locationUrl !== null) {
      this.$router.push(this.locationUrl).catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/ApiPopup.scss";

input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
