import { referList } from "@/api";
const state = {
  list: {
    searchList: [],
    sendList: [],
    receiveList: [],
    referDetail: [],
    reqDoctorList:[],
    resDoctorList:[]
  },
  view: {
    isCheckedAgreement: false,
    smsList: [],
    agreeResult: [],
    listOrder: null,
    referId: null
  }
};

const getters = {
  GET_SEARCH_LIST: state => state.list.searchList,
  GET_SEND_LIST: state => state.list.sendList,
  GET_RECEIVE_LIST: state => state.list.receiveList,
  GET_REFER_DETAIL: state => state.list.referDetail,
  GET_LIST_ORDER: state => state.view.listOrder,
  GET_REFER_ID: state => state.view.referId,
  GET_RES_DOCTOR_DATA: state => state.list.resDoctorList,
  GET_REQ_DOCTOR_DATA: state => state.list.reqDoctorList,
};

const mutations = {
  SET_SEARCH_LIST(state, searchedData) {
    state.list.searchList = searchedData;
  },
  SET_SEND_LIST(state, listData) {
    state.list.sendList = listData;
  },
  SET_RECEIVE_LIST(state, listData) {
    state.list.receiveList = listData;
  },
  SET_REFER_DETAIL(state, detailInfo) {
    state.list.referDetail = detailInfo;
  },
  SET_REQ_DOCTOR_DATA(state, reqDoctor) {
    state.list.reqDoctorList = reqDoctor;
  },
  SET_RES_DOCTOR_DATA(state, resDoctor) {
    state.list.resDoctorList = resDoctor;
  },
  SET_LIST_ORDER(state, order) {
    state.view.listOrder = order;
  },
  SET_REFER_ID(state, referId) {
    state.view.referId = referId;
  }
};

const actions = {
  /** 병원검색 */
BRING_SEARCH_LIST({ commit }, payload) {
    //console.log(payload);
    return referList.searchList(payload).then(data => {
      commit("SET_SEARCH_LIST", data);
    });
  },
  BRING_SEND_LIST({ commit }, payload) {
    return referList.sendList(payload).then(data => {
      commit("SET_SEND_LIST", data);
    });
  },
  RECEIVE_SEND_LIST({ commit }, payload) {
    return referList.receiveList(payload).then(data => {
      commit("SET_RECEIVE_LIST", data);
    });
  },
  RECEIVE_SEND_LIST_ALL({ commit }, payload) {
    return referList.receiveListAll(payload).then(data => {
      commit("SET_RECEIVE_LIST", data);
    });
  },
  REFER_DETAIL({ commit }, payload) {
    return referList.referDetail(payload).then(data => {
      commit("SET_REFER_DETAIL", data);
    });
  },
  REQ_DOCTOR_LIST({ commit }, payload) {
    return referList.reqDoctor(payload).then(data => {
      commit("SET_REQ_DOCTOR_DATA", data);
    });
  },
  RES_DOCTOR_LIST({ commit }, payload) {
    return referList.resDoctor(payload).then(data => {
      commit("SET_RES_DOCTOR_DATA", data);
    });
  },
  SAVE_MEMO({}, payload){
    return referList.saveMemo(payload);
  },
  SAVE_INBOX({}, payload){
    return referList.saveInBox(payload);
  },
  INBOX_RESTORE({}, payload){
    return referList.inBoxRestore(payload);
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
