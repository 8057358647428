<template>
  <transition name="modal">
    <div
      class="modal-mask"
      @click="overlayClick($event)"
      :class="{ isTopPriority: priority }"
    >
      <div class="modal-wrapper" @click.stop>
        <div class="modal-container search">
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    footerLess: {
      type: Boolean,
      default: false,
    },
    priority: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("basic", ["checkSearchPopup"]),
    ...mapState("basic", ["checkSearchPopupReform"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_SEARCH_POPUP"]),
    ...mapMutations("basic", ["SET_SEARCH_POPUP_REFORM"]),
    overlayClick(event) {
      const target = event.target;
      //console.log("타겟", target);
      //console.log("커런트", event.currentTarget);

      this.SET_SEARCH_POPUP(false);
      this.SET_SEARCH_POPUP_REFORM(false);
    },
    closePop() {
      this.SET_SEARCH_POPUP(false);
      this.SET_SEARCH_POPUP_REFORM(false);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
