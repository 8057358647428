import { prod } from "@/api";
import { list } from "postcss";

const state = {
  prodList: [],
  orderList: [],
  couponList: [],
  applyYn: {},
  inProgress: "",
  orderYn: "",
  count: "",
};

const getters = {
  //get
  GET_PROD_LIST: (state) => state.prodList,
  GET_APPLY_YN: (state) => state.applyYn,
  GET_ORDER_LIST: (state) => state.orderList,
  GET_ORDER_YN: (state) => state.orderYn,
  GET_COUPON_LIST: (state) => state.couponList,
  GET_COUNT: (state) => state.count,
};

const mutations = {
  //set
  SET_COUPON_LIST(state, listData) {
    state.couponList = listData;
  },
  SET_PROD_LIST(state, listData) {
    state.prodList = listData;
  },
  SET_APPLY_YN(state, applyYn) {
    state.applyYn = applyYn;
  },
  SET_ORDER_LIST(state, listData) {
    state.orderList = listData;
  },
  SET_ORDER_YN(state, orderYn) {
    state.orderYn = orderYn;
  },
  SET_COUNT(state, count) {
    state.count = count;
  },
};

const actions = {
  //act
  BRING_ORDER_LIST({ commit }, payload) {
    return prod.getOrderListForDesigner(payload).then((data) => {
      commit("SET_ORDER_LIST", data);
    });
  },
  BRING_INPROGRESS_APPLY({}, payload) {
    return prod.getInProgressApply(payload).then((data) => {
      return data;
    });
  },
  BRING_PROD_LIST({ commit }, payload) {
    return prod.getProdList(payload).then((data) => {
      commit("SET_PROD_LIST", data);
    });
  },
  ORDER_SAVE({}, payload) {
    return prod.orderSave(payload);
  },
  ORDER_COMPLETE({}, payload) {
    return prod.orderComplete(payload);
  },
  ORDER_COMPLETE_WITH_NO_COST({}, payload) {
    return prod.orderCompleteWithNoCost(payload);
  },
  ORDER_PROGRESS_FAIL({}, payload) {
    return prod.oderProgressFail(payload);
  },
  SAVE_APPLY({}, payload) {
    /*for (var pair of payload.entries()) {
      console.log(pair[0]);
      console.log("/");
      console.log(pair[1]);
    }*/
    return prod.saveApply(payload).then((data) => {
      return data;
    });
  },
  BRING_CHECK_ORDER({ commit }, payload) {
    return prod.bringCheckOrder(payload).then((data) => {
      commit("SET_ORDER_YN", data);
    });
  },
  BRING_CHECK_APPLY({ commit }, payload) {
    return prod.bringCheckApply(payload).then((data) => {
      commit("SET_APPLY_YN", data);
    });
  },
  ADD_PROD({}, payload) {
    return prod.addProd(payload);
  },
  PROD_DELETE({}, payload) {
    return prod.deleteProd(payload);
  },
  MODIFY_PROD({}, payload) {
    return prod.modifyProd(payload);
  },
  BRING_COUPON_LIST({ commit }, payload) {
    return prod.bringCouponList(payload).then((data) => {
      commit("SET_COUPON_LIST", data);
    });
  },
  BRING_USER_COUPON_USABLE_LIST({ commit }, payload) {
    return prod.bringUserCouponUsableList(payload).then((data) => {
      commit("SET_COUPON_LIST", data);
    });
  },
  ADD_USER_COUPON({ commit }, payload) {
    return prod.addUserCoupon(payload);
  },
  ADD_USER_COUPON({ commit }, payload) {
    return prod.addUserCoupon(payload);
  },
  BRING_USER_COUPON_COUNT({ commit }, payload) {
    return prod.selectUserCouponCount(payload).then((data) => {
      commit("SET_COUNT", data);
    });
  },
};

export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
