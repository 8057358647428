<template>
  <v-container class="app-footer" ma-0 pa-0>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="4" class="app-footer-logo">
            <div class="f-logo" @click="goHome('/')">
              <img src="@/assets/images/footer-logo.svg" />
            </div>
        </v-col>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12">
              <ul class="menu-list">
                <li>
                  <router-link
                    :to="{ name: 'company', params: { cur: '회사소개' } }"
                    >회사소개</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'NoticeList', params: { cur: '공지사항' } }"
                    >공지사항</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ name: 'helpdesk', params: { cur: '문의사항' } }"
                    >문의사항</router-link
                  >
                </li>
                <li @click="callAgreement(0)">
                  <router-link to="">이용약관</router-link>
                </li>
                <li @click="callAgreement(1)">
                  <router-link to="">개인정보처리방침</router-link>
                </li>
              </ul>
            </v-col>
            <v-col cols="12">
              <div class="app-footer-address">
                <v-row class="app-footer-address-row">
                  <v-col class="app-footer-address-text" cols="12">
                    <div>서울특별시 강남구 테헤란로64길 13,</div>
                    <div>
                      204호(대치동, 선릉역 풍림아이원레몬) 헬스앤모어(주)
                    </div>
                  </v-col>
                  <v-col class="app-footer-address-text" cols="12">
                    <div>대표자: 임양선</div>
                    <div class="app-footer-address-text-inner2">
                      사업자등록번호: 368-81-01864
                    </div>
                  </v-col>
                  <v-col class="app-footer-address-text" cols="12">
                    <div>통신판매업신고번호: 제 2021-서울강남-03461호</div>
                    <div class="app-footer-address-text-inner2">
                      대표번호: 02-555-1175
                    </div>
                  </v-col>
                  <v-col class="app-footer-address-text" cols="12">
                    <div class="app-footer-address-text-copyright">
                      Copyright Health & More Co., Ltd.
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <agreement-popup
      v-if="checkAgreementPopup"
      :popupSetAgree="popupSetAgree"
    />
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AgreementPopup from "@/components/modal/AgreementPopupFooter";
export default {
  props: {},
  data() {
    return {
      popupSetAgree: {},
    };
  },
  mounted() {},
  computed: {
    ...mapState("basic", {
      checkAgreementPopup: (state) => state.footer.checkAgreementPopup,
    }),
  },
  methods: {
    ...mapMutations("basic", ["SET_AGREEMENT_POPUP_FOOTER"]),
    callAgreement(order) {
      this.SET_AGREEMENT_POPUP_FOOTER(true);
      this.popupSetAgree.agreeCheckNum = order;
    },
    goHome(url) {
      const href = window.location.href;
      if (href.indexOf("/entered") > 0) {
        this.$router.push(url).catch(() => {});
        window.scrollTo(0,0);
        return
      } else if (href.indexOf("/ent") > 0) {
        // console.log(href);
        this.$router.push("/ent").catch(() => {});
        window.scrollTo(0,0);
        return;
      }
      this.$router.push(url).catch(() => {});
      window.scrollTo(0,0);
    },
    prePare() {
      alert("준비중입니다.");
    },
  },

  components: {
    AgreementPopup,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/AppFooter.scss";
</style>
