import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
//나중에 완성되면 아래 before 풀기
const routes = [
  /*{
    path: '/',
    name: 'Home',
    component: Home
  },*/
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/MainPage.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/appointment/:code",
    name: "appointment",
    component: () => import("@/views/appointment/Appointment.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/mypage",
    component: () => import("@/views/Temp.vue"),
    props: true,
    children: [
      {
        path: "request/0",
        name: "request_0",
        component: () => import("@/components/request/tab_0/Req_0.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "request/1",
        name: "request_1",
        component: () => import("@/components/request/tab_0/Req_1.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "request/2",
        name: "request_2",
        component: () => import("@/components/request/tab_0/Req_2.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "request/3",
        name: "request_3",
        component: () => import("@/components/request/tab_0/Req_3.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "request/4",
        name: "request_4",
        component: () => import("@/components/request/tab_0/Req_4.vue"),
        meta: { unauthorized: false },
      },

      {
        path: "inboxnoteList",
        name: "inboxnoteList",
        component: () => import("@/components/request/tab_1/InboxNoteList.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "sentnoteList",
        name: "sentnoteList",
        component: () => import("@/components/request/tab_1/SentNoteList.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "noteDetail",
        name: "noteDetail",
        component: () => import("@/components/request/tab_1/NoteDetail.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "modifymember",
        name: "modifymember",
        component: () => import("@/components/request/tab_2/ModifyMember.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "modifysearch",
        name: "modifysearch",
        component: () => import("@/components/request/tab_2/ModifySearch.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "prmanage",
        name: "prmanage",
        component: () => import("@/components/request/tab_2/PrManage.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "changePw",
        name: "changePw2",
        component: () => import("@/components/request/tab_2/ChangePw.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "sub",
        name: "modifySub",
        component: () => import("@/components/request/tab_2/Sub.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "draw",
        name: "withdraw",
        component: () => import("@/components/request/tab_2/Draw.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "appointmentList",
        name: "appointmentList",
        component: () =>
          import("@/components/request/tab_3/AppointmentListReq.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "appointmentConfig",
        name: "appointmentConfig",
        component: () =>
          import("@/components/request/tab_3/AppointmentConfigReq.vue"),
        meta: { unauthorized: false },
      },
    ],
  },
  {
    path: "/ent/",
    alias: ["/ent"],
    name: "ententMain",
    component: () => import("@/views/ent/EntEntMain.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/entlist/",
    alias: ["/entlist"],
    name: "ententList",
    component: () => import("@/views/ent/EntEntList.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/join/done",
    name: "Done",
    component: () => import("@/views/auth/join/Done.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/join/close",
    name: "popClose",
    component: () => import("@/views/auth/join/PopClose.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/join/enteredDone",
    name: "EnteredDone",
    component: () => import("@/views/auth/join/EnteredDone.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/join/finish",
    name: "Finish",
    component: () => import("@/views/auth/join/Finish.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/join/agreement",
    name: "j-Agreement",
    component: () => import("@/views/auth/join/Agreement.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/join/member",
    name: "JoinMember",
    component: () => import("@/views/auth/join/Member.vue"),
    meta: { unauthorized: true },
  },
  //구 검색정보관리
  // {
  //   path: "/v/join/search",
  //   name: "Search",
  //   component: () => import("@/views/auth/join/Search.vue"),
  // },
  {
    path: "/v/join/search2",
    name: "Search2",
    component: () => import("@/views/auth/join/Search_noneSide.vue"),
  },
  //구 소개정보 입력
  // {
  //   path: "/v/join/promotion",
  //   name: "Promotion",
  //   component: () => import("@/views/auth/join/Promotion.vue"),
  //   meta: { unauthorized: true },
  // },
  {
    path: "/v/request/agreement",
    name: "r-Agreement",
    component: () => import("@/views/request/Agreement.vue"),
  },
  {
    path: "/v/req/agree/:code",
    name: "v-Agreement",
    component: () => import("@/views/request/AgreementMobile.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/feedback/:code",
    name: "v-Feedback",
    component: () => import("@/views/request/Feedback.vue"),
    meta: { unauthorized: true },
  },
  // {
  //   path: "/v/req/agree1/:code",
  //   name: "v-Agreement",
  //   component: () => import("@/views/request/AgreementMobileOld.vue"),
  //   meta: { unauthorized: true },
  // },
  {
    path: "/v/req/agreeDone",
    name: "v-AgreeDone",
    component: () => import("@/views/request/AgreeDone.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/req/feedback/:code",
    name: "v-FeedbackMob",
    component: () => import("@/views/request/FeedbackMobile.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/req/refer/:code",
    name: "v-Qr",
    component: () => import("@/views/request/QR.vue"),
    meta: { unauthorized: true },
  },
  //구 수신환자 리스트(InBox)
  // {
  //   path: "/v/request/list",
  //   name: "r-List",
  //   component: () => import("@/views/request/List.vue"),
  // },
  //구 전체 수신환자 리스트
  // {
  //   path: "/v/request/list_all",
  //   name: "r-List-All",
  //   component: () => import("@/views/request/List_All.vue"),
  // },
  // {
  //   path: "/v/request/listCopy",
  //   name: "r-List-copy",
  //   component: () => import("@/views/request/List_copy.vue"),
  //   meta: { unauthorized: true },
  // },
  {
    path: "/v/request/requestDocument",
    name: "RequestDocument",
    component: () => import("@/views/request/RequestDocument.vue"),
  },
  {
    path: "/v/request/requestView",
    name: "RequestView",
    component: () => import("@/views/request/RequestView.vue"),
  },
  //구 모바일 진료의뢰서
  {
    path: "/v/request/requestForm",
    name: "RequestForm",
    component: () => import("@/views/request/RequestForm.vue"),
  },
  {
    path: "/v/request/requestFormReply",
    name: "requestFormReply",
    component: () => import("@/views/request/RequestFormReply.vue"),
  },
  {
    path: "/v/request/request",
    name: "RequestFormRequest",
    component: () => import("@/views/request/Request.vue"),
  },
  //구 모바일 진료의뢰서
  {
    path: "/v/request/requestAfter",
    name: "RequestFormAfter",
    component: () => import("@/views/request/RequestAfter.vue"),
  },
  {
    path: "/v/request/research",
    name: "Research",
    component: () => import("@/views/request/Research.vue"),
  },
  {
    path: "/v/request/researchHospital",
    name: "ResearchHospital",
    component: () => import("@/views/request/ResearchHospital.vue"),
  },
  {
    path: "/v/adminMain/:code",
    name: "AdminMain",
    component: () => import("@/views/admin/AdminMain.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/adminMain/prodDtl/:code",
    name: "adminProdDtl",
    component: () => import("@/views/admin/AdminManageProdDtl.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/adminMain/prodModify/:code",
    name: "adminProdModify",
    component: () => import("@/views/admin/AdminManageProdModify.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/adminMain/couponDtl/:code",
    name: "adminCouponDtl",
    component: () => import("@/views/admin/AdminCouponDtl.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/adminMain/userCouponDtl/:code",
    name: "adminCouponUserDtl",
    component: () => import("@/views/admin/AdminCouponUserDtl.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/adminMain/couponMailSend/:code",
    name: "adminCouponMailSend",
    component: () => import("@/views/admin/AdminCouponMailSend.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/adminMain/symptomDtl/:code",
    name: "adminSymptomDtl",
    component: () => import("@/views/admin/AdminSymptomDtl.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/designer/main/:code",
    name: "designerMain",
    component: () => import("@/views/designerView/DesignMain.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/designer/orderDtl/:code",
    name: "DesignOrderDtl",
    component: () => import("@/views/designerView/DesignOrderDtl.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/payment",
    component: () => import("@/views/Payment.vue"),
    props: true,
    children: [
      {
        path: "registCoupon",
        name: "registCoupon",
        component: () => import("@/components/payment/RegistCoupon.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "designList",
        name: "DesignList",
        component: () => import("@/components/payment/DesignList.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "designDetail/:code",
        name: "DesignDetail",
        component: () => import("@/components/payment/DesignDetail.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "refund",
        name: "refund",
        component: () => import("@/components/payment/DesignRefund.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "apply",
        name: "apply",
        component: () => import("@/components/payment/DesignApply.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "manageApply",
        name: "manageApply",
        component: () => import("@/components/payment/ApplyManage.vue"),
        meta: { unauthorized: false },
      },
      // Ori
      {
        path: "registCoupon_O",
        name: "registCoupon_O",
        component: () => import("@/components/payment/Origin/RegistCoupon.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "designList_O",
        name: "DesignList_O",
        component: () => import("@/components/payment/Origin/DesignList.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "designDetail_O/:code",
        name: "DesignDetail_O",
        component: () => import("@/components/payment/Origin/DesignDetail.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "refund_O",
        name: "refund_O",
        component: () => import("@/components/payment/Origin/DesignRefund.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "apply_O",
        name: "apply_O",
        component: () => import("@/components/payment/Origin/DesignApply.vue"),
        meta: { unauthorized: false },
      },
      {
        path: "manageApply_O",
        name: "manageApply_O",
        component: () => import("@/components/payment/Origin/ApplyManage.vue"),
        meta: { unauthorized: false },
      },
    ],
  },
  // {
  //   path: "/ent/",
  //   name: "entMain",
  //   component: () => import("@/views/ent/EntMain.vue"),
  //   meta: { unauthorized: true },
  // },
  // {
  //   path: "/ent/list",
  //   name: "entList",
  //   component: () => import("@/views/ent/EntList.vue"),
  //   meta: { unauthorized: true },
  // },
  //구 회원정보 수정
  // {
  //   path: "/v/modify/member",
  //   name: "Member",
  //   component: () => import("@/views/auth/modify/Member.vue"),
  // },
  //구 소개정보관리 모바일
  // {
  //   path: "/v/modify/promotion",
  //   name: "PromotionModify",
  //   component: () => import("@/views/auth/modify/Promotion.vue"),
  //   meta: { unauthorized: true },
  // },
  // 구 검색정보 관리
  // {
  //   path: "/v/modify/search",
  //   name: "SearchModify",
  //   component: () => import("@/views/auth/modify/Search.vue"),
  // },
  {
    path: "/v/intro",
    name: "intro",
    component: () => import("@/views/intro/Intro.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/note/noteList",
    name: "NoteList",
    component: () => import("@/views/note/NoteList.vue"),
  },
  //구 쪽지 관리
  // {
  //   path: "/v/note/noteDetail",
  //   name: "NoteDetail",
  //   component: () => import("@/views/note/NoteDetail.vue"),
  // },
  //구 소개정보 관리 웹
  // {
  //   path: "/v/pr/manage",
  //   name: "Pr",
  //   component: () => import("@/views/pr/Manage.vue"),
  // },
  {
    path: "/v/pr/managepreview",
    name: "PrPreview",
    component: () => import("@/views/pr/ManagePreview.vue"),
  },
  {
    path: "/v/pr/manage/:code",
    name: "PrSample",
    component: () => import("@/views/pr/ManageSample.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/pr/view/:code",
    name: "ViewPrWindow",
    component: () => import("@/views/pr/ViewPrWindow.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/dr/:code",
    name: "PrView",
    component: () => import("@/views/pr/ViewPrWindow.vue"),
    meta: { unauthorized: true },
  },
  // {
  //   path: "/drdr/:code",
  //   name: "PrView2",
  //   component: () => import("@/views/pr/ViewPrWindow copy.vue"),
  //   meta: { unauthorized: true },
  // },
  {
    path: "/v/entDr/:code",
    name: "EntPrView",
    component: () => import("@/views/pr/ViewPrWindowEnt.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/calendarDark",
    name: "calendarDark",
    component: () => import("@/views/request/CalendarDark.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/calendar",
    name: "calendar",
    component: () => import("@/views/request/Calendar.vue"),
    meta: { unauthorized: false },
  },
  {
    path: "/v/error",
    name: "Error",
    component: () => import("@/views/util/Error.vue"),
    meta: { unauthorized: true },
  },
  // {
  //   path: "/gift",
  //   name: "Gift",
  //   component: () => import("@/views/util/Gift.vue"),
  //   meta: { unauthorized: true },
  // },
  {
    path: "/editor",
    name: "Editor",
    component: () => import("@/views/util/Editor.vue"),
    meta: { unauthorized: true },
  },
  //구 회원정보 탈퇴
  {
    path: "/v/draw",
    name: "draw",
    component: () => import("@/views/util/Draw.vue"),
    meta: { unauthorized: true },
  },
  //구 비밀번호 변경
  // {
  //   path: "/v/changePw",
  //   name: "changePw",
  //   component: () => import("@/views/util/ChangePw.vue"),
  //   meta: { unauthorized: true },
  // },
  {
    path: "/v/login",
    name: "Login",
    component: () => import("@/views/util/Login.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/restrictPw",
    name: "RestrictPw",
    component: () => import("@/views/util/RestrictPw.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/recovery",
    name: "Recovery",
    component: () => import("@/views/util/Recovery.vue"),
    meta: { unauthorized: true },
  },
  {
    path: "/v/requireChangePw",
    name: "RequireChangePw",
    component: () => import("@/views/util/RequiredChangePw.vue"),
    meta: { unauthorized: true },
  },
  //구 부계정 관리
  // {
  //   path: "/v/modify/sub",
  //   name: "Sub",
  //   component: () => import("@/views/auth/join/Sub.vue"),
  // },

  // {
  //   path: "/v/request/listtest",
  //   name: "r-List-test",
  //   component: () => import("@/views/request/ListTest.vue"),
  // },
  {
    path: "/v/introduce",
    component: () => import("@/components/introduce/AppToolbar.vue"),
    props: true,
    children: [
      {
        path: "helpdesk",
        name: "helpdesk",
        component: () => import("@/views/introduce/Helpdesk.vue"),
        meta: { unauthorized: true },
      },
      {
        path: "company",
        name: "company",
        component: () => import("@/views/introduce/Company.vue"),
        meta: { unauthorized: true },
      },
      {
        path: "service",
        name: "service",
        component: () => import("@/views/introduce/Service.vue"),
        meta: { unauthorized: true },
      },
      {
        path: "service/:code",
        name: "serviceCode",
        component: () => import("@/views/introduce/Service.vue"),
        meta: { unauthorized: true },
      },
    ],
  },
  {
    path: "/v/notice/",
    component: () => import("@/components/introduce/AppToolbar.vue"),
    props: true,
    children: [
      {
        path: "noticeList",
        name: "NoticeList",
        component: () => import("@/views/notice/NoticeList.vue"),
      },
      {
        path: "noticeDetail/:code",
        name: "NoticeDetail",
        component: () => import("@/views/notice/NoticeDetail.vue"),
      },
    ],
  },
  {
    path: "/v/req/agreefinish",
    name: "AgreeMobileFinish",
    component: () => import("@/views/request/AgreementMobileFinish.vue"),
    meta: { unauthorized: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});
router.beforeEach(async (to, from, next) => {
  /** 내가 지정한 URL */
  /** https://router.vuejs.org/guide/advanced/meta.html  */
  if (to.matched.some((record) => record.meta.unauthorized)) {
    //console.log("권한이 필요없어 이동함", to.path);
    sessionStorage.setItem("currentUrl", to.path);
    if (to.path !== "/v/login") {
      sessionStorage.setItem("destinedURL", to.path);
    }
    return next();
  } else if (localStorage.getItem("token") !== null) {
    //console.log("권한이 부여되어 이동함", to.path);
    if (localStorage.getItem("pwActiveYn") === "N") {
      console.log("비밀번호 잠금으로 인해 비밀번호 변경이 필요한 상태입니다.");
    } else if (localStorage.getItem("pwElapYn") === "Y") {
      console.log(
        "비밀번호를 3개월간 바꿈으로 인해 변경이 권유되는 상태입니다."
      );
    } else if (localStorage.getItem("suspandYn") === "Y") {
      console.log("휴면계정으로 인해 접근이 제한된 상태입니다.");
    }
    if (to.path !== "/v/login") {
      sessionStorage.setItem("destinedURL", to.path);
    }
    sessionStorage.setItem("currentUrl", to.path);
    return next();
  } else if (to.path === "/v/request/researchHospital") {
    //console.log("비로그인 예외처리 실행함 by 세인", to.path);
    sessionStorage.setItem("currentUrl", to.path);
    return next();
  } else {
    //console.log("이동하기 위해선 권한이 필요함", to.path);
    sessionStorage.setItem("destinedURL", to.path);
    return next("/v/login");
  }
});
export default router;
