import { asso } from "@/api";

const state = {
  keywordList: [],
};

const getters = {
  GET_KEYWORD_LIST: (state) => state.keywordList,
};

const mutations = {
  SET_KEYWORD_LIST(state, listData) {
    state.keywordList = listData;
  },
};

const actions = {
  BRING_KEYWORD_LIST({ commit }, payload) {
    return asso.getKeywordList(payload).then((data) => {
      commit("SET_KEYWORD_LIST", data);
    });
  },
  BRING_KEYWORD_LIST_WITH_KEY({ commit }, payload) {
    return asso.getKeywordListWithKey(payload).then((data) => {
      commit("SET_KEYWORD_LIST", data);
    });
  },
  UPDATE_KEYWORD_LIST({ commit }, payload) {
    return asso.updateKeywordList(payload).then((data) => {
      return data;
    });
  },
};

export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
