<template>
  <div :class="{ active: isLogin }" style="width: 100%">
    <v-container fluid class="pa-0 ma-0">
      <v-row no-gutters justify="center">
        <v-col :cols="mypage ? 12 : 10">
          <v-container
            class="pa-7"
            style="max-width: none !important"
            v-if="$vuetify.breakpoint.lgAndUp"
          >
            <v-row justify="space-between" align="center">
              <v-col cols="auto">
                <img
                  v-if="!isLogin"
                  src="@/assets/images/logo/logo_default.svg"
                  style="height: 30px; cursor: pointer; position: relative"
                  @click="logoGoURL('/')"
                />
                <span
                  v-else
                  style="position: relative"
                  class="header-logo-login"
                >
                  <img
                    src="@/assets/images/logo/logo_default_bar.png"
                    style="height: 30px; cursor: pointer"
                    @click="logoGoURL('/')"
                  />
                  <span
                    v-if="isLogin"
                    v-show="!ent"
                    style="
                      position: relative;
                      top: -6px;
                      left: 10px;
                      font-size: 15px;
                      font-weight: bold;
                    "
                    class="header-hellow"
                  >
                    {{ username }}님, 어서오세요.
                  </span>
                </span>
              </v-col>
              <v-col class="header_noLogin" cols="auto" v-if="!isLogin">
                <v-row justify="end">
                  <v-col cols="auto">
                    <v-btn
                      dark
                      color="reform_primary"
                      @click="login()"
                      class="btn_Login"
                      v-show="!ent"
                    >
                      로그인
                    </v-btn>
                    <v-btn
                      dark
                      color="reform_primary"
                      @click="goRegiste()"
                      class="btn_Login"
                      v-show="!ent"
                    >
                      회원가입
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                class="header-right right-text"
                v-if="isLogin"
                v-show="!ent"
              >
                <v-row no-gutters align="center" justify="end">
                  <v-col
                    cols="auto"
                    class="mr-3 header-pointer"
                    @click="goMsg()"
                  >
                    <v-hover v-slot="{ hover }">
                      <span
                        class="ma-0 pa-0"
                        :style="hover ? 'color: #146f83' : ''"
                      >
                        <v-icon class="mr-1" :color="hover ? '#146F83' : ''">
                          mdi-email-outline
                        </v-icon>
                        메시지
                      </span>
                    </v-hover>
                    <v-chip
                      v-if="noteCount !== 0"
                      color="orange"
                      class="white--text pl-2 pr-2"
                      label
                      small
                    >
                      <strong v-html="noteCount" class="count"></strong>
                    </v-chip>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="mr-3 header-pointer"
                    @click="goURL('/v/request/researchHospital')"
                  >
                    <v-hover v-slot="{ hover }">
                      <span
                        class="ma-0 pa-0"
                        :style="hover ? 'color: #146f83' : ''"
                      >
                        <v-icon class="mr-1" :color="hover ? '#146F83' : ''"
                          >mdi-magnify</v-icon
                        >
                        병원검색
                      </span>
                    </v-hover>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="mr-3 header-pointer"
                    @click="goURL('/v/request/requestDocument')"
                  >
                    <v-hover v-slot="{ hover }">
                      <span
                        class="ma-0 pa-0"
                        :style="hover ? 'color: #146f83' : ''"
                      >
                        <v-icon class="mr-1" :color="hover ? '#146F83' : ''"
                          >mdi-file-chart-outline</v-icon
                        >
                        진료의뢰
                      </span>
                    </v-hover>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="mr-5 header-pointer"
                    @click="goURL('/v/mypage/request/0')"
                  >
                    <v-hover v-slot="{ hover }">
                      <span
                        class="ma-0 pa-0"
                        :style="hover ? 'color: #146f83' : ''"
                      >
                        <v-icon class="mr-1" :color="hover ? '#146F83' : ''"
                          >mdi-account-circle-outline</v-icon
                        >
                        마이페이지
                      </span>
                    </v-hover>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      dark
                      color="reform_primary"
                      @click="callLogOutPop()"
                      style="width: 100px; cursor: pointer"
                      v-show="!ent"
                    >
                      로그아웃
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <div v-else>
            <v-btn
              elevation="0"
              @click="moveTop()"
              v-show="!drawer"
              color="#eeeeed"
              dark
              small
              absolute
              right
              bottom
              fixed
              fab
              style="margin-bottom: 88px; border: thin solid #cccccc"
            >
              <v-icon color="black">mdi-chevron-up</v-icon>
            </v-btn>
            <v-btn
              elevation="0"
              @click="drawer = !drawer"
              v-show="!drawer"
              color="reform_primary"
              dark
              small
              absolute
              right
              bottom
              fixed
              fab
              class="mb-10"
              v-if="!ent"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-navigation-drawer
              v-model="drawer"
              app
              right
              temporary
              color="reform_primary_light_2"
              v-if="!ent"
            >
              <!-- 상단 -->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-center">
                    <!-- 상단 -->
                    <img
                      src="@/assets/images/logo/logo_default.svg"
                      style="height: 30px; cursor: pointer; position: relative"
                      @click="goURL('/')"
                    />
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="isLogin"
                    class="text-center font-weight-bold"
                  >
                    {{ username }}님, 어서오세요
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider />
              <!-- 하단/로그인 -->
              <v-list nav dense v-if="isLogin">
                <v-list-item
                  v-for="item in items"
                  :key="item.title"
                  @click="goURL(item.to)"
                  class="py-1"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item class="py-1" @click="callLogOutPop()">
                  <v-list-item-icon>
                    <v-icon>mdi-account-off-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>로그아웃</v-list-item-title>
                </v-list-item>
              </v-list>
              <!-- 하단/비로그인 -->
              <v-list nav dense v-else>
                <v-list-item class="py-1" @click="login()">
                  <v-list-item-icon>
                    <v-icon>mdi-account-key-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>로그인</v-list-item-title>
                </v-list-item>
                <v-list-item class="py-1" @click="goRegiste()">
                  <v-list-item-icon>
                    <v-icon>mdi-account-plus-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>회원가입</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <api-popup
      v-if="checkApiPopup"
      :apiSet="apiSet"
      @call-parents-popup="doLayerPop()"
      @is-login="loginOn()"
      @reload-api-pop="reloadApiPop"
      @require-change-password-done="changePwDone"
    />
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @reload-pop="reloadPop"
    />
  </div>
</template>
<script>
import ApiPopup from "@/components/modal/ApiPopup";
import DefaultPopup from "@/components/modal/DefaultPopupHeader";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  props: {
    headerOption: {
      navOrder: {
        type: Number,
        default: 1,
      },
      isMain: {
        type: Boolean,
        default: false,
      },
      login: {
        id: "",
        pw: "",
      },
    },
  },
  components: {
    ApiPopup,
    DefaultPopup,
  },
  //   data: () => ({
  //       isLogin : false,
  //       username : "응가맨",
  //       login: {
  //           id: "",
  //           pw: "",
  //       }
  //   }),
  data() {
    return {
      navOrder: this.headerOption.navOrder,
      isMain: this.headerOption.isMain,
      lastScrollPosition: 0,
      apiSet: {},
      popupSet: {},
      noteCount: 0,
      referCount: 0,
      isLogin: false,
      username: null,
      drawer: false,
      items: [
        {
          title: "메시지",
          icon: "mdi-email-outline",
          to: "/v/mypage/inboxnoteList",
        },
        {
          title: "병원검색",
          icon: "mdi-magnify",
          to: "/v/request/researchHospital",
        },
        {
          title: "진료의뢰",
          icon: "mdi-file-document-outline",
          to: "/v/request/requestDocument",
        },
        {
          title: "마이페이지",
          icon: "mdi-account-circle-outline",
          to: "/v/mypage/request/0",
        },
      ],
      ent: false,
    };
  },
  mounted() {
    this.checkIsLogin();
  },
  updated() {
    //this.checkIsLogin();
  },
  computed: {
    ...mapState("basic", ["checkApiPopup", "isChildLoading"]),
    ...mapState("basic", {
      checkPopup: (state) => state.header.checkPopup,
    }),
    ...mapGetters("basic", ["getPopConfig"]),
    ...mapGetters("auth", ["GET_IS_LOGIN_ACTIVE"]),
    ...mapGetters("join", ["GET_BASIC"]),
    mypage() {
      let text = this.$route.path;
      if (text.includes("v/mypage")) return true;
      if (text.includes("v/payment")) return true;
      return false;
    },
  },
  created() {
    //console.log("isMain", this.isMain);
    //console.log("headerOption : ", this.headerOption);
  },
  methods: {
    ...mapActions("auth", ["LOGIN"]),
    ...mapActions("note", ["NOTE_COUNT", "REFER_COUNT"]),
    ...mapMutations("basic", ["SET_API_POPUP", "SET_POPUP_HEADER"]),
    ...mapMutations("util", ["SET_ISINIT_LOGIN"]),
    ...mapActions("auth", ["IS_LOGIN_ACTIVE"]),
    ...mapActions("join", ["FETCH_BASIC_TARGET"]),
    setMyPage(val) {
      this.mypage = val;
    },
    async isLoginActive() {
      try {
        await this.IS_LOGIN_ACTIVE();
      } catch (error) {
        console.log(error);
        if (sessionStorage.getItem("destinedURL") === "/") {
          this.isLogin = false;
          localStorage.removeItem("token");
        } else {
          this.$router.push("/v/login").catch(() => {});
        }
      }
    },
    goURL(url) {
      this.$router.push(url).catch(() => {});
    },
    logoGoURL(url) {
      const href = window.location.href;
      if (href.indexOf("/entered") > 0) {
        this.$router.push(url).catch(() => {});
        window.scrollTo(0, 0);
        return;
      } else if (href.indexOf("/ent") > 0) {
        // console.log(href);
        this.$router.push("/ent").catch(() => {});
        window.scrollTo(0, 0);
        return;
      }
      this.$router.push(url).catch(() => {});
      window.scrollTo(0, 0);
    },
    goRegiste() {
      this.$router.push("/v/join/agreement").catch(() => {});
    },
    async getNoteCount() {
      try {
        await this.NOTE_COUNT().then((data) => {
          //console.log(data);
          this.noteCount = Number(data.data);
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async getReferCount() {
      try {
        await this.REFER_COUNT().then((data) => {
          //console.log(data);
          this.referCount = Number(data.data);
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    goMsg() {
      this.$router.push("/v/mypage/inboxnoteList").catch(() => {});
    },
    entOnOff(data) {
      if (data === "on") {
        this.ent = true;
      } else {
        this.ent = false;
      }
    },
    callMessage(data) {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "Message";
      this.apiSet.apiBtnText = "전송";
      this.apiSet.dataParam = data;
    },
    callAuthPop() {
      this.clearLocalStorage();
      this.popupSet.title = "권한확인";
      this.popupSet.content = "권한이 없거나 만료되었습니다.";
      this.popupSet.popType = "custom";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = undefined;
      this.popupSet.destination = true;
      this.popupSet.nextLink = "/v/login";
      this.popupSet.isTopPriority = true;
      this.popupSet.authCause = true;
      this.SET_POPUP_HEADER(true);
    },
    callNormalPop() {
      this.SET_POPUP_HEADER(true);
      this.popupSet.title = "로그인 확인";
      this.popupSet.content = "로그인이 필요한 메뉴입니다.";
      this.popupSet.popType = "warn";
      this.popupSet.cancelBtnText = "확인";
    },
    async login() {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "LoginMain";
      this.apiSet.title = "LOGIN";
      this.apiSet.content = "리뉴얼하는 곳";
      this.drawer = false;
      // if (defineUrl !== undefined) {
      //   this.apiSet.defineUrl = defineUrl;
      //   //console.log("찾기", sessionStorage.getItem("destinedURL"));
      //   if (sessionStorage.getItem("destinedURL") !== null) {
      //     this.apiSet.defineUrl = sessionStorage.getItem("destinedURL");
      //   }
      // }
      // if (d === sessionStorage.getItem("pathToLoadUnAuthInApi")) {
      //   this.apiSet.defineUrl = "reload";
      // }
      // if (type !== undefined && type === "fixing") {
      //   this.apiSet.isFixing = true;
      // }
    },
    changePwDone() {
      this.$emit("change-pw-done");
    },
    calledNav(type) {
      //console.log("calledNav :", type);
      if (type === "login") {
        this.callLoginPop();
      } else if (type === "logout") {
        this.callLogOutPop();
      } else if (type === "withDraw") {
        //console.log("헤더 탈퇴 진입");
        this.callwithDrawPop();
      }
    },
    loginOn() {
      this.isLogin = true;
      this.login.id = "";
      this.login.pw = "";
      this.username = localStorage.getItem("nm");
      this.getNoteCount();
      if (this.navOrder === 2) {
        setTimeout(() => {
          this.getMargin();
        }, 800);
      }
    },
    getMargin() {
      const clientWidth = document.querySelector(".user-status").clientWidth;
      //console.log("user-status width: ", clientWidth);
      const getMarin =
        document.querySelector(".n-title").childNodes[0].offsetLeft -
        clientWidth;
      //console.log("마진찾기: ", getMarin);
      document.querySelector(".user-status").style.right = getMarin + "px";
      document.querySelector(".user-status").classList.add("active");
    },
    checkIsLogin() {
      if (localStorage.getItem("token") !== null) {
        this.isLogin = true;
        this.username = localStorage.getItem("nm");
        this.getNoteCount();
        this.getReferCount();
        this.isLoginActive();
        //console.log("접속");
        if (
          localStorage.getItem("drId") === "admin" ||
          localStorage.getItem("drId") === "DrRefer"
        ) {
          //console.log(localStorage.getItem("drId") + "관리자 확인 중");
          //console.log(this.$route);
          if (this.$route.name === "RestrictPw") {
            this.$router.push("/v/restrictPw").catch(() => {});
          } else if (this.$route.name === "RequireChangePw") {
            this.$router.push("/v/requireChangePw").catch(() => {});
          } else {
            this.$router.push("/v/adminMain/0").catch(() => {});
          }
        } else if (localStorage.getItem("drId") === "drdesign") {
          console.log(localStorage.getItem("drId") + "디자이너 확인");
          console.log(this.$route);
          if (this.$route.name === "RestrictPw") {
            this.$router.push("/v/restrictPw").catch(() => {});
          } else if (this.$route.name === "RequireChangePw") {
            this.$router.push("/v/requireChangePw").catch(() => {});
          } else if (this.$route.path !== "/v/designer/main/2") {
            this.$router.push("/v/designer/main/0").catch(() => {});
          }
        }
      } else {
        //this.callLoginPop();
        this.isLogin = false;
      }
    },
    reloadPop(type) {
      //console.log("popInfo", type);
      setTimeout(() => {
        if (type === "Login") {
          this.callLoginPop();
        }
      }, 800);
    },
    // checkIsCheckedIdentity() {
    //   this.SET_API_POPUP(true);
    //   this.apiSet.popType = "DrLicense";
    //   this.apiSet.title = "의사면허번호 확인";
    //   this.apiSet.content =
    //     "이 서비스는 의사 회원만을 위한 서비스입니다.\n중복 가입을 피하기 위해 의사면호번호를 확인합니다.";
    //   this.apiSet.placeholder = "의사면허번호 입력";
    //   this.apiSet.warnMessage =
    //     "신규 가입한 회원의 의사면허번호는 가입 후 검증합니다.\n" +
    //     "의사면허번호 및 개인정보가 일치하지 않으면\n" +
    //     "서비스 사용이 중단됩니다.";
    //   this.apiSet.apiBtnText = "중복확인";
    //   this.apiSet.confirmBtnText = "회원가입";
    //   this.apiSet.nextLink = "/v/join/member";
    // },
    // callReplyComment(referId, replyComment, str, rcvAttachment) {
    //   //console.log(referId, replyComment, str, rcvAttachment);
    //   this.SET_API_POPUP(true);
    //   this.apiSet.popType = "replyComment2";
    //   this.apiSet.title = "회신등록";
    //   this.apiSet.customParam2 = replyComment;
    //   this.apiSet.customParam = referId;
    //   this.apiSet.rcvAttachment = rcvAttachment;
    // },
    reloadApiPop(popInfo) {
      //console.log("popInfo", popInfo);
      if (popInfo.immediatly !== undefined && popInfo.immediatly === true) {
        this.SET_API_POPUP(true);
      } else {
        setTimeout(() => {
          this.SET_API_POPUP(true);
        }, 800);
      }
      this.apiSet.popType = popInfo.popType;
      this.apiSet.title = popInfo.title;
      this.apiSet.content = popInfo.content;
      if (popInfo.functionValue !== undefined) {
        //console.log("전송할 데이터파람", popInfo.functionValue);
        this.apiSet.dataParam = popInfo.functionValue;
      }
    },
    // callFindPwCheckPop() {
    //   this.SET_API_POPUP(true);
    //   this.apiSet.popType = "FindPw";
    //   this.apiSet.title = "비밀번호 찾기";
    //   this.apiSet.content = "비밀번호를 확인한 후 변경할 수 있습니다.";
    // },
    // callPwCheckPop() {
    //   this.SET_API_POPUP(true);
    //   this.apiSet.popType = "CheckPassword";
    //   this.apiSet.title = "비밀번호 확인";
    //   this.apiSet.content = "비밀번호를 확인한 후 변경할 수 있습니다.";
    // },
    callPwOwnCheckPopup() {
      // this.SET_API_POPUP(true);
      // this.apiSet.popType = "CheckPasswordOwn";
      // this.apiSet.title = "비밀번호 확인";
      // this.apiSet.content = "비밀번호를 확인한 후 변경할 수 있습니다.";
      this.$router.push("/v/mypage/changePw");
    },
    async callLoginPop(defineUrl, type) {
      //console.log(this.$route.name);
      if (this.$route.name === "Done") {
        if (localStorage.getItem("drId") !== null) {
          this.FETCH_BASIC_TARGET(localStorage.getItem("drId")).then(() => {
            if (
              this.GET_BASIC.drId !== undefined ||
              this.GET_BASIC.drId !== null ||
              this.GET_BASIC.drId !== ""
            ) {
              this.$router.push(defineUrl);
            } else {
              this.SET_API_POPUP(true);
              this.apiSet.popType = "LoginMain";
              this.apiSet.title = "LOGIN";
              this.apiSet.content =
                "의사 회원만 이용 가능한 서비스 입니다.\n로그인 후 검색 하실 수 있습니다.";
            }
          });
        } else {
          this.SET_API_POPUP(true);
          this.apiSet.popType = "LoginMain";
          this.apiSet.title = "LOGIN";
          this.apiSet.content =
            "의사 회원만 이용 가능한 서비스 입니다.\n로그인 후 검색 하실 수 있습니다.";
        }
      } else if (this.$route.name !== "Home") {
        //console.log("헤더에서부른 로그인팝 예약URL", defineUrl);
        /**
        const d = document.location.href.replace("localhost:8080", "");
         */
        const d = document.location.href.replace("https://www.drrefer.net", "");
        //console.log("d의값", d);
        /*
        console.log(
          "검증",
          d === sessionStorage.getItem("pathToLoadUnAuthInApi")
        );
        */

        this.SET_API_POPUP(true);
        this.apiSet.popType = "LoginMain";
        this.apiSet.title = "LOGIN";
        this.apiSet.content =
          "의사 회원만 이용 가능한 서비스 입니다.\n로그인 후 검색 하실 수 있습니다.";
        if (defineUrl !== undefined) {
          this.apiSet.defineUrl = defineUrl;
          //console.log("찾기", sessionStorage.getItem("destinedURL"));
          if (sessionStorage.getItem("destinedURL") !== null) {
            this.apiSet.defineUrl = sessionStorage.getItem("destinedURL");
          }
        }
        if (d === sessionStorage.getItem("pathToLoadUnAuthInApi")) {
          this.apiSet.defineUrl = "reload";
        }
        if (type !== undefined && type === "fixing") {
          this.apiSet.isFixing = true;
        }
      }
    },
    callFindIdPop() {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "FindId";
      this.apiSet.title = "아이디 찾기";
      this.apiSet.content = "휴대폰으로 전송된 인증번호를 입력해주세요.";
    },
    doLayerPop() {
      //console.log("가져온 레이어팝업 재료", this.getPopConfig);
      this.popupSet.title = this.getPopConfig.title;
      this.popupSet.content = this.getPopConfig.content;
      /** 팝업 타입 */
      if (this.getPopConfig.popType !== undefined) {
        this.popupSet.popType = this.getPopConfig.popType;
      } else {
        this.popupSet.popType = "warn";
      }
      if (this.getPopConfig.confirmBtnText !== undefined) {
        this.popupSet.confirmBtnText = this.getPopConfig.confirmBtnText;
        this.popupSet.cancelBtnText = this.getPopConfig.cancelBtnText;
      }
      this.popupSet.cancelBtnText = this.getPopConfig.cancelBtnText;
      if (this.getPopConfig.action !== undefined) {
        this.popupSet.action = this.getPopConfig.action;
      }
      if (this.getPopConfig.defineUrl !== undefined) {
        this.popupSet.nextLink = this.getPopConfig.defineUrl;
        this.popupSet.destination = true;
      }
      if (this.getPopConfig.authCause !== undefined) {
        this.popupSet.authCause = this.getPopConfig.authCause;
      }
      this.popupSet.isTopPriority = true;
      this.SET_POPUP_HEADER(true);
      //console.log("최종 팝업셋값", this.popupSet);
    },

    clearLocalStorage() {
      this.isLogin = false;
      localStorage.removeItem("drId");
      localStorage.removeItem("nm");
      localStorage.removeItem("token");
      localStorage.removeItem("memType");
      localStorage.removeItem("pwActiveYn");
      localStorage.removeItem("pwElapYn");
      localStorage.removeItem("suspandYn");
      localStorage.removeItem("popYn");
    },
    callwithDrawPop() {
      //console.log("엡 헤더 탈퇴 팝업 진입");
      this.clearLocalStorage();
      this.popupSet.title = "탈퇴완료";
      this.popupSet.content = "정상적으로 회원탈퇴 되었습니다.";
      this.popupSet.popType = "custom";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = undefined;
      this.popupSet.destination = true;
      this.popupSet.nextLink = "/";
      this.popupSet.isTopPriority = true;
      this.SET_POPUP_HEADER(true);
    },
    // initLoginStatus() {
    //   //console.log("로그인 초기롸로직 실행됨");
    //   this.isLogin = false;
    // },
    initLogin() {
      this.SET_ISINIT_LOGIN(true);
    },
    callLogOutPop() {
      this.clearLocalStorage();
      this.popupSet.title = "로그아웃";
      this.popupSet.content = "정상적으로 로그아웃되었습니다.";
      this.popupSet.popType = "custom";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = undefined;
      const locate = document.location.href;
      const d = locate.replace("https://www.drrefer.net", "");
      //console.log("Destination", d);
      if (d !== "/") {
        //console.log("destination 진입");
        this.popupSet.destination = true;
        this.popupSet.nextLink = "/";
      }
      this.popupSet.isTopPriority = true;
      this.isLogin = false;
      this.SET_POPUP_HEADER(true);
    },
    // testScroll(cb) {
    //   var rAfTimeout = null;

    //   return function () {
    //     if (rAfTimeout) {
    //       window.cancelAnimationFrame(rAfTimeout);
    //     }
    //     rAfTimeout = window.requestAnimationFrame(function () {
    //       cb();
    //     });
    //   };
    // },
    moveTop() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    $route() {
      if (localStorage.getItem("token") !== null) {
        this.getNoteCount();
      }
      this.SET_POPUP_HEADER(false);
      this.SET_API_POPUP(false);
    },
  },
  // beforeUnmount() {
  //   if (this.checkPopup) {
  //     this.SET_POPUP_HEADER(false);
  //   }
  //   if (this.checkApiPopup) {
  //     this.SET_API_POPUP(false);
  //   }
  // },
  // metaInfo() {
  //   return {
  //     meta: [
  //       {
  //         vmid: "viewport",
  //         name: "viewport",
  //         content:
  //           "width=device-width, height=device-height, initial-scale=0.25, minimum-scale=0.25, maximum-scale=1.0, user-scalable=yes",
  //       },
  //     ],
  //   };
  // },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/AppHeader";
</style>
