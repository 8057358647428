<template>
  <transition name="modal">
    <div
      class="modal-mask"
      @click="overlayClick($event)"
      :class="{ isTopPriority: priority }"
    >
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    footerLess: {
      type: Boolean,
      default: false,
    },
    priority: {
      type: Boolean,
      default: false,
    },
    destination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("basic", ["checkApiPopup"]),
    ...mapState("basic", {
      checkPopup: (state) => state.header.checkPopup,
    }),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP_HEADER", "SET_API_POPUP"]),
    overlayClick(event) {
      const target = event.target;
      //console.log("타겟", target);
      //console.log("커런트", event.currentTarget);
      // const d1 = locate.replace("http://localhost:8080", "");
     const d = document.location.href.replace("https://www.drrefer.net", "");
      if (this.checkPopup !== undefined && this.checkPopup === true) {
        if (this.destination && d !== "/") {
          this.$emit("forced-nextlink");
        }
        this.SET_POPUP_HEADER(false);
      } else if (this.checkApiPopup !== undefined && this.checkApiPopup === true) {
        if (this.destination && d !== "/") {
          this.$emit("forced-nextlink");
        }
        this.SET_API_POPUP(false);
      }
    },
    closePop() {
      //console.log("헤더에서 파생되었나요?");
     // const d1 = locate.replace("http://localhost:8080", "");
      const d = document.location.href.replace("https://www.drrefer.net", "");
      if (this.checkPopup !== undefined && this.checkPopup === true) {
        if (this.destination && d !== "/") {
          this.$emit("forced-nextlink");
        }
        this.SET_POPUP_HEADER(false);
      } else if (this.checkApiPopup !== undefined && this.checkApiPopup === true) {
        if (this.destination && d !== "/") {
          this.$emit("forced-nextlink");
        }
        this.SET_API_POPUP(false);
      }
    },
  },
  mounted() {},
  created() {
    //console.log("모달 헤더");
    //console.log("destination in Modal", this.destination);
  },
};
</script>

<style lang="scss" scoped></style>
