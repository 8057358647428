import axios from "axios";
/** import router from "@/router/index"; */
const domain = process.env.VUE_APP_API_URL;
const request = {
  get(path, header) {
    const currentUrl = document.location.href;
    //console.log("get Api Current URL", currentUrl);
    //console.log("get Path", path);
    return axios
      .get(`${domain + path}`, header)
      .catch((error) => {
        console.log("domain", domain);
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            console.log("path:", path);
            if (error.response.data.status == "5000") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].clearLocalStorage();
              if (path !== "notes/inqNewNoteCount") {
                window.app.$children[0].$children[0].$children[0].callLoginPop(
                  sessionStorage.getItem("pathToLoadUnAuthInApi"),
                  "fixing"
                );
              }
            }
            if (error.response.data.status == "5002") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].clearLocalStorage();
              if (path !== "notes/inqNewNoteCount") {
                window.app.$children[0].$children[0].$children[0].callLoginPop(
                  sessionStorage.getItem("pathToLoadUnAuthInApi"),
                  "fixing"
                );
              }
            }
          }
          if (error.response.status === 401) {
            console.log("401 ERROR 진입:");
            /**
            if (error.data.status == "5000") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             if (error.data.status == "5004") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             */
          }
          if (error.response.status === 404) {
            console.log("404 ERROR 진입:");
            /** router.push("/v/error"); */
          }
          return Promise.reject(error.response);
        } else if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {});
  },
  patch(path, data, header) {
    const currentUrl = document.location.href;
    //console.log("patch Api Current URL", currentUrl);
    //console.log("patch Path", path);
    return axios
      .patch(`${domain + path}`, data, header)
      .catch((error) => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            if (error.response.data.status == "5000") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].callAuthPop();
            }
            if (error.response.data.status == "5002") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].callAuthPop();
            }
          }
          if (error.response.status === 401) {
            console.log("401 ERROR 진입:");
            /**
            if (error.data.status == "5000") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             if (error.data.status == "5004") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             */
          }
          if (error.response.status === 404) {
            console.log("404 ERROR 진입:");
            /** router.push("/v/error"); */
          }
          return Promise.reject(error.response);
        } else if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {});
  },
  post(path, data, header) {
    const currentUrl = document.location.href;
    //console.log("post Api Current URL", currentUrl);
    //console.log("post Path", path);
    return axios
      .post(`${domain + path}`, data, header)
      .catch((error) => {
        console.log("찐에러", error);
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            if (error.response.data.status == "5000") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].callAuthPop();
              console.log(
                "감지대상",
                window.app.$children[0].$children[0].$children[0]
              );
            }
            if (error.response.data.status == "5002") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].callAuthPop();
              console.log(
                "감지대상",
                window.app.$children[0].$children[0].$children[0]
              );
            }
          }
          if (error.response.status === 401) {
            console.log("401 ERROR 진입:");
            /**
            if (error.data.status == "5000") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             if (error.data.status == "5004") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             */
          }
          if (error.response.status === 404) {
            console.log("404 ERROR 진입:");
            /** router.push("/v/error"); */
          }
          return Promise.reject(error.response);
        } else if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {});
  },
  delete(path, header) {
    const currentUrl = document.location.href;
    //console.log("delete Api Current URL", currentUrl);
    //console.log("delete Path", path);
    return axios
      .delete(`${domain + path}`, header)
      .catch((error) => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            if (error.response.data.status == "5000") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].clearLocalStorage();
              window.app.$children[0].$children[0].$children[0].callLoginPop(
                sessionStorage.getItem("pathToLoadUnAuthInApi"),
                "fixing"
              );
            }
            if (error.response.data.status == "5002") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].clearLocalStorage();
              window.app.$children[0].$children[0].$children[0].callLoginPop(
                sessionStorage.getItem("pathToLoadUnAuthInApi"),
                "fixing"
              );
            }
          }
          if (error.response.status === 401) {
            console.log("401 ERROR 진입:");
            /**
            if (error.data.status == "5000") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             if (error.data.status == "5004") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             */
          }
          if (error.response.status === 404) {
            console.log("404 ERROR 진입:");
            /** router.push("/v/error"); */
          }
          return Promise.reject(error.response);
        } else if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log("Error", error.message);
        }
      })
      .finally(() => {});
  },
  put(path, data, header) {
    const currentUrl = document.location.href;
    //console.log("put Api Current URL", currentUrl);
    //console.log("put Path", path);
    return axios
      .put(`${domain + path}`, data, header)
      .catch((error) => {
        if (error.response) {
          console.log("error", error);
          console.log("error response", error.response);
          if (error.response.status === 500) {
            console.log("500 ERROR 진입:");
            if (error.response.data.status == "5000") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].clearLocalStorage();
              window.app.$children[0].$children[0].$children[0].callLoginPop(
                sessionStorage.getItem("pathToLoadUnAuthInApi"),
                "fixing"
              );
            }
            if (error.response.data.status == "5002") {
              sessionStorage.setItem(
                "pathToLoadUnAuthInApi",
                location.pathname
              );
              window.app.$children[0].$children[0].$children[0].clearLocalStorage();
              window.app.$children[0].$children[0].$children[0].callLoginPop(
                sessionStorage.getItem("pathToLoadUnAuthInApi"),
                "fixing"
              );
            }
          }
          if (error.response.status === 401) {
            console.log("401 ERROR 진입:");
            /**
            if (error.data.status == "5000") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             if (error.data.status == "5004") {
              sessionStorage.setItem("pathToLoadUnAuthInApi", location.pathname);
                window.app.$children[0].$children[0].$children[0].callLoginPop();
            }
             */
          }
          if (error.response.status === 404) {
            console.log("404 ERROR 진입:");
            /** router.push("/v/error"); */
          }
          return Promise.reject(error.response);
        } else if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log("err request", error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우identitySuccess
          console.log("Error", error.message);
        }
      })
      .finally(() => {});
  },
};
/**
SET_IDENTITY_VERIFICATION
 */
export const prod = {
  selectUserCouponCount(payload) {
    return request
      .post("coupons/selectUserCouponCount", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringUserCouponUsableList(payload) {
    return request
      .post("coupons/bringUserCouponUsableList", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  addUserCoupon(payload) {
    return request
      .post("coupons/user/add", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringCouponList(payload) {
    return request
      .post("coupons/bringCouponList", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getOrderListForDesigner(orderData) {
    return request
      .post("orders/getOrderListForDesigner", orderData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getInProgressApply(inqParam) {
    return request
      .post("orders/validateNotApply", inqParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringCheckOrder(applyParam) {
    return request
      .post("orders/checkOrderYn", applyParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringCheckApply(applyParam) {
    return request
      .post("orders/checkApplyYn", applyParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  saveApply(applyParam) {
    return request
      .post("orders/saveApply", applyParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  addProd(prodParam) {
    return request
      .post("products/add", prodParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  deleteProd(prodParam) {
    return request
      .post("products/delete", prodParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  modifyProd(prodParam) {
    return request
      .put("products/modify", prodParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getProdList(listStatus) {
    return request
      .post("products/getIntroProdlist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  orderSave(orderData) {
    return request
      .post("orders/saveOrder", orderData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  orderComplete(orderData) {
    return request
      .post("orders/complete", orderData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  orderCompleteWithNoCost(orderData) {
    return request
      .post("orders/completeWithNoCost", orderData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  oderProgressFail(orderData) {
    return request
      .post("orders/progressFail", orderData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};

export const admin = {
  sendCouponEmail(couponParam) {
    return request
      .post("coupons/sendCouponEmail", couponParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((data) => data);
  },
  getUserCoupon(regiId) {
    return request
      .post("coupons/userDetail", regiId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((data) => data);
  },
  getUserCouponListAll(listStatus) {
    return request
      .post("coupons/selectUserAdminAll", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((data) => data);
  },
  useOrDisposalUser(regiId) {
    return request
      .post("coupons/useOrDisposalUser", regiId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  useOrDisposal(cponId) {
    return request
      .post("coupons/useOrDisposal", cponId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  addCouponData(couponParam) {
    return request.post("coupons/add", couponParam, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  modifyCoupon(couponParam) {
    return request
      .put("coupons/modify", couponParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getCponId(cponId) {
    return request
      .post("coupons/getCponId", cponId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((data) => data);
  },
  getCoupon(cponId) {
    return request
      .post("coupons/detail", cponId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((data) => data);
  },
  getCouponListAll(listStatus) {
    return request
      .post("coupons/selectAdminAll", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((data) => data);
  },
  getProdAdmin(prodNo) {
    return request
      .post("products/detail", prodNo, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getProdListAdmin(listStatus) {
    return request
      .post("products/getProdlist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  updateStatusCode(listData) {
    return request
      .put("admin/update_status_list", listData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringOrderList(listStatus) {
    return request
      .post("admin/getOrderList", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getprgsList(listStatus) {
    return request
      .post("admin/doctor/prgslist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getprgsListCopy(listStatus) {
    return request
      .post("admin/doctor/prgslist_grade_002", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getHelpDeskList(listStatus) {
    return request
      .post("helpdesk/gethelpdesklist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getFeedList(listStatus) {
    return request
      .post("feedback/getFeedList", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getFeedListSearch(listStatus) {
    return request
      .post("feedback/getFeedListSearch", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  delete_notice_data(noticeId) {
    return request
      .post("notices/delete", noticeId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  update_notice_data(data) {
    return request
      .post("notices/edit", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  chgStatus(drId) {
    //console.log(drId);
    return request
      .post("admin/doctor/chgstatus", drId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  add_notice_data(noticeParam) {
    //console.log(noticeParam);
    return request
      .post("notices/add", noticeParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },

  getUseYList(listStatus) {
    return request
      .post("admin/doctor/use_Y_list", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },

  getUseNList(listStatus) {
    return request
      .post("admin/doctor/use_N_list", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  update_use_Y(data) {
    return request
      .put("admin/doctor/update_use_Y", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  update_use_N(data) {
    return request
      .put("admin/doctor/update_use_N", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getSymptomList(listStatus) {
    return request
      .post("admin/symptoms/getSymptomlist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  addSymptom(data) {
    return request
      .post("admin/symptoms/add", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getSymptomOne(data) {
    return request
      .post("admin/symptoms/detail", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  updateSymptom(data) {
    return request
      .put("admin/symptoms/modify", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  deleteSymptom(data) {
    return request
      .post("admin/symptoms/delete", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};
export const asso = {
  getKeywordList(data) {
    return request
      .get("ent/entlist", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getKeywordListWithKey(data) {
    return request
      .post("admin/get_keyword_list", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  updateKeywordList(listData) {
    return request
      .put("admin/update_keyword_list", listData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};
export const join = {
  joinBasic(joinData) {
    return request
      .post("signups/basic/add", joinData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchToDoctorLicense(license) {
    return request
      .get("signups/licNo/" + license, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  identitySuccess(reqSeq) {
    return request
      .get("auths/getStatus/" + reqSeq, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getEncData() {
    return request
      .get("auths/checkPlus/getEncData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  checkId(drId) {
    return request
      .get("signups/id/" + drId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchBasic() {
    return request
      .get("members/basic", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchBasicTarget(drId) {
    return request
      .get("members/basic/" + drId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchBasicTargetUnAuth(drId) {
    return request
      .get("members/basic/" + drId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchBasicAddress(address) {
    return request
      .get("members/address/" + address, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  modifyBasic(userData) {
    return request
      .put("members/basic", userData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  modifySub(subInfo) {
    return request
      .put("members/sub", subInfo, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchSub() {
    return request
      .get("members/sub", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  selectHospNm() {
    return request
      .get("members/selectHospNm", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};
export const auth = {
  isLiginActive() {
    return request
      .get("auths/isLoginActive", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  login(joinData) {
    return request
      .post("authenticate", joinData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringSmsCode(userInfo) {
    return request
      .post("members/sms", userInfo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  searchId(userInfo) {
    return request
      .post("members/searchId", userInfo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  checkPw(pwParam) {
    return request
      .post("members/pwd", pwParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  changePwOwn(userInfo) {
    return request
      .put("members/changePasswd", userInfo, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  changePwDefault(userInfo) {
    return request
      .put("members/changePasswd", userInfo, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringSmsCode1(payload) {
    return request
      .get(
        "members/sms",
        {
          params: {
            nm: payload.nm,
            hpNo: payload.hpNo,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Charset": "utf-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      )
      .then(({ data }) => data);
  },
  withDraw() {
    return request
      .put(
        "members/withdraw",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      )
      .then(({ data }) => data);
  },
};
export const search = {
  searchSubmit(searchData) {
    return request
      .post("signups/search/add", searchData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchSearchWithId(drId) {
    return request
      .put("members/getSearchWithId", drId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchSearch() {
    return request
      .get("members/search", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  modifySearch(userData) {
    return request
      .put("members/search", userData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};
export const promotion = {
  promotionSubmit(promotionData) {
    return request
      .post("signups/pr/add", promotionData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchPromotion() {
    return request
      .get("members/pr", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchPromotionTarget(drId) {
    return request
      .get("members/pr/" + drId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          responseType: "blob",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  fetchPromotionTargetUnAuth(drId) {
    return request
      .get("members/pr/" + drId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  promotionModify(promotionData) {
    // console.log("aaaaa");
    // console.log(promotionData);
    // console.log("aaaaa");
    return request
      .put("members/pr", promotionData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};
export const apply = {
  bringApplyList(drId) {
    return request
      .post("orders/getApplyList", drId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};
export const refer = {
  requestVisitorStatus(ReferParam) {
    return request
      .post("refers/print", ReferParam, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  cancleOrder(CancleData) {
    return request
      .post("orders/cancle", CancleData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringTokkenImport(data) {
    return request
      .post("orders/getToken", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  cancle_refer(data) {
    return request
      .post("refers/cancel", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringSmsCode(userInfo) {
    return request
      .post("refers/sms", userInfo, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  checkAgreeId(agreeId) {
    return request
      .get("refers/agree/" + agreeId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  referSubmit(referData) {
    return request
      .post("refers/add", referData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  orderList(orderData) {
    return request
      .post("orders/getOrderList", orderData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  agreeSubmit(agreeData) {
    return request
      .post("refers/agree/edit", agreeData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  referModify(referData) {
    // console.log("refer api and param : " + referData);
    return request
      .put("refers/edit/", referData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  referReply(referReplyData) {
    // console.log("refer reply and param : " + referReplyData);
    return request
      .put("refers/readd/", referReplyData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  replySubmit(reply) {
    return request
      .put("refers/replyM", reply, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  agreeFinish(agreeId) {
    return request
      .get("refers/agreefinish/" + agreeId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  visitCheck(isVisit) {
    return request
      .put("refers/visit/", isVisit, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  visitCheckUnAuth(isVisit) {
    return request
      .post("refers/visit/", isVisit, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bring_agree_id() {
    return request
      .get("refers/genAgreeId", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};

export const referList = {
  searchList(searchWord) {
    // console.log(searchWord.auth);
    if (searchWord.auth) {
      return request
        .post("members/inq", searchWord, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        })
        .then(({ data }) => data);
    } else {
      return request
        .post("members/inq", searchWord, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        })
        .then(({ data }) => data);
    }
  },
  sendList(listStatus) {
    return request
      .post("refers/sendlist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  receiveList(listStatus) {
    return request
      .post("refers/recvlist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  receiveListAll(listStatus) {
    return request
      .post("refers/recvAlllist", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  referDetail(referUrl) {
    return request
      .get("refers/refer/" + referUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  reqDoctor(searchOpt) {
    return request
      .post("members/my/reqDoctor", searchOpt, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  resDoctor(searchOpt) {
    return request
      .post("members/my/resDoctor", searchOpt, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  saveMemo(memo) {
    return request
      .post("refers/memo", memo, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  saveInBox(dataList) {
    return request
      .post("refers/inbox", dataList, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  inBoxRestore(dataList) {
    return request
      .post("refers/restore", dataList, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
};
export const util = {
  bring_refer_calendar_list(listStatus) {
    return request
      .post("refers/getReferCalendarList", listStatus, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  get_recv_exel() {
    return request
      .get("refers/recvExcel", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  get_send_exel() {
    return request
      .get("refers/sendExcel", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  helpDesk(question) {
    return request
      .post("helpdesk/add", question, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  feedback(content) {
    return request
      .post("feedback/edit", content, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  aleadyFeedbackYn(content) {
    return request
      .post("feedback/feedYn", content, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getFeedbackData(feedId) {
    return request
      .get("feedback/inq/" + feedId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringPdfUrl() {
    return request
      .get("members/report", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringPdfUrlPr(drId) {
    return request
      .get("members/report/" + drId, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bringPdfUrlOnRefer(referId) {
    return request
      .get("refers/report/" + referId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  /** 파일정보를 겟하는 */
  bringFileInfo(fileId) {
    return request
      .get("files/" + fileId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  getPdf(fileId) {
    return request
      .get("pdfs/" + fileId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        responseType: "blob",
      })
      .then(({ data }) => data);
  },
  getPdfPr(fileId) {
    return request
      .get(
        "prpdfs/" + fileId,
        {
          responseType: "blob",
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      )
      .then(({ data }) => data);
  },
  getNote(filePath) {
    // console.log(filePath);
    return request
      .get("note/" + filePath.fileId + "/" + filePath.datePath, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        responseType: "blob",
      })
      .then(({ data }) => data);
  },
  delayPasswd() {
    return request
      .put(
        "members/delayPasswd",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      )
      .then(({ data }) => data);
  },
  recovery() {
    return request
      .put(
        "members/recovery",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      )
      .then(({ data }) => data);
  },
};

export const note = {
  bringNoteList(payload) {
    return request.post("notes/getNoteList", payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  recptlist(searchOpt) {
    return request.post("notes/recptlist", searchOpt, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  translist(searchOpt) {
    return request.post("notes/translist", searchOpt, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  recptDetail(searchOpt) {
    return request.post("notes/recptDetail", searchOpt, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  transDetail(searchOpt) {
    return request.post("notes/transDetail", searchOpt, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  noteAdd(data) {
    return request.post("notes/add", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  recptDelete(noteInfo) {
    return request.post("notes/deleteRecpt", noteInfo, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  transDelete(noteInfo) {
    return request.post("notes/deleteTrans", noteInfo, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  noteCount() {
    return request.get("notes/inqNewNoteCount", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  referCount() {
    return request.get("notes/inqReqPatientCount", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
};
export const notice = {
  noticeList(searchOpt) {
    return request.post("notices/list", searchOpt, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  noticeDetail(noticeId) {
    return request.post("notices/detail", noticeId, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
};
export const book = {
  addBook(bookObj) {
    return request.post("appointment/add", bookObj, {
      headers: {
        // Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  selectFromDrid(payload) {
    return request
      .post("appointment/selectFromDrId", payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then(({ data }) => data);
  },
  bookCheck(payload) {
    return request.post("appointment/bookCheck", payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  getAppointmentData() {
    return request.get("members/appointment/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  updateAppointmentData(payload) {
    return request.post("members/appointment/", payload, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
};
