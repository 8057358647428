<template>
  <modal
    :priority="isTopPriority"
    :destination="destination"
    ref="myModal"
    @forced-nextlink="goNextLink()"
  >
    <template v-slot:body>
      <div class="default-pop" v-if="popType === 'warn'">
        <div class="close" @click="close()">
          <img src="@/assets/images/x.svg" />
        </div>
        <div class="header">
          <h4 v-html="titleMutated"></h4>
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap">
          <button
            v-text="cancelBtnText"
            ref="btn_warn"
            type="button"
            @click="close()"
          />
        </div>
      </div>
      <div class="default-pop" v-if="popType === 'custom'">
        <div class="close" @click="close()">
          <img src="@/assets/images/x.svg" />
        </div>
        <div class="header">
          <h4 v-html="titleMutated"></h4>
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div
          class="btn-wrap-multiple"
          v-if="confirmBtnText !== undefined && cancelBtnText !== undefined"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            class="prev"
            @click="prev()"
            v-if="cancelBtnText !== undefined"
          />
          <button
            v-text="confirmBtnText"
            type="button"
            class="activated"
            ref="btn_custom"
            @click="next()"
            v-if="confirmBtnText !== undefined"
          />
        </div>
        <div class="btn-wrap" v-else>
          <button
            v-text="cancelBtnText"
            type="button"
            class="prev"
            @click="prev()"
            v-if="cancelBtnText !== undefined"
          />
          <button
            v-text="confirmBtnText"
            type="button"
            class="activated"
            ref="btn_custom"
            @click="next()"
            v-if="confirmBtnText !== undefined"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Modal from "./ModalHeader.vue";

export default {
  props: {
    popupSet: {
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      prevLink: {
        type: String,
      },
      isTopPriority: {
        type: Boolean,
      },
      action: {
        type: String,
      },
      destination: {
        type: Boolean,
      },
      authCause: {
        type: Boolean,
        default: false,
      },
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      input: "",
      valid: false,
      popType: this.popupSet.popType || "warn",
      title: this.popupSet.title,
      authCause: this.popupSet.authCause,
      content: this.popupSet.content,
      confirmBtnText: this.popupSet.confirmBtnText,
      cancelBtnText: this.popupSet.cancelBtnText,
      nextLink: this.popupSet.nextLink,
      prevLink: this.popupSet.prevLink,
      isTopPriority: this.popupSet.isTopPriority,
      action: this.popupSet.action,
      destination: this.popupSet.destination,
    };
  },
  watch: {},
  mounted() {
    //console.log(this.popType);
    if (this.popType === "warn") {
      this.$refs.btn_warn.focus();
    } else if (this.popType === "custom") {
      this.$refs.btn_custom.focus();
    }
  },
  computed: {
    ...mapState("basic", ["checkCustomPopup"]),
    titleMutated() {
      if (this.title !== undefined && this.title.indexOf("\n") !== -1) {
        return this.title.split("\n").join("<br />");
      } else {
        return this.title;
      }
    },
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf("\n") !== 1) {
        return this.content.split("\n").join("<br />");
      } else {
        return this.content;
      }
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP_HEADER"]),
    check(data) {
      //console.log(data);
      //console.log("a");
    },
    goNextLink() {
      if (this.nextLink !== "reload") {
        this.$router.push(this.nextLink).catch(()=>{});
      } else {
        window.location.reload();
      }
    },
    next() {
      //console.log("defaultPopNextLink: ", this.nextLink);
      const d = document.location.href.replace("https://www.drrefer.net", "");
      //console.log(this.nextLink);
      //console.log(d);
      if (this.action !== undefined) {
        //console.log("if1 : " + this.nextLink);
        //console.log(d);
        this.$emit("reload-pop", this.action);
        this.SET_POPUP_HEADER(false);
      }
      if (this.nextLink !== undefined && d !== "/") {
        if (this.nextLink !== "reload") {
          //console.log(this.nextLink);
          //console.log(d);
          if (
            localStorage.getItem("drId") === "admin" ||
            localStorage.getItem("drId") === "DrRefer"
          ) {
            //console.log(localStorage.getItem("drId") + "관리자 확인 중");
            this.$router.push("/v/adminMain/0").catch(()=>{});
          } else {
            //console.log(this.$router);
            this.$router.push(this.nextLink).catch((err) => {
              console.log(err);
            });
          }
          //window.location.reload();
          /*this.$router.push(this.nextLink).catch((err) => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // But print any other errors to the console
              console.log(err);
            }
          });*/
        } else {
          //console.log("77777777777777");
          window.location.reload();
        }
      } else {
        //console.log("888888888888888");
        //console.log("else : " + this.nextLink);
        //console.log(d);
        window.location.reload();
      }
      this.SET_POPUP_HEADER(false);
    },
    prev() {
      this.SET_POPUP_HEADER(false);
      this.$router.push(this.prevLink).catch(()=>{});
    },
    close() {
      //console.log("우야", this.authCause);
      if (this.authCause) {
        this.$refs.myModal.closePop();
      } else {
        this.SET_POPUP_HEADER(false);
      }
    },
  },
  created() {
    //console.log("팝업아이템", this.popupSet);
  },
  beforeDestroy() {
    if (this.checkPopup) {
      this.SET_POPUP_HEADER(false);
    }
  },
};
</script>

<style lang="scss" scoped></style>
