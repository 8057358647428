import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import basic from "./modules/basic";
import auth from "./modules/auth";
import join from "./modules/join";
import search from "./modules/search";
import promotion from "./modules/promotion";
import request from "./modules/request";
import referList from "./modules/referList";
import util from "./modules/util";
import note from "./modules/note";
import notice from "./modules/notice";
import admin from "./modules/admin";
import asso from "./modules/asso";
import prod from "./modules/prod";
import book from "./modules/book";

const modules = {
  basic,
  auth,
  join,
  search,
  request,
  referList,
  promotion,
  util,
  note,
  notice,
  admin,
  prod,
  asso,
  book,
};

const plugins = [
  createPersistedState({
    paths: ["auth"],
  }),
];
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules,
  plugins,
});
