import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        reform_primary: "#146F83",
        reform_secondary: "#C1C1C1",
        reform_nana: "#EEEEEE",
        reform_primary_light: "#BCD6DB",
        reform_primary_light_2: "#f5f9fa",
        reform_orange: "#ff7334",
        reform_sky: "#6fdaf1"
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: "xs",
  },
});
