import { auth } from "@/api";

const state = {
  auth: {
    drId: localStorage.getItem("drId") || "",
    username: localStorage.getItem("nm") || "",
    token: localStorage.getItem("token") || "",
    smsList: [],
    searchAuthList: [],
    isCheck: false,
  },
  isLoginActive: "",
};

const mutations = {
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  },
  SET_SMS_LIST(state, list) {
    state.auth.smsList = list;
  },
  SET_SEARCHED_AUTH_DATA(state, list) {
    state.auth.searchAuthList = list;
  },
  SET_TRANS_ID(state, data) {
    state.auth.drId = data;
  },
  SET_CHECK_RESULT(state, data) {
    state.auth.isCheck = data;
  },
  SET_IS_LOGIN_ACTIVE(state, data) {
    state.isLoginActive = data.list;
  },
};

const getters = {
  GET_CHECK_RESULT: (state) => state.auth.isCheck,
  GET_SMS_LIST: (state) => state.auth.smsList,
  GET_SEARCHED_AUTH_DATA: (state) => state.auth.searchAuthList,
  GET_TRANS_ID: (state) => state.auth.drId,
  GET_IS_LOGIN_ACTIVE: (state) => state.isLoginActive,
};

const actions = {
  IS_LOGIN_ACTIVE({ commit }) {
    return auth.isLiginActive().then((data) => {
      commit("SET_IS_LOGIN_ACTIVE", data);
    });
  },
  LOGIN({}, payload) {
    return auth.login(payload);
  },
  BRING_SMS_CODE({ commit }, payload) {
    return auth.bringSmsCode(payload).then((data) => {
      commit("SET_SMS_LIST", data);
    });
  },
  BRING_SEARCHED_ID({ commit }, payload) {
    return auth.searchId(payload).then((data) => {
      commit("SET_SEARCHED_AUTH_DATA", data);
    });
  },
  CHANGE_PASSWORD({}, payload) {
    return auth.changePwDefault(payload);
  },
  CHECK_PASSWORD({ commit }, payload) {
    return auth.checkPw(payload).then((data) => {
      commit("SET_CHECK_RESULT", data);
    });
  },
  CHANGE_PASSWORD_OWN({}, payload) {
    return auth.changePwOwn(payload);
  },
  WITH_DRAW({}) {
    return auth.withDraw();
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
