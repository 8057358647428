<template>
  <modal-address :priority="isTopPriority" @close-address-modal="closemodal">
    <template v-slot:body>
      <div class="default-pop" v-if="(popType = 'address')">
        <!-- 스피너 시작 -->
        <div class="spinner" v-if="isChildLoading">
          <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
            <beat-loader
              :loading="isChildLoading"
              :color="loader.color"
              :size="loader.size"
            ></beat-loader>
          </transition>
        </div>
        <!-- 스피너 끝 -->
        <div
          class="close"
          @click="btnClose()"
          :class="{ hide: isChildLoading }"
        >
          <img src="@/assets/images/x.svg" />
        </div>
        <div class="postcode-wrap" :class="{ hide: isChildLoading }">
          <vue-daum-postcode
            @search="searching()"
            @complete="getAddress($event)"
          />
        </div>
      </div>
    </template>
  </modal-address>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import BeatLoader from "vue-spinner/src/BeatLoader";
import ModalAddress from "./ModalAddress.vue";

export default {
  props: {
    popupSetAddress: {
      title: {
        type: String
      },
      content: {
        type: String
      },
      confirmBtnText: {
        type: String
      },
      cancelBtnText: {
        type: String
      },
      popType: {
        type: String
      },
      nextLink: {
        type: String
      },
      prevLink: {
        type: String
      },
      isTopPriority: {
        type: Boolean
      }
    }
  },
  components: {
    ModalAddress,
    BeatLoader
  },
  data() {
    return {
      input: "",
      valid: false,
      popType: this.popupSetAddress.popType,
      title: this.popupSetAddress.title,
      content: this.popupSetAddress.content,
      confirmBtnText: this.popupSetAddress.confirmBtnText,
      cancelBtnText: this.popupSetAddress.cancelBtnText,
      nextLink: this.popupSetAddress.nextLink,
      prevLink: this.popupSetAddress.prevLink,
      isTopPriority: this.popupSetAddress.isTopPriority,
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px"
      },
      addressResult: ""
    };
  },
  watch: {},
  mounted() {
    this.callLoader();
  },
  computed: {
    ...mapState("basic", ["checkAddressPopup", "isChildLoading"]),
    titleMutated() {
      if (this.title !== undefined) {
        return this.title.split("\n").join("<br />");
      } else {
        return "";
      }
    },
    contentMutated() {
      if (this.content !== undefined) {
        return this.content.split("\n").join("<br />");
      } else {
        return "";
      }
    }
  },
  methods: {
    ...mapMutations("basic", ["SET_ADDRESS_POPUP", "SET_CHILD_LOADER"]),
    searching() {
      document.querySelector(".modal-container").classList.add("search-ing");
      if (document.location.href.indexOf("requestForm") !== -1) {
        //console.log("원하는 진행");
        document.querySelector(".address").classList.add("search-ing");
      } else {
        document.querySelector(".modal-container").classList.add("search-ing");
      }
    },
    getAddress(addressData) {
      this.$emit("responseAddress", addressData);
    },
    btnNext() {
      this.$router.push(this.nextLink).catch(()=>{});
    },
    callApi() {
      this.SET_ADDRESS_POPUP(false);
      this.$emit("callApi");
    },
    btnPrev() {
      this.SET_ADDRESS_POPUP(false);
      this.$router.push(this.prevLink).catch(()=>{});
    },
    btnClose() {
      this.SET_ADDRESS_POPUP(false);
    },
    closemodal(){
      this.$emit("close-address-modal");
    },
    callLoader() {
      document.querySelector("body").classList.add("spreaded");
      if (document.location.href.indexOf("requestForm") !== -1) {
        document
          .querySelector(".modal-mask")
          .querySelector(".modal-container")
          .classList.add("overYOff");
      }
      this.SET_CHILD_LOADER(true);
      setTimeout(() => {
        this.SET_CHILD_LOADER(false);
      }, 800);
    }
  },
  created() {
    //console.log("가입", this.title);
    //console.log("팝타입", this.popType);
  },
  destroyed() {
    if (
      document
        .querySelector(".modal-mask")
        .querySelector(".modal-container")
        .classList.contains("overYOff")
    ) {
      document
        .querySelector(".modal-mask")
        .querySelector(".modal-container")
        .classList.remove("overYOff");
    } else {
       if (document.querySelector("body").classList.contains("spreaded")) {
      document.querySelector("body").classList.remove("spreaded");
    }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
