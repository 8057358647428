import { util } from "@/api";

const state = {
  util: {},
  view: {
    pdfAttachId: "",
    exelAttachId: "",
    fileInfo: null,
    authResult: [],
    passedUrl: null,
    isInitLogin: null,
    listDocInfo: [],
  },
  formData: "a",
  feedbackData: "",
  calendar: {
    title : "",
    startDate: "",
    startTime: "",
    hasTime: "",
    dialog: false,
  },
  events: [],
  referCalendarList : [],
};

const mutations = {
  SET_REFER_CALENDAR_LIST(state, data){
    state.referCalendarList = data;
  },
  OPEN_CALENDAR_DIALOG(state, payload) {
    //console.log(payload);
    state.calendar.startDate = payload.date;
    state.calendar.startTime = payload.time;
    state.calendar.hasTime = payload.hasTime;
    state.calendar.dialog = true;
    //console.log("commit")

    return state.calendar;
  },
  CLOSE_CALENDAR_DIALOG(state) {
    state.calendar.dialog = false;
  },
  REQUEST_ADD_EVENT(state, evnet) {
    //console.log(evnet);
    state.events[0] = evnet;
    state.calendar.dialog = false;
  },
  SET_EXEL_DATA(state, exelData){
    state.view.exelAttachId = exelData;
  },
  SET_PDF_URL(state, pdfUrl) {
    state.view.pdfAttachId = pdfUrl;
  },
  SET_FILE_INFO(state, fileInfo) {
    state.view.fileInfo = fileInfo;
  },
  SET_AUTH_RESULT(state, result) {
    state.view.authResult = result;
  },
  SET_PASSED_URL(state, url) {
    state.view.passedUrl = url;
  },
  SET_ISINIT_LOGIN(state, isInitLogin) {
    state.view.isInitLogin = isInitLogin;
  },
  SET_LIST_DOC_INFO(state, list) {
    state.view.listDocInfo = list;
  },
  SET_FORM_DATA(state, form) {
    //console.log("inside")
    //console.log(state.formData)
    //console.log(form)
    //console.log(state.formData)
    state.formData = form;
  },
  SET_FEEDBACK_DATA(state, data) {
    //console.log(data);
    state.feedbackData = data;
  }
};

const getters = {
  GET_PDF_URL: state => state.view.pdfAttachId,
  GET_FILE_INFO: state => state.view.fileInfo,
  GET_AUTH_RESULT: state => state.view.authResult,
  GET_PASSED_URL: state => state.view.passedUrl,
  GET_ISINIT_LOGIN: state => state.view.isInitLogin,
  GET_LIST_DOC_INFO: state => state.view.listDocInfo,
  GET_FORM_DATA: state => state.formData,
  GET_FEEDBACK_DATA: state => state.feedbackData,
  GET_EXEL_DATA: state => state.view.exelAttachId,
  GET_DATE_INFO: state => state.calendar,
  GET_REFER_CALENDAR_LIST: state=> state.referCalendarList,
};

const actions = {
  BRING_REFER_LIST({commit}, payload)
  {
    return util.bring_refer_calendar_list(payload).then(data=>{
      commit("SET_REFER_CALENDAR_LIST", data);
    })
  },
  STORE_DATE_INFO({commit}, payload){
    commit("OPEN_CALENDAR_DIALOG", payload);
  },
  QUESTION_SUBMIT({ }, payload) {
    return util.helpDesk(payload).then(data => {
      return data;
    });
  },
  FEEDBACK_SUBMIT({ }, payload) {
    return util.feedback(payload);
  },
  FEEDBACK_IS_YN({ }, payload) {
    return util.aleadyFeedbackYn(payload).then(data => {
      return data;
    });
  },
  BRING_FEEDBACK_DATA({ commit }, payload) {
    return util.getFeedbackData(payload).then(data => {
      //console.log(data);
      commit("SET_FEEDBACK_DATA", data);
    })
  },
  BRING_PDF_URL({ commit }) {
    return util.bringPdfUrl().then(data => {
      commit("SET_PDF_URL", data);
    });
  },
  BRING_PDF_URL_PR({ commit }, payload) {
    return util.bringPdfUrlPr(payload).then(data => {
      commit("SET_PDF_URL", data);
    });
  },
  BRING_PDF_URL_ON_REFER({ commit }, payload) {
    return util.bringPdfUrlOnRefer(payload).then(data => {
      commit("SET_PDF_URL", data);
    });
  },
  BRING_FILE_INFO({ commit }, payload) {
    return util.bringFileInfo(payload).then(data => {
      commit("SET_FILE_INFO", data);
    });
  },
  BRING_PDF({ commit }, payload) {
    return util.getPdf(payload).then(data => {
      commit("SET_PDF_URL", data);
    });
  },
  BRING_PDF_PR({ commit }, payload) {
    return util.getPdfPr(payload).then(data => {
      commit("SET_PDF_URL", data);
    });
  },
  BRING_NOTE({commit}, payload) {
    return util.getNote(payload).then(data => {
      commit("SET_NOTE_URL", data);
    });
  }
  ,
  DELAY_PASSWD({ commit }) {
    return util.delayPasswd().then(data => {
      commit("SET_AUTH_RESULT", data);
    });
  },
  RECOVERY({ commit }) {
    return util.recovery().then(data => {
      commit("SET_AUTH_RESULT", data);
    });
  },
  BRING_FORM_DATA({ commit }, payload) {
    return commit("SET_FORM_DATA", payload);
  },
  BRING_RECV_EXEL({ commit }) {
    return util.get_recv_exel().then((data)=>{
      commit("SET_EXEL_DATA", data);
    });
  },
  BRING_SEND_EXEL({ commit }) {
    return util.get_send_exel().then((data)=>{
      commit("SET_EXEL_DATA", data);
    });
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
