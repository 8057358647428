import Vue from "vue";
import App from "./App.vue";
import vueMoment from "vue-moment";
import router from "./router";
import { store } from "./store/store";
import vuetify from "./plugins/vuetify";
import BeatLoader from "vue-spinner/src/BeatLoader";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueCookies from "vue-cookies";
import naver from "vue-naver-maps";
import VueDaumPostcode from "vue-daum-postcode";
import Meta from "vue-meta";
import IMP from "vue-iamport";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import jQuery from "jquery";
window.jQuery = window.$ = jQuery;

//fort-awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faAddressCard,
  faCog,
  faFileCode,
  faLink,
  faRedo,
  faUndo,
  faEnvelope,
  faMagnifyingGlass,
  faFileLines,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
library.add(
  faAddressCard,
  faCog,
  faFileCode,
  faLink,
  faRedo,
  faUndo,
  faEnvelope,
  faMagnifyingGlass,
  faFileLines,
  faUser
);
library.add(faTrashAlt);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueperSlide);
Vue.use(VueperSlides);
Vue.use(vueMoment);
Vue.use(CKEditor);
Vue.use(VueCookies);
Vue.use(naver, {
  clientID: "86pngivree",
  useGovAPI: false, //공공 클라우드 API 사용 (선택)
  subModules: "", // 서브모듈 (선택)
});
Vue.use(VueDaumPostcode);

Vue.use(IMP, "imp16687294"); //아임포트 회원가입 후 발급된 가맹점 고유 코드를 사용해주세요. 예시는 KCP공식 아임포트 데모 계정입니다.
Vue.IMP().load();

Vue.use(Meta);

new Vue({
  router,
  store,
  BeatLoader,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
