import { note } from "@/api";
import { util } from "../../api";

const state = {
  noteIdentity: [],
  referIdentity: [],
  noteList: [],
};

const mutations = {
  SET_NOTE_IDENTITY(state, info) {
    state.noteIdentity = info;
  },
  SET_REFER_IDENTITY(state, info) {
    state.referIdentity = info;
  },
  SET_NOTE_LIST(state, data) {
    state.noteList = data;
  },
};

const getters = {
  GET_NOTE_IDENTITY: (state) => state.noteIdentity,
  GET_REFER_IDENTITY: (state) => state.referIdentity,
  GET_NOTE_LIST: (state) => state.noteList,
};

const actions = {
  BRING_NOTE_LIST({ commit }, payload) {
    return note.bringNoteList(payload).then((data) => {
      commit("SET_NOTE_LIST", data);
    });
  },
  RECPT_LIST({}, payload) {
    return note.recptlist(payload);
  },
  TRANS_LIST({}, payload) {
    return note.translist(payload);
  },
  RECPT_DETAIL({}, payload) {
    return note.recptDetail(payload);
  },
  TRANS_DETAIL({}, payload) {
    return note.transDetail(payload);
  },
  SUBMIT_MESSAGE({}, payload) {
    return note.noteAdd(payload);
  },
  RECPT_DELETE({}, payload) {
    return note.recptDelete(payload);
  },
  TRANS_DELETE({}, payload) {
    return note.transDelete(payload);
  },
  NOTE_COUNT({}) {
    return note.noteCount();
  },
  REFER_COUNT({}) {
    return note.referCount();
  },
  FILE_DOWNLOAD({}, payload) {
    return util.getNote(payload);
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
