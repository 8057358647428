import { notice } from "@/api";

const state = {
  noticeIdentity: [],
};

const mutations = {
  SET_NOTICE_IDENTITY(state, info) {
    state.noticeIdentity = info;
  }
};

const getters = {
  GET_NOTICE_IDENTITY: state => state.noticeIdentity
};

const actions = {
  NOTICE_LIST({}, searchOpt) {
    return notice.noticeList(searchOpt);
  },
  NOTICE_DETAIL({}, noticeId) {
    return notice.noticeDetail(noticeId);
  }
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
