import { admin } from "@/api";

const state = {
  prgsList: [],
  feedList: [],
  helpDeskList: [],
  drId: "",
  notice: {},
  orderList: [],
  prodList: [],
  prodData: "",
  couponList: [],
  couponData: "",
  cponId: "",
  userCouponList: [],
  symptomList: [],
  symptomData: "",
};

const getters = {
  //get
  GET_PRGS_LIST: (state) => state.prgsList,
  GET_DR_ID: (state) => state.drId,
  GET_NOTICE_DATA: (state) => state.notice,
  GET_FEED_LIST: (state) => state.feedList,
  GET_HELP_DESK_LIST: (state) => state.helpDeskList,
  GET_ORDER_LIST: (state) => state.orderList,
  GET_PROD_LIST: (state) => state.prodList,
  GET_PROD_DATA: (state) => state.prodData,
  GET_COUPON_LIST: (state) => state.couponList,
  GET_COUPON_DATA: (state) => state.couponData,
  GET_CPONID_DATA: (state) => state.cponId,
  GET_USER_COUPON_LIST: (state) => state.userCouponList,
  GET_USER_COUPON_DATA: (state) => state.couponData,
  GET_SYMPTOM_LIST: (state) => state.symptomList,
  GET_SYMPTOM_DATA: (state) => state.symptomData,
};

const mutations = {
  //set
  SET_PRGS_LIST(state, listData) {
    state.prgsList = listData;
  },
  SET_DR_ID(state, drId) {
    state.drId = drId;
  },
  SET_NOTICE_DATA(state, data) {
    state.notice = data;
  },
  SET_FEED_LIST(state, listData) {
    state.feedList = listData;
  },
  SET_HELP_DESK_LIST(state, listData) {
    state.helpDeskList = listData;
  },
  SET_ORDER_LIST(state, listData) {
    state.orderList = listData;
  },
  SET_PROD_LIST(state,listData){
    state.prodList = listData;
  },
  SET_PROD_DATA(state,data){
    state.prodData = data;
  },
  SET_COUPON_LIST(state,listData){
    state.couponList = listData.data;
  },
  SET_COUPON_DATA(state,data){
    state.couponData = data.data;
  },
  SET_CPONID_DATA(state,data){
    state.cponId = data;
  },
  SET_USER_COUPON_LIST(state,listData){
    state.userCouponList = listData.data;
  },
  SET_USER_COUPON_DATA(state,data){
    state.couponData = data.data;
  },
  SET_SYMPTOM_LIST(state,listData){
    state.symptomList = listData;
  },
  SET_SYMPTOM_DATA(state,data){
    state.symptomData = data;
  },
};

const actions = {
  //act
  BRING_PRGS_LIST({ commit }, payload) {
    return admin.getprgsList(payload).then((data) => {
      commit("SET_PRGS_LIST", data);
    });
  },
  BRING_PRGS_LIST_COPY({ commit }, payload) {
    return admin.getprgsListCopy(payload).then((data) => {
      commit("SET_PRGS_LIST", data);
    });
  },
  CHANGE_STATUS({ commit }, payload) {
    return admin.chgStatus(payload).then((data) => {
      commit("SET_DR_ID", data);
    });
  },
  ADD_NOTICE_DATA({ commit }, payload) {
    return admin.add_notice_data(payload).then((data) => {
      commit("SET_NOTICE_DATA", data);
    });
  },
  BRING_FEED_LIST({ commit }, payload) {
    return admin.getFeedList(payload).then((data) => {
      commit("SET_FEED_LIST", data);
    });
  },
  BRING_FEED_LIST_SEARCH({ commit }, payload) {
    return admin.getFeedListSearch(payload).then((data) => {
      commit("SET_FEED_LIST", data);
    });
  },
  DELETE_NOTICE_DATA({ commit }, payload) {
    return admin.delete_notice_data(payload).then((data) => {
    });
  },
  UPDATE_NOTICE_DATA({ commit }, payload) {
    return admin.update_notice_data(payload).then((data) => {
    });
  },
  BRING_HELP_DESK({ commit }, payload) {
    return admin.getHelpDeskList(payload).then((data) => {
      commit("SET_HELP_DESK_LIST", data);
    });
  },
  BRING_USE_Y_LIST({ commit }, payload) {
    return admin.getUseYList(payload).then((data) => {
      commit("SET_PRGS_LIST", data);
    });
  },
  BRING_USE_N_LIST({ commit }, payload) {
    return admin.getUseNList(payload).then((data) => {
      commit("SET_PRGS_LIST", data);
    });
  },
  UPDATE_USE_Y({ commit }, payload) {
    return admin.update_use_Y(payload).then((data) => {
    });
  },
  UPDATE_USE_N({ commit }, payload) {
    return admin.update_use_N(payload).then((data) => {
    });
  },
  BRING_ORDER_LIST({ commit }, payload) {
    return admin.bringOrderList(payload).then((data) => {
      commit("SET_ORDER_LIST", data);
    });
  },
  UPDATE_STATUS_CODE({}, payload) {
    return admin.updateStatusCode(payload);
  },
  BRING_PROD_LIST({ commit }, payload) {
    return admin.getProdListAdmin(payload).then((data) => {
      commit("SET_PROD_LIST", data);
    });
  },
  BRING_PROD_DATA({commit}, payload){
    return admin.getProdAdmin(payload).then((data) => {
      commit("SET_PROD_DATA", data);
    });
  },
  BRING_ADMIN_COUPON_LIST({commit}, payload){
    return admin.getCouponListAll(payload).then((data)=>{
      commit("SET_COUPON_LIST",data);
    });
  },
  BRING_COUPON_DATA({commit}, payload){
    return admin.getCoupon(payload).then((data) => {
      commit("SET_COUPON_DATA", data);
    });
  },
  UPDATE_COUPON_DATA({},payload){
    return admin.modifyCoupon(payload).then((data) => {
      return data;
    });
  },
  BRING_CPONID_DATA({commit}, payload){
    return admin.getCponId(payload).then((data) => {
      commit("SET_CPONID_DATA", data.data);
    });
  },
  ADD_COUPON_DATA({commit}, payload){
    return admin.addCouponData(payload).then((data) => {
      return data.data;
    });
  },
  DELETE_COUPON_DATA({ commit }, payload) {
    return admin.useOrDisposal(payload).then((data) => {
    });
  },
  BRING_ADMIN_USER_COUPON_LIST({commit},payload){
    return admin.getUserCouponListAll(payload).then((data) => {
      commit("SET_USER_COUPON_LIST",data);
    });
  },
  BRING_USER_COUPON_DATA({commit}, payload){
    return admin.getUserCoupon(payload).then((data) => {
      commit("SET_USER_COUPON_DATA", data);
    });
  },
  DELETE_USER_COUPON_DATA({ commit }, payload) {
    return admin.useOrDisposalUser(payload).then((data) => {
    });
  },
  SEND_COUPON_EMAIL({commit},payload){
    return admin.sendCouponEmail(payload);
  },
  BRING_SYMPTOM_LIST({ commit }, payload) {
    return admin.getSymptomList(payload).then((data) => {
      commit("SET_SYMPTOM_LIST", data);
    });
  },
  
  ADD_SYMPTOM({ commit }, payload) {
    return admin.addSymptom(payload).then((data) => {
      return data;
    });
  },
  BRING_SYMPTOM_DATA({ commit }, payload) {
    return admin.getSymptomOne(payload).then((data) => {
      commit("SET_SYMPTOM_DATA", data);
    });
  },
  UPDATE_SYMPTOM_DATA({ commit }, payload) {
    return admin.updateSymptom(payload).then((data) => {
      return data;
    });
  },
  DELETE_SYMPTOM_DATA({ commit }, payload) {
    return admin.deleteSymptom(payload).then((data) => {
    });
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
