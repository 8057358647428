<template>
  <transition name="modal">
    <div
      class="modal-mask"
      @click="overlayClick($event)"
      :class="{ isTopPriority: priority }"
    >
      <div class="modal-wrapper">
        <div class="modal-container" @click.stop>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    footerLess: {
      type: Boolean,
      default: false,
    },
    priority: {
      type: Boolean,
      default: false,
    },
    destination: {
      type: Boolean,
      default: false,
    },
    isFixing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("basic", [
      "checkPopup",
      "checkApiPopup",
      "checkSearchPopupReform",
    ]),
  },
  methods: {
    ...mapMutations("basic", [
      "SET_POPUP",
      "SET_API_POPUP",
      "SET_SEARCH_POPUP_REFORM",
    ]),
    overlayClick(event) {
      const target = event.target;
      //console.log("타겟", target);
      //console.log("커런트", event.currentTarget);
      //console.log("오버레이 isFixing", this.isFixing);
      if (this.checkPopup !== undefined && this.checkPopup === true) {
        if (this.destination) {
          this.$emit("forced-nextlink");
        }
        this.SET_POPUP(false);
        if (this.isFixing) {
          this.$router.push("/").catch(() => {});
        }
      } else if (
        this.checkApiPopup !== undefined &&
        this.checkApiPopup === true
      ) {
        if (this.destination) {
          this.$emit("forced-nextlink");
        }
        this.SET_API_POPUP(false);
        this.SET_SEARCH_POPUP_REFORM(false);
        if (this.isFixing) {
          this.$router.push("/").catch(() => {});
        }
      }
    },
    closePop() {
      //console.log("클로스팝 isFixing", this.isFixing);
      //console.log("하하", this.destination);
      if (this.checkPopup !== undefined && this.checkPopup === true) {
        if (this.destination) {
          this.$emit("forced-nextlink");
        }
        if (!this.isFixing) {
          this.SET_POPUP(false);
        }
      } else if (
        this.checkApiPopup !== undefined &&
        this.checkApiPopup === true
      ) {
        if (this.destination) {
          this.$emit("forced-nextlink");
        }
        if (!this.isFixing) {
          this.SET_API_POPUP(false);
        }
      }
    },
  },
  mounted() {},
  created() {
    //console.log("isFixing in Modal", this.isFixing);
    //console.log("destination in Modal", this.destination);
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper .modal-container {
  min-width: 320px;
}
@media screen and (max-width: 950px) {
  .modal-wrapper .modal-container {
    width: 100% !important;
  }
}
</style>
