import { refer, apply } from "@/api";
const state = {
  request: {
    requestList: [],
    applyList: [],
  },
  orderList: [],
  tokken: "",
  response: {},
  view: {
    isCheckedAgreement: false,
    smsList: [],
    agreeResult: [],
    replyStatus: [],
    reqAgrResult: [],
    referId: null,
    referType: null,
    agreeId: null,
  },
  referPdfName: "",
};

const getters = {
  GET_REQUEST: (state) => state.request.requestList,
  GET_ISCHECKED_AGREEMENT: (state) => state.view.isCheckedAgreement,
  GET_SMS_LIST: (state) => state.view.smsList,
  GET_AGREE_RESULT: (state) => state.view.agreeResult,
  GET_REPLY_STATUS: (state) => state.view.replyStatus,
  GET_REQ_AGREE_RESULT: (state) => state.view.reqAgrResult,
  GET_REFER_ID: (state) => state.view.referId,
  GET_REFER_TYPE: (state) => state.view.referType,
  GET_AGREE_ID: (state) => state.view.agreeId,
  GET_ORDER_LIST: (state) => state.orderList,
  GET_TOKKEN_IMPORT: (state) => state.tokken,
  GET_RESP: (state) => state.response,
  GET_REFER_PDF_NAME: (state) => state.referPdfName,
  GET_APPLY_LIST: (state) => state.request.applyList,
};

const mutations = {
  SET_APPLY_LIST(state, applyList) {
    state.request.applyList = applyList;
  },
  SET_REQUEST(state, requestData) {
    state.request.requestList = requestData;
  },
  SET_ISCHECKED_AGREEMENT(state, isChecked) {
    state.view.isCheckedAgreement = isChecked;
  },
  SET_SMS_LIST(state, smsList) {
    state.view.smsList = smsList;
  },
  SET_AGREE_RESULT(state, agreeResult) {
    state.view.agreeResult = agreeResult;
  },
  SET_REPLY_STATUS(state, status) {
    state.view.replyStatus = status;
    //console.log("넘어간 값");
    //console.log(state.view.replyStatus);
  },
  SET_REQ_AGREE_RESULT(state, agreeResult) {
    state.view.reqAgrResult = agreeResult;
  },
  SET_REFER_ID(state, referId) {
    state.view.referId = referId;
  },
  SET_REFER_TYPE(state, type) {
    state.view.referType = type;
  },
  SET_AGREE_ID(state, agreeId) {
    state.view.agreeId = agreeId;
  },
  SET_ORDER_LIST(state, orderData) {
    state.orderList = orderData;
  },
  SET_TOKKEN_IMPORT(state, tokken) {
    state.tokken = tokken;
  },
  SET_RESP(state, response) {
    state.response = response;
  },
  SET_REFER_PDF_NAME(state, referPdfName) {
    state.referPdfName = referPdfName;
  },
};

const actions = {
  BRING_APPLY_LIST({ commit }, payload) {
    return apply.bringApplyList(payload).then((data) => {
      //console.log(data);
      commit("SET_APPLY_LIST", data);
    });
  },
  REQUEST_VISITOR_STAUS({ commit }, payload) {
    return refer.requestVisitorStatus(payload).then((data) => {
      commit("SET_REFER_PDF_NAME", data);
    });
  },
  BRING_SMS_CODE({ commit }, payload) {
    return refer.bringSmsCode(payload).then((data) => {
      commit("SET_SMS_LIST", data);
    });
  },
  BRING_AGREE_RESULT({ commit }, payload) {
    return refer.checkAgreeId(payload).then((data) => {
      commit("SET_AGREE_RESULT", data);
      return data;
    });
  },
  REFER_SUBMIT({}, payload) {
    return refer.referSubmit(payload);
  },
  AGREE_SUBMIT({}, payload) {
    return refer.agreeSubmit(payload);
  },
  REFER_MODIFY({}, payload) {
    //console.log("refer_modify");
    return refer.referModify(payload);
  },
  REFER_REPLY({}, payload) {
    return refer.referReply(payload);
  },
  REPLY_WORD({}, payload) {
    return refer.replySubmit(payload);
  },
  VISIT_CHECK({}, payload) {
    return refer.visitCheck(payload);
  },
  VISIT_CHECK_UN_AUTH({}, payload) {
    return refer.visitCheckUnAuth(payload);
  },
  AGREE_FINISHED({ commit }, payload) {
    return refer.agreeFinish(payload).then((data) => {
      commit("SET_REQ_AGREE_RESULT", data);
      return data;
    });
  },
  BRING_AGREE_ID({ commit }) {
    return refer.bring_agree_id().then((data) => {
      commit("SET_AGREE_ID", data);
    });
  },
  CANCLE_REFER({}, payload) {
    return refer.cancle_refer(payload);
  },
  BRING_ORDER_LIST({ commit }, payload) {
    return refer.orderList(payload).then((data) => {
      commit("SET_ORDER_LIST", data);
    });
  },
  BRING_TOKKEN_IMPORT({ commit }, payload) {
    return refer.bringTokkenImport(payload).then((data) => {
      commit("SET_TOKKEN_IMPORT", data);
    });
  },
  CANCLE_ORDER({ commit }, payload) {
    return refer.cancleOrder(payload).then((response) => {
      commit("SET_RESP", response);
    });
  },
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
