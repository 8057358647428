<template>
  <div class="side-menu">
    <h2
      class="s-title"
      :class="{
        inResearch: menuName === 'research',
        inList: menuName === 'mypage',
        inIntroduce: menuName === 'introduce',
      }"
    >
      {{ sideTitle }}
    </h2>
    <div class="menu-wrap" v-if="menuName === 'research'">
      <div class="sort">
        <div class="item">
          <h4>거리 기준점</h4>
          <ul class="s-list">
            <li
              v-for="(distanceSort, distanceIndex) in sortOption.distance"
              :key="distanceIndex"
              @click="checkPick('second', distanceIndex)"
            >
              <div
                class="img-wrap"
                :class="{ active: sortOption.distance[distanceIndex].flag }"
              >
                <img
                  src="@/assets/images/ck_white.svg"
                  v-if="sortOption.distance[distanceIndex].flag"
                />
              </div>
              <div class="input-wrap">
                <label :for="'distanceSort' + distanceIndex">{{
                  distanceSort.title
                }}</label>
                <input type="checkbox" :id="'distanceSort' + distanceIndex" />
              </div>
            </li>
          </ul>
          <div class="toastPop" v-if="sortOption.distance[1].flag">
            <p>({{ address.buildingname }})</p>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-wrap" v-if="menuName === 'mypage'">
      <div class="introduce-menu">
        <h3 class="title top-margin">진료의뢰현황</h3>
        <div class="menu-holder">
          <div class="menu receive">
            <p @click="listOrder(0)">수신환자 리스트(InBox)</p>
          </div>
          <div class="menu receive_all">
            <p
              @click="click_receive_all()"
              style="font-size: 13px !important; left: 25px"
            >
              전체 수신환자 리스트
            </p>
          </div>
          <div class="menu receipt">
            <p @click="listOrder(1)">발신환자 리스트</p>
          </div>
          <div class="menu new_receive">
            <p @click="listOrder(2)">최근 진료의뢰받은 병원</p>
          </div>
          <div class="menu new_receipt">
            <p @click="listOrder(3)">최근 진료의뢰한 병원</p>
          </div>
        </div>
        <h3 class="title top-margin alone">
          <router-link to="/v/note/noteList">쪽지 관리</router-link>
        </h3>
        <h3 class="title top-margin">회원정보 관리</h3>
        <div class="menu-holder last">
          <div class="menu member-info">
            <p>
              <router-link to="/v/modify/member">회원정보 수정</router-link>
            </p>
          </div>
          <div class="menu search-info">
            <p @click="manageSearch()">검색정보 관리</p>
          </div>
          <div class="menu pr-info">
            <p @click="managePromotion()">소개정보 관리</p>
          </div>
          <div class="menu pw-change">
            <p><router-link to="/v/changePw">비밀번호 변경</router-link></p>
          </div>
          <div class="menu sub-info" v-if="!isSecondId">
            <p @click="callChildAccount()">부계정 관리</p>
          </div>
          <div class="menu draw" v-if="!isSecondId">
            <p><router-link to="/v/draw">회원탈퇴</router-link></p>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-wrap" v-if="menuName === 'mypage2'">
      <div class="menu onPr">
        <h3 class="m-title">
          <router-link to="/v/pr/manage">PR 관리</router-link>
        </h3>
        <div class="sub-menu">
          <p @click="manageSearch()">검색정보 관리</p>
          <p @click="managePromotion()">소개정보 관리</p>
        </div>
      </div>
      <div class="menu origin">
        <h3 class="m-title">
          <router-link to="/v/request/requestDocument">진료의뢰</router-link>
        </h3>
      </div>
      <div class="menu">
        <h3 class="m-title">
          <router-link to="/v/request/researchHospital">닥터검색</router-link>
        </h3>
      </div>
      <div class="menu origin" :class="{ active: activeOrder === 0 }">
        <h3 class="m-title">
          <router-link to="/v/request/list">진료의뢰현황</router-link>
        </h3>
        <div class="sub-menu">
          <p @click="listOrder(0)">수신진료의뢰현황조회</p>
          <p @click="listOrder(1)">발신진료의뢰현황조회</p>
        </div>
      </div>
      <div class="menu">
        <h3 class="m-title">
          <router-link to="/v/modify/member">회원정보 관리</router-link>
        </h3>
        <div class="sub-menu">
          <p><router-link to="/v/modify/member">회원정보 수정</router-link></p>
          <p @click="callPwChange()">비밀번호 변경</p>
          <p v-if="!isSecondId" @click="callChildAccount()">부계정 관리</p>
          <p v-if="!isSecondId" @click="requestwithDraw()">회원탈퇴</p>
        </div>
      </div>
    </div>
    <div class="menu-wrap" v-if="menuName === 'introduce'">
      <div class="introduce-menu">
        <div class="menu">
          <p>
            <router-link to="/v/introduce/company">헬스앤모어는</router-link>
          </p>
        </div>
        <div class="menu">
          <p>
            <router-link to="/v/introduce/service/1"
              >DrRefer 서비스소개</router-link
            ><!--매개변수 0 = silde, 1~3 = scroll-->
          </p>
          <div class="sein">
            <p>
              <router-link to="/v/introduce/service/1">의사검색</router-link>
            </p>
          </div>
          <div class="sein refer1">
            <p>
              <router-link to="/v/introduce/service/2">의뢰 발신</router-link>
            </p>
          </div>
          <div class="sein refer2">
            <p>
              <router-link to="/v/introduce/service/3">의뢰 수신</router-link>
            </p>
          </div>
          <div class="sein pr">
            <p>
              <router-link to="/v/introduce/service/4">소개페이지</router-link>
            </p>
          </div>
        </div>
        <div class="menu">
          <p>
            <router-link to="/v/notice/noticeList">공지사항</router-link>
          </p>
        </div>
        <div class="menu">
          <p>
            <router-link to="/v/introduce/helpdesk">문의사항</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="menu-wrap" v-if="menuName === 'admin'">
      <div
        @click="clearLocalStorage('logout')"
        style="cursor: pointer; text-align: center; border: 2px solid #c6c6ee"
      >
        로그아웃
      </div>
      <div class="introduce-menu" style="min-height: 700px">
        <div class="menu adminLic">
          <p>
            <router-link to="/v/adminMain/0">회원 승인</router-link>
          </p>
        </div>
        <div class="menu adminBlackList">
          <p>
            <router-link to="/v/adminMain/7">회원 차단 관리</router-link>
          </p>
        </div>
        <div class="menu adminNotice">
          <p>
            <router-link to="/v/adminMain/1">공지 사항 생성</router-link>
          </p>
        </div>
        <div class="menu adminNoticeUpdate">
          <p>
            <router-link to="/v/adminMain/2"
              >공지 사항 수정 및 삭제</router-link
            >
          </p>
        </div>
        <div class="menu adminFeedBack">
          <p>
            <router-link to="/v/adminMain/3">피드백 조회</router-link>
          </p>
        </div>
        <div class="menu adminHelpDesk">
          <p>
            <router-link to="/v/adminMain/4">문의 사항</router-link>
          </p>
        </div>
        <div class="menu adminDashBoard">
          <p>
            <router-link to="/v/adminMain/5">대시 보드</router-link>
          </p>
        </div>
        <div class="menu adminKeywordSelection">
          <p>
            <router-link to="/v/adminMain/6">키워드 관리</router-link>
          </p>
        </div>
        <div class="menu adminManagePayment">
          <p>
            <router-link to="/v/adminMain/8">상품 주문 현황 관리</router-link>
          </p>
        </div>
        <div class="menu adminManageProd">
          <p>
            <router-link to="/v/adminMain/9">상품 관리</router-link>
          </p>
        </div>
        <div class="menu adminCoupon">
          <p>
            <router-link to="/v/adminMain/10">쿠폰 관리</router-link>
          </p>
        </div>
        <div class="menu adminSymptom">
          <p>
            <router-link to="/v/adminMain/11">유사증상정보 관리</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="menu-wrap" v-if="menuName === 'designer'">
      <div
        @click="clearLocalStorage('logout')"
        style="cursor: pointer; text-align: center; border: 2px solid #c6c6ee"
      >
        로그아웃
      </div>
      <div class="introduce-menu" style="min-height: 700px">
        <div class="menu designMain">
          <p>
            <router-link to="/v/designer/main/0">Design Main</router-link>
          </p>
        </div>
        <div class="menu designOrderList">
          <p>
            <router-link to="/v/designer/main/1">Design 상품 현황</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="menu-wrap" v-if="menuName === 'payment'">
      <div class="introduce-menu" style="min-height: 700px">
        <h3 class="title top-margin">소개 디자인 관리</h3>
        <div class="menu designList">
          <p>
            <router-link to="/v/payment/designList"
              >소개 디자인 구매</router-link
            >
          </p>
        </div>
        <div class="menu designRefund">
          <p>
            <router-link to="/v/payment/refund"
              >소개 디자인 구매 관리</router-link
            >
          </p>
        </div>
        <div class="menu designManageApply">
          <p>
            <router-link to="/v/payment/manageApply"
              >소개 디자인 신청서 관리</router-link
            >
          </p>
        </div>
        <div class="menu registCoupon">
          <p>
            <router-link to="/v/payment/registCoupon">쿠폰 등록</router-link>
          </p>
        </div>
      </div>
    </div>
    <address-popup
      @responseAddress="getAddress($event)"
      v-if="checkAddressPopup"
      :popupSetAddress="popupSetAddress"
    />
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @call-withdraw="withDraw"
    />
  </div>
</template>

<script>
import AddressPopup from "@/components/modal/AddressPopup";
import DefaultPopup from "@/components/modal/DefaultPopupSide";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  props: {
    sideSet: {
      menuName: {
        type: String,
        default: "mypage",
      },
      activeOrder: {
        type: Number,
      },
      sideTitle: {
        type: String,
      },
      isWritedSearch: {
        type: Boolean,
      },
      isWritedPr: {
        type: Boolean,
      },
    },
  },
  data() {
    return {
      menuName: this.sideSet.menuName,
      sideTitle: this.sideSet.sideTitle,
      activeOrder: this.sideSet.activeOrder,
      popupSetAddress: {},
      isWritedSearch: this.sideSet.isWritedSearch,
      isWritedPr: this.sideSet.isWritedPr,
      sortOption: {
        default: [
          {
            flag: false,
            title: "거리순",
          },
          {
            flag: false,
            title: "추천순",
          },
          {
            flag: false,
            title: "되의뢰율순",
          },
          {
            flag: false,
            title: "의뢰순",
          },
          {
            flag: false,
            title: "등록순",
          },
        ],
        distance: [
          {
            flag: false,
            title: "병원 정보 기준",
          },
          {
            flag: true,
            title: "주소 입력",
          },
        ],
      },
      isSecondId: false,
      popupSet: {},
      address: {
        buildingname: "",
        zonecode: "",
        bname: "",
      },
    };
  },
  mounted() {
    this.checkPick("first", 4);
    this.checkPick("second", 0);
    if (localStorage.getItem("popYn") !== null) {
      this.checkActiveOrder();
    }
  },
  computed: {
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("join", ["GET_SUB_DATA"]),
    ...mapGetters("prod", ["GET_IN_PROGRESS"]),
    ...mapState("basic", ["checkAddressPopup", "isChildLoading"]),
    ...mapState("basic", {
      checkPopup: (state) => state.side.checkPopup,
      checkDesignPopup: (state) => state.side.checkDesignPopup,
    }),
    ...mapGetters("search", ["GET_SEARCH"]),
    ...mapGetters("request", ["GET_REFER_ID"]),
  },
  created() {
    this.getIdType();
  },
  methods: {
    ...mapActions("search", ["FETCH_SEARCH"]),
    ...mapActions("promotion", ["FETCH_PROMOTION"]),
    ...mapActions("auth", ["WITH_DRAW"]),
    ...mapActions("join", ["FETCH_SUB"]),
    ...mapActions("prod", ["BRING_INPROGRESS_APPLY"]),
    ...mapMutations("basic", ["SET_ADDRESS_POPUP", "SET_POPUP_SIDE"]),
    ...mapMutations("referList", ["SET_LIST_ORDER"]),
    click_receive_all() {
      if (
        document.querySelector(".receive").classList.contains("active") ||
        document.querySelector(".receipt").classList.contains("active") ||
        document.querySelector(".new_receive").classList.contains("active") ||
        document.querySelector(".new_receipt").classList.contains("active")
      ) {
        document.querySelector(".receive").classList.remove("active");
        document.querySelector(".receipt").classList.remove("active");
        document.querySelector(".new_receive").classList.remove("active");
        document.querySelector(".new_receipt").classList.remove("active");
        document.querySelector(".receive_all").classList.add("active");
      }

      this.$router.push("/v/mypage/request/1").catch(() => {});
    },
    //미사용중
    async validateInProgressApply() {
      const inqParam = {
        drId: localStorage.getItem("drId"),
      };
      const transData = new FormData();
      const transedInqParam = JSON.stringify(inqParam);
      transData.append("inqParam", transedInqParam);
      //console.log("서버로 전송할 data", transedInqParam);

      await this.BRING_INPROGRESS_APPLY(transData).then((data) => {
        //console.log(data.param.prgsStatCd);
        if (data.param.prgsStatCd === "01" || data.param.prgsStatCd === "02") {
          this.SET_POPUP_SIDE(true);
          this.popupSet.title = "소개 디자인 주문 진행 중";
          this.popupSet.content =
            "주문 진행중인 소개 디자인 신청서가 있습니다.\n 소개 디자인 신청서 관리에서 확인하시기 바랍니다.";
          this.popupSet.popType = "custom";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.nextLink = "/v/payment/manageApply";
        } else if (data.param.prgsStatCd === "03") {
          this.SET_POPUP_SIDE(true);
          this.popupSet.title = "소개 디자인 제작 중";
          this.popupSet.content =
            "제작중인 소개 디자인 신청서가 있습니다.\n 소개 디자인 신청서 관리에서 확인하시기 바랍니다.";
          this.popupSet.popType = "custom";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.nextLink = "/v/payment/manageApply";
        } else if (data.param.prgsStatCd === "04") {
          this.SET_POPUP_SIDE(true);
          this.popupSet.title = "소개 디자인 전달 중";
          this.popupSet.content =
            "전달 대기중인 소개 디자인 신청서가 있습니다.\n 소개 디자인 신청서 관리에서 확인하시기 바랍니다.";
          this.popupSet.popType = "custom";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.nextLink = "/v/payment/manageApply";
        } else if (data.param.prgsStatCd === "05") {
          this.SET_POPUP_SIDE(true);
          this.popupSet.title = "소개 디자인 전달 완료";
          this.popupSet.content =
            "전달 완료된 소개 디자인 신청서가 있습니다.\n 소개 디자인 신청서 관리에서 확인하시기 바랍니다.";
          this.popupSet.popType = "custom";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.nextLink = "/v/payment/manageApply";
        } else {
          this.$router.push("/v/payment/apply").catch(() => {});
        }
      });
    },
    moveApplyList() {
      this.$router.push("/v/payment/manageApply").catch(() => {});
    },
    clearLocalStorage(str) {
      localStorage.removeItem("drId");
      localStorage.removeItem("nm");
      localStorage.removeItem("token");
      localStorage.removeItem("memType");
      localStorage.removeItem("pwActiveYn");
      localStorage.removeItem("pwElapYn");
      localStorage.removeItem("suspandYn");
      localStorage.removeItem("popYn");

      if (str === "logout") {
        this.$router.push("/").catch(() => {});
      }
    },
    checkActiveOrder() {
      if (localStorage.getItem("popYn") !== "N") {
        if (this.isWritedSearch === false) {
          document
            .querySelector(".onPr .sub-menu")
            .childNodes[0].classList.add("active");
        }
        if (this.isWritedPr === false) {
          document
            .querySelector(".onPr .sub-menu")
            .childNodes[1].classList.add("active");
        }
      }
    },
    getIdType() {
      if (localStorage.getItem("memType") !== "D") {
        this.isSecondId = true;
      }
    },
    requestwithDraw() {
      this.SET_POPUP_SIDE(true);
      this.popupSet.title = "회원탈퇴";
      this.popupSet.content =
        "회원 탈퇴를 원하시면 '탈퇴'를 선택해주세요.\n 한번 탈퇴된 정보는 복구되지 않으니 신중히 결정해 주시기 바랍니다.";
      this.popupSet.popType = "withDraw";
      this.popupSet.confirmBtnText = "탈퇴";
      this.popupSet.cancelBtnText = "취소";
    },
    async withDraw() {
      try {
        await this.WITH_DRAW().then(() => {
          this.clearLocalStorage("withDraw");
          this.SET_POPUP_SIDE(true);
          this.popupSet.title = "탈퇴완료";
          this.popupSet.content = "회원탈퇴가 성공적으로 진행되었습니다.";
          this.popupSet.popType = "custom";
          this.popupSet.cancelBtnText = undefined;
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/";
          this.popupSet.destination = true;
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP_SIDE(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "서버통신장애로 탈퇴하지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    prePare() {
      alert("준비중입니다.");
    },
    listOrder(order) {
      //console.log("aa");
      //console.log(document.location.href.indexOf("all"));
      if (document.location.href.indexOf("all") !== -1) {
        this.$router.push("/v/mypage/request/0").catch(() => {});
        this.SET_LIST_ORDER(order);
      } else if (document.location.href.indexOf("list") === -1) {
        this.$router.push("/v/mypage/request/0").catch(() => {});
        this.SET_LIST_ORDER(order);
      } else {
        this.$emit("click-list", order);
      }
    },
    searchAddress() {
      this.SET_ADDRESS_POPUP(true);
      this.popupSetAddress.popType = "address";
    },
    getAddress(address) {
      //console.log("addr", address);
      //console.log("우편주소", address.zonecode);
      //console.log("주소1", address.buildingName);
      //console.log("주소2", address.bname);
      this.address.buildingname = address.address + " " + address.buildingName;
      this.address.zonecode = address.zonecode;
      this.address.bname = address.bname;
      this.sortOption.distance[0].flag = false;
      this.sortOption.distance[1].flag = true;
      this.$emit("addressResult", this.address);
      this.SET_ADDRESS_POPUP(false);
    },
    async manageSearch() {
      try {
        await this.FETCH_SEARCH().then(() => {
          //console.log("서치정보 스캔", this.GET_SEARCH);
          //console.log("univercity", this.GET_SEARCH.affilCollCd);
          if (this.GET_SEARCH.proObj !== null) {
            this.$router.push("/v/mypage/modifySearch").catch(() => {});
          } else {
            this.$router.push("/v/join/search2").catch(() => {});
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async managePromotion() {
      try {
        await this.FETCH_PROMOTION().then(() => {
          //console.log("소개정보 스캔", this.GET_PROMOTION);
          //console.log("introduce", this.GET_PROMOTION.doctIntro);
          if (this.GET_PROMOTION.doctIntro !== null) {
            //console.log("path : " + this.$route.path);
            if (this.$route.path !== "/v/mypage/prmanage") {
              this.$router.push("/v/mypage/prmanage").catch(() => {});
            } else {
              window.location.reload();
            }
          } else {
            this.$router.push("/v/mypage/prmanage").catch(() => {});
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    addressAdterPick() {
      this.sortOption.distance[0].flag = false;
      this.sortOption.distance[1].flag = true;
    },
    checkPick(type, order) {
      if (type === "first") {
        for (let i = 0; i < this.sortOption.default.length; i++) {
          if (order !== i) {
            this.sortOption.default[i].flag = false;
          } else {
            this.sortOption.default[i].flag = true;
          }
        }
      } else if (type === "second") {
        for (let j = 0; j < this.sortOption.distance.length; j++) {
          if (!(order === 1 && this.sortOption.distance[1].flag)) {
            if (order !== j) {
              this.sortOption.distance[j].flag = false;
            } else {
              this.sortOption.distance[0].flag = true;
              if (order === 1) {
                this.searchAddress();
              }
            }
          } else {
            this.searchAddress();
          }
        }
      }
      this.$emit("getCheckedValue", type, order);
    },
    callPwChange() {
      this.$emit("call-pop-in-side-password");
    },
    callChildAccount() {
      /**
       * 이전에 사용하였던 팝업 관련
       * this.$emit("call-pop-in-side", "issueChild"); */
      this.$router.push("/v/modify/sub").catch(() => {});
    },
    /** 부계정 비밀번호 변경로직이었으나 서버에서 PW 받지않아서 안씀 */
    async callChildPwChange() {
      try {
        await this.FETCH_SUB().then(() => {
          if (this.GET_SUB_DATA.subYn !== undefined) {
            this.$emit("call-pop-in-side-issue", this.GET_SUB_DATA);
          } else {
            this.SET_POPUP_SIDE(true);
            this.popupSet.title = "부계정 활성화";
            this.popupSet.content = "부계정을 활성화해야 합니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        });
      } catch (error) {
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP_SIDE(true);
          console.log("error: ", error);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "부계정 정보를 가져오지 못했습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
  },
  beforeDestroy() {},
  components: {
    AddressPopup,
    DefaultPopup,
  },
};
</script>

<style lang="scss" scoped>
.context.searchPop {
  .side-menu {
    width: 205px;
    h2.s-title {
      margin: 0 0 25px 0;
      line-height: 105px;
      font-size: 30px;
      font-family: "SUIT";
    }
    .menu-wrap .item {
      margin: 40px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .toastPop {
        p {
          font-size: 17px;
          line-height: 23px;
        }
      }
      h4 {
        font-size: 24px;
        line-height: 37px;
        margin: 0 0 10px 0;
      }
    }
  }
}
.side-menu {
  width: 217px;
  h2.s-title {
    font-size: 35px;
    font-stretch: normal;
    font-style: normal;
    line-height: 39px;
    letter-spacing: normal;
    text-align: center;
    color: #474747;
    padding: 35px 0;
    width: 100%;
    border: 0px solid #146f83;
    border-width: 4px 0;
    font-family: "SUIT";
    margin: 0 0 45px 0;
    font-weight: normal;
    &.inResearch,
    &.inList,
    &.inIntroduce {
      line-height: 129px;
      padding: 0;
      margin: 0 0 22.5px 0;
    }
  }
  .menu-wrap {
    position: relative;
    .menu {
      &.origin {
        &.active {
          h3.m-title {
            color: #fff;
            background: #146f83;
            border-top-right-radius: 10.5px;
            border-bottom-left-radius: 10.5px;
          }
        }
      }
    }
    .toastPop {
      position: relative;
      padding: 15px;
      background-image: url("~@/assets/images/pog_.svg");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      p {
        font-family: "SUIT";
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 29px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding: 20px 0 0 0;
      }
    }
    .introduce-menu {
      margin: 20px 0 0 0;
      h3.title {
        font-family: SUIT;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 29px;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        margin: 0 0 12px 0;
        padding: 0 0 0 15px;
        a {
          color: inherit;
          width: 100%;
          display: block;
        }
        &.top-margin {
          margin: 12px 0;
          &.last {
            margin: 35px 0 12px 0;
          }
        }
        &.alone {
          margin: 12px 0 25px 0;
          cursor: pointer;
        }
      }
      &:first-of-type {
        margin: 0;
      }
      .menu-holder {
        margin: 0 0 25px 0;
        &.last {
          margin: 0;
        }
      }
      .menu {
        position: relative;
        margin: 0;
        min-height: 36px;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        cursor: pointer;
        .sein {
          &:hover {
            background-color: #c8cac7;
            p {
              color: #fff;
            }
          }
          p {
            color: #000000;
          }
        }
        p {
          line-height: 24px !important;
          padding-left: 25px;
          font-size: 16px !important;
          width: 100%;
          a {
            display: block;
            width: inherit;
          }
        }
        &.receive_all {
          &:after {
            content: "";
            position: absolute;
            border: 0;
            background: transparent;
            box-sizing: border-box;
            width: 0;
            height: 10px;
            border-color: transparent transparent transparent #156f83;
            transition: 100ms all ease;
            cursor: pointer;
            border-style: solid;
            border-width: 5px 0 5px 10px;
            top: calc(50% - 0px);
            left: 25px;
            transform: translateY(-50%);
          }
          &.active {
            background: #156f83;
            border-bottom-left-radius: 8.5px;
            border-top-right-radius: 8.5px;
            .sein {
              &:hover {
                background-color: #146f83;
                p {
                  color: #fff;
                }
              }
              p {
                color: #000000;
              }
            }
            p {
              color: #fff;
            }
            &:after {
              content: "";
              position: absolute;
              border: 0;
              background: transparent;
              box-sizing: border-box;
              width: 0;
              height: 10px;
              border-color: transparent transparent transparent #fff;
              transition: 100ms all ease;
              cursor: pointer;
              border-style: solid;
              border-width: 5px 0 5px 10px;
              top: calc(50% - 0px);
              left: 25px;
              transform: translateY(-50%);
            }
          }
        }
        &:after {
          content: "";
          position: absolute;
          border: 0;
          background: transparent;
          box-sizing: border-box;
          width: 0;
          height: 10px;
          border-color: transparent transparent transparent #156f83;
          transition: 100ms all ease;
          cursor: pointer;
          border-style: solid;
          border-width: 5px 0 5px 10px;
          top: calc(50% - 0px);
          left: 8px;
          transform: translateY(-50%);
        }
        &.active {
          background: #156f83;
          border-bottom-left-radius: 8.5px;
          border-top-right-radius: 8.5px;
          .sein {
            &:hover {
              background-color: #146f83;
              p {
                color: #fff;
              }
            }
            p {
              color: #000000;
            }
          }
          p {
            color: #fff;
          }
          &:after {
            content: "";
            position: absolute;
            border: 0;
            background: transparent;
            box-sizing: border-box;
            width: 0;
            height: 10px;
            border-color: transparent transparent transparent #fff;
            transition: 100ms all ease;
            cursor: pointer;
            border-style: solid;
            border-width: 5px 0 5px 10px;
            top: calc(50% - 0px);
            left: 8px;
            transform: translateY(-50%);
          }
        }
        p {
          font-family: "SUIT";
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 18px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          position: relative;
        }
      }
    }
    .menu {
      &:first-of-type {
        margin: 0;
      }
      margin: 20px 0 0 0;
      h3.m-title {
        padding: 8px 0 8px 20px;
        font-family: "SUIT";
        font-size: 28px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 34px;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        cursor: pointer;
      }
      .sub-menu {
        padding: 0 0 0 20px;
        margin: 15px 0 0 0;
        p {
          &:first-of-type {
            margin: 0;
          }
          font-family: "SUIT";
          margin: 10px 0 0 0;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 29px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          position: relative;
          padding: 0 0 0 27.5px;
          &.active {
            width: fit-content;
            &:before {
              content: "!";
              position: absolute;
              right: -27px;
              width: 21px;
              height: 21px;
              top: 4px;
              color: #fff;
              background: #ff6565;
              text-align: center;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          &:hover {
            cursor: pointer;
          }
          &:after {
            content: "";
            width: 11px;
            height: 11px;
            box-sizing: border-box;
            border-style: solid;
            border-width: 5.5px 0px 5.5px 11px;
            border-color: transparent transparent transparent #146f83;
            position: absolute;
            left: 4.5px;
            top: 50%;
            transform: translateY(-50%);
          }
          a {
            color: inherit;
            &:active {
              color: #000000;
            }
          }
        }
      }
      a {
        color: inherit;
        &:active {
          color: #000000;
        }
      }
    }
    .sort {
      .item {
        margin: 65px 0 0 0;
        &:first-of-type {
          margin: 0;
        }
        h4 {
          font-family: "SUIT";
          font-size: 28px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 41px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
          margin: 0 0 15px 0;
        }
        ul.s-list {
          li {
            display: flex;
            margin: 8px 0 0 0;
            align-items: center;
            cursor: pointer;
            &:first-of-type {
              margin: 0;
            }
            .img-wrap {
              width: 23px;
              height: 23px;
              border-radius: 6px;
              border: solid 0.5px #2a2a2a;
              background-color: #ffffff;
              margin: 0 8px 0 0;
              display: flex;
              justify-content: center;
              align-items: center;
              &.active {
                background-color: #146f83;
                border: 0;
              }
              img {
              }
            }
            .input-wrap {
              label {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 29px;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
                cursor: pointer;
              }
              input {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
p.address-view {
  margin: 10px 0 0 30px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 27px;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: 2px solid #313c49;
  border-radius: 8.5px;
  padding: 6px;
  overflow: hidden;
}
.sein {
  display: none;
  position: absolute;
  width: 50%;
  left: 218px;
  z-index: 1;
  border-bottom-left-radius: 8.5px;
  border-top-right-radius: 8.5px;
  min-height: 36px;
  &.refer1 {
    top: 36px;
  }
  &.refer2 {
    top: 72px;
  }
  &.pr {
    top: 108px;
  }
  p {
    color: #000000;
    font-size: 16px !important;
    margin: 5px 0 0 0;
  }
}

.menu:hover .sein {
  display: block;
  background-color: #f8f8f8;
}
.designApplyP:hover {
  text-decoration: underline;
}
</style>
