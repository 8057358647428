<template>
  <transition name="modal">
    <div
      class="modal-mask"
      @click="overlayClick($event)"
      :class="{ isTopPriority: priority }"
    >
      <div class="modal-wrapper">
        <div class="modal-container address" @click.stop>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    footerLess: {
      type: Boolean,
      default: false,
    },
    priority: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("basic", ["checkAddressPopup"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_ADDRESS_POPUP"]),
    overlayClick(event) {
      const target = event.target;
      if (target == event.currentTarget.querySelector(".modal-container")) {
        return false;
      }
      const headerTags = event.currentTarget.querySelector(".close");
      if (headerTags === target) {
        return false;
      }
      document.querySelector(
        ".vue-daum-postcode-container"
      ).style.height = window.getComputedStyle(
        document.querySelector(".modal-container")
      ).height;
      this.SET_ADDRESS_POPUP(false);
      this.$emit("close-address-modal");
      if (document.querySelector("body").classList.contains("spreaded")) {
        document.querySelector("body").classList.remove("spreaded");
      }
      if (
        document
          .querySelector(".modal-container")
          .classList.contains("search-ing")
      ) {
        document
          .querySelector(".modal-container")
          .classList.remove("search-ing");
      }
    },
    closePop() {
      document.querySelector(
        ".vue-daum-postcode-container"
      ).style.height = window.getComputedStyle(
        document.querySelector(".modal-container")
      ).height;
      this.SET_ADDRESS_POPUP(false);
      if (document.querySelector("body").classList.contains("spreaded")) {
        document.querySelector("body").classList.remove("spreaded");
      }
      if (
        document
          .querySelector(".modal-container")
          .classList.contains("search-ing")
      ) {
        document
          .querySelector(".modal-container")
          .classList.remove("search-ing");
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss" scoped>
.modal-wrapper .modal-container.address .modal-body {
  width: 100%;
}
@media screen and (max-width: 809px) {
  .modal-container.address {
    width: 600px;
  }
}
@media screen and (max-width: 610px) {
  .modal-container.address {
    width: 500px;
  }
}
@media screen and (max-width: 510px) {
  .modal-container.address {
    width: 410px;
    height: 400px;
  }
}
@media screen and (max-width: 410px) {
  .modal-container.address {
    width: 310px;
    height: 350px;
  }
}
@media screen and (max-width: 350px) {
  .modal-container.address {
    width: 260px;
    height: 300px;
  }
}
</style>
